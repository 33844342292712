import React, { useEffect, useState } from "react";
import ProgressBar from "./components/ProgressBar/ProgressBar";
import { useForm } from "react-hook-form";
import "./AddUpdateArtWork.scss";
import ArtWorkListing from "./components/ArtWorkListing/ArtWorkListing";
import Details from "./components/Details/Details";
import ShippingOption from "./components/ShippingOption/ShippingOption";
import Bio from "./components/Bio/Bio";
import { getSellerProductDetails } from "../../../../../../../apiCall";
import toast from "react-hot-toast";

const AddUpdateArtWork = ({ setActiveTab, setPage, editProduct }) => {
    const [actveStep, setActiveStep] = useState(0);
    const [myData, setMyData] = useState({
        title: "",
        about: "",
        price: "",
        current_stock: "",
        category_id: 0,
        weight: "",
        dimensions: "",
        description: "",
        countries: [],
        states: [],
        owner: "",
        bio: "",
        shipping_option_name: "",
        shipping_time_estimate: null,
        shipping_fee: 0,
        images: [],
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const onSubmit = (data) => {
        console.log(data);
    };
    const onCancel = () => {
        setPage("ArtWorkList");
        setActiveTab("");
    };
    console.log(editProduct)
    useEffect(() => {
        const getData = async () => {
            const Re = await getSellerProductDetails(editProduct);
            console.log(Re?.data);

            if (Re?.data?.status[0].Error === "False") {
                setMyData(Re?.data?.value);
            } else if (Re?.data?.status[0]?.ResponseMessage) {
                toast.error(Re?.data?.status[0].ResponseMessage);
            } else {
                toast.error("Something went wrong.");
            }
        };
        if(editProduct){
          getData();
        }
    }, [editProduct]);
    return (
        <div className='add-art-work-container'>
            <div className='title'>Add new artworks</div>
            <ProgressBar actveStep={actveStep} />
            {(() => {
                switch (actveStep) {
                    case 0:
                        return (
                            <ArtWorkListing
                                setActiveStep={setActiveStep}
                                onCancel={onCancel}
                                setMyData={setMyData}
                                myData={myData}
                            />
                        );
                    case 1:
                        return (
                            <Details
                                setActiveStep={setActiveStep}
                                onCancel={onCancel}
                                setMyData={setMyData}
                                myData={myData}
                            />
                        );
                    case 2:
                        return (
                            <ShippingOption
                                setActiveStep={setActiveStep}
                                onCancel={onCancel}
                                setMyData={setMyData}
                                myData={myData}
                            />
                        );
                    case 3:
                        return (
                            <Bio
                                setActiveStep={setActiveStep}
                                setActiveTab={setActiveTab}
                                onCancel={onCancel}
                                myData={myData}

                                isEdit={editProduct ? true : false}
                            />
                        );
                    default:
                        return "";
                }
            })()}
        </div>
    );
};

export default AddUpdateArtWork;
