import { Navigate, useLocation } from "react-router-dom";
import { useStateValue } from "./StateProvider";
import toast from "react-hot-toast";
export const ProtectRoute = ({ children }) => {
  const [{ userDetail, userLoggedIn }, dispatch] = useStateValue();
  const { pathname } = useLocation();
  const EcommereceRouteList = [
    "/signup",
    "/login",
    "",
    "/",
    "/shop",
    "/product-info",
    "/checkout",
    "/rental",
    "/settings",
    "/build-custom-art",
    "/forgot-password",
    "/contact-us",
    "/about-us",
    "/become-seller",
    "/membership"
  ];
  const sellerRoutList = [
    "/seller/dashboard/home",
    "/seller/dashboard/manage-artworks",
    "/seller/dashboard/orders",
    "/seller/dashboard/shop",
    "/seller/dashboard/earnings-payouts",
    "/seller/dashboard/profile-settings",
    "/seller/dashboard/artwork-categories",
    "/seller/dashboard/Artwork Categories",
    "/seller/dashboard/support-ticket",
    "/seller/dashboard/membership",
  ]
  const isPathPrefix = (list,pathName) => {
    return list.includes(pathName);
  };

console.log(pathname, userDetail?.user_type === "seller" , isPathPrefix(EcommereceRouteList,pathname))
  if (userLoggedIn && userDetail?.user_type === "seller" && isPathPrefix(EcommereceRouteList,pathname)) {
  
    return <Navigate to={"/seller/dashboard/home"} />;
  }
  if (userLoggedIn && userDetail?.user_type !== "seller" && pathname === "/seller/signup") {
  toast.error("please logout first. and signup as seller.")
    return <Navigate to={"/"} />;
  }
  if (!userLoggedIn && isPathPrefix(sellerRoutList,pathname)) {
    return <Navigate to={"/"} />;
  }
  return children;
};

export const PublicRoute = ({ children }) => {
  if (sessionStorage.getItem("token")) {
    return <Navigate to={"/"} />;
  }
  return children;
};
