import React, { useState } from "react";
import AccountInfo from "./components/AccountInfo/AccountInfo";
import "./ProfileSettings.scss";
import StoreSetting from "./components/StoreSetting/StoreSetting";
import NotificationSetting from "./components/NotificationSetting/NotificationSetting";
import { useQuery } from "@tanstack/react-query";
import { getSellerProfile } from "../../../../../apiCall";
import toast from "react-hot-toast";

const ProfileSettings = ({ setPage, setProfile }) => {
  const [activateTab, setActiveTab] = useState("account-info");

  const { data: profileData,refetch } = useQuery({
    queryKey: ["seller-profile"],
    queryFn: () => getSellerProfile(),
    onSuccess: (data) => {
      console.log(data);
      if (data.status === 200) {
        setProfileDetail(data.data?.value);
      } else {
        toast.error("something went wrong there. please login again.");
      }
    },
    onError: (e) => {
      if (e.message) {
        return toast.error(e.message);
      }
      toast.error("Something went wrong");
    },
  });

  const [profileDetail, setProfileDetail] = useState(
    profileData?.data?.value ? profileData.data?.value : {}
  );
  return (
    <div className="profile-setting-container">
      <div className="page-option">
        <button
          className={`${
            activateTab === "account-info" ? "btn-primary" : "btn-secondary"
          }`}
          onClick={() => {
            setPage("ProfileInfo");
            setActiveTab("account-info");
          }}
        >
          Account Information
        </button>
        <button
          className={`${
            activateTab === "store-setting" ? "btn-primary" : "btn-secondary"
          }`}
          onClick={() => {
            setPage("StoreSetting");
            setActiveTab("store-setting");
          }}
        >
          Store Settings
        </button>
        <button
          className={`${
            activateTab === "notification" ? "btn-primary" : "btn-secondary"
          }`}
          onClick={() => {
            setPage("ManageNotification");
            setActiveTab("notification");
          }}
        >
          {" "}
          Manage notification
        </button>
      </div>
      {(() => {
        switch (activateTab) {
          case "account-info":
            // setPage("ArtWorkList")
            return <AccountInfo profileDetail={profileDetail} refetch={refetch} setProfile={setProfile} />;
          case "store-setting":
            // setPage("ArtWorkList")
            return <StoreSetting profileDetail={profileDetail} refetch={refetch}/>;
          case "notification":
            // setPage("ArtWorkList")
            return <NotificationSetting profileDetail={profileDetail} refetch={refetch} />;
          default:
            return;
        }
      })()}
    </div>
  );
};

export default ProfileSettings;
