import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { domainName } from "../../../../../../../../../Constants";

const ArtWorkListing = ({ setActiveStep, onCancel, setMyData, myData }) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    console.log("MY DATA", myData);
    const [images, setImages] = useState([]);
    const [imageUrl, setImageUrl] = useState(myData?.images)

    useEffect(() => {
      reset({ title: myData.title, about: myData.about })
      setImageUrl(myData?.images.map(img => domainName + "/uploads/" + img))
    }, [myData])
    const onSubmit = (data) => {
        console.log(data);
        setMyData((pre) => {
            return {
                ...pre,
                images: images,
                title: data.title,
                about: data.about,
            };
        });
        setActiveStep(1);
    };
    const handleFileChange = () => {
        if (images.length < 5) {
            setImages((pre) => [...pre, fileInputRef.current.files[0]]);
            setImageUrl((pre) => [...pre, URL.createObjectURL(fileInputRef.current.files[0])])
        }
    };
    const fileInputRef = useRef();
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='input-container'>
                <div className='upload-file'>
                    <label>
                        Upload Images (for artwork) ({images.length}/5)
                    </label>
                    <div className='images-container'>
                        <input
                            ref={fileInputRef}
                            type='file'
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={() => handleFileChange()}
                        />
                        {imageUrl.map((img, indx) => (
                            <img
                                key={"img-" + indx}
                                src={img}
                            />
                        ))}
                        {imageUrl.length < 5 && (
                            <div
                                className='upload-file-button'
                                onClick={() => fileInputRef.current.click()}>
                                <span>+</span>
                                <span>Add Image</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className='input-wrapper'>
                    <label>Title of Artwork/Service </label>
                    <input
                        type='text'
                        placeholder='Enter Art Title..'
                        value={FormData.title}
                        {...register("title", {
                            required: "Title is required.",
                            maxLength: {
                                value: 50,
                                message:
                                    "Title should be less then 50 characters.",
                            },
                        })}
                    />
                    {errors.title && (
                        <div className='error'>{errors.title.message}</div>
                    )}
                </div>

                <div className='input-wrapper'>
                    <label>Description</label>
                    <textarea
                        rows={2}
                        type='text'
                        placeholder='A calming painting capturing the beauty of a sunset over the ocean.'
                        {...register("about", {
                            required: "Description is required.",
                            maxLength: {
                                value: 500,
                                message:
                                    "Description should be less then 500 characters.",
                            },
                        })}
                    />
                    {errors.about && (
                        <div className='error'>{errors.about.message}</div>
                    )}
                </div>
                <div className='btn-wrapper'>
                    <div
                        className='cancel-btn btn-secondary'
                        onClick={() => onCancel()}>
                        Cancel
                    </div>
                    <button className='btn-primary' type='submit'>
                        Save and Next
                    </button>
                </div>
            </div>
        </form>
    );
};

export default ArtWorkListing;
