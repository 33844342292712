import React from 'react';
import "./ProtectYourArtModal.scss"
import { Modal } from 'react-bootstrap';
import { BiShield } from "react-icons/bi";


const ProtectYourArtModal = (props) => {
    return (
        <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="protect-your-art-modal">
          <BiShield className="icon" />
          <h4>Protect your Art</h4>
          <h5>From ₹6045/mo.</h5>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Expedita
            consequatur excepturi distinctio laudantium quos recusandae rerum non
            optio, quae, est similique enim, molestiae id ex doloribus numquam
            harum illum aliquam?
          </p>
          <h4>Have more questions?</h4>
          <span>+91 9547565652</span>
        </Modal.Body>
      </Modal>
    );
};

export default ProtectYourArtModal;