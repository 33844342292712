import React from "react";
import "./CircleSlider.scss";
import { Container } from "react-bootstrap";
import { useEffect } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

const CircleSlider = () => {
  const scrollContainerRef = useRef(null);
  const circleRef = useRef(null);
  const navigateTo = useNavigate()

  useEffect(() => {
    // circleRef.current.style.transform = "rotate(-" + 0 + "deg)";
    const scrollContainer = scrollContainerRef.current;
    const handleScroll = () => {
      // var circle = document.getElementById('circle');
      const rotationDegreesObj = {
        0: 0,
        33: -270,
        67: -180,
        100: -90,
      };

      if (!scrollContainer) return;

      const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
      const currentScrollPercentage =
        (scrollTop / (scrollHeight - clientHeight)) * 100;

      if (rotationDegreesObj[currentScrollPercentage.toFixed(0)]) {
        circleRef.current.style.transform =
          "rotate(" +
          rotationDegreesObj[currentScrollPercentage.toFixed(0)] +
          "deg)";
      }
    };

    scrollContainer.addEventListener("scroll", handleScroll);

    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="circle-component">
      <div className="circle-container" ref={scrollContainerRef}>
        <div className="circle-wrapper">
          <div className="circle" ref={circleRef}>
            {/* Add dots here */}
            {/* <div className="dot" style={{ top: "0%", left: "50%" }}></div>

            <div className="dot" style={{ top: "50%", left: "0%" }}></div>

            <div className="dot" style={{ top: "100%", left: "50%" }}></div>

            <div className="dot" style={{ top: "50%", left: "100%" }}></div> */}
          </div>
        </div>
        <section className="one1">
          <div className="container">
            <h1>01. About the company. </h1>
            <div className="contant">
              Welcome to Tart, where art comes to life! We are passionate about
              connecting artists and art enthusiasts in a vibrant community.
              Learn more about our journey, values, and the people who make our
              platform a creative hub.
            </div>
            <button className="btn-primary" onClick={() => navigateTo('about-us')}>Discover Our Story</button>
          </div>
        </section>
        <section className="two2">
          <div className="container">
            <h1>02. Become a Seller. </h1>
            <div className="contant">
              Are you an artist looking to showcase and sell your creations?
              Join our community of talented sellers and turn your passion into
              a thriving business. Benefit from our global reach and connect
              with art lovers from around the world.
            </div>
            <button className="btn-primary" onClick={() => navigateTo('become-seller')}>Start Selling Today</button>
          </div>
        </section>
        {/* <section className="tree3">
          <div className="container">
            <h1>03. Become a freelancer. </h1>
            <div className="contant">
              Calling all creative minds! Whether you're a graphic designer,
              illustrator, or any other artistic professional, explore
              opportunities to freelance on our platform. Connect with clients,
              showcase your skills, and take your freelance career to new
              heights.
            </div>
            <button className="btn-primary">Join as a Freelancer</button>
          </div>
        </section> */}
        <section className="four4">
          <div className="container">
            <h1>03. Shop </h1>
            <div className="contant">
              Discover a world of creativity in our online art marketplace.
              Browse through a diverse collection of artworks, from paintings
              and prints to sculptures and digital art. Find the perfect piece
              to adorn your space or gift to a fellow art lover.
            </div>
            <button className="btn-primary" onClick={() => navigateTo('shop')}>Explore the Gallery</button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CircleSlider;
