import React, { useState } from "react";
import "./HelpSupport.scss"

import Faq from "./components/Faq/Faq";
import SupportForm from "./components/SupportForm/SupportForm";

const HelpSupport = ({setPage}) => {
  const [activateTab, setActiveTab] = useState("support-faq");

  return (
    <div className="support-container">
      <div className="page-option">
        <button className={`${activateTab ==="support-faq"?"btn-primary":"btn-secondary"}`} onClick={()=>{setPage("SupportFAQ");setActiveTab("support-faq")}}>Help articles and FAQs</button>
        <button className={`${activateTab ==="support-form"?"btn-primary":"btn-secondary"}`}onClick={()=>{setPage("SupportForm");setActiveTab("support-form")}}>Contact customer support</button>
      </div>
      {(() => {
        switch (activateTab) {
          case "support-faq":
            // setPage("ArtWorkList")
            return <Faq />;
          case "support-form":
            // setPage("ArtWorkList")
            return <SupportForm />;
          
          default:
            return;
        }
      })()}
    </div>
  );
};

export default HelpSupport;
