import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  checkStoreNameAvailable,
  sellerStore,
  updateSellerStoreInfo,
  updateSellerStoreLogo,
} from "../../../../../../../apiCall";
import toast from "react-hot-toast";
import { useStateValue } from "../../../../../../../StateProvider";
import { domainName } from "../../../../../../../Constants";

const StoreSetting = ({ profileDetail, refetch }) => {
  const [{ userLoggedIn, userDetail }, dispatch] = useStateValue();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const logoImgRef = useRef();
  const [logoPic, setLogoPic] = useState();
  const onImageSelect = async (e) => {
    const fData = new FormData();
    fData.append("logoImage", e.target.files[0]);
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (
        file.type === "image/x-png" ||
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      ) {
        try {
          const response = await updateSellerStoreLogo(fData);
          if (response.data?.status[0].Error === "False") {
            setLogoPic({ url: URL.createObjectURL(file) });
            // QueryClient.invalidateQueries("user-data");
            toast.success(response.data?.status[0].Message);
          } else {
            toast(response.data?.status[0].ResponseMessage, { icon: "⚠️" });
          }
        } catch (err) {
          toast.error(err.message);
        }
      } else {
        return toast("Select an image file", {
          icon: "❌",
          position: "top-center",
          style: {
            borderRadius: "10px",
          },
        });
      }
    }
  };
  useEffect(() => {
    const setData = () => {
      setValue("storeName", profileDetail?.store_name);
      setValue("storeUrl", `www.tart.aapkacfo.com/${profileDetail?.store_name}`);
      setValue("description", profileDetail?.description);
      setValue("address", profileDetail?.address);
    };
    setData();
  }, [profileDetail]);
  const fileInputRef = useRef();

  const [imagePreview, setImagePreview] = useState(null);
  const [imageError, setImageError] = useState(false);
  
  const onSubmit = async (data) => {
    try {
      const response = await updateSellerStoreInfo(data);

      if (response?.status === 200) {
        toast.success("Store detail updated successfully.");
      } else {
        toast.error(response?.response.data.status[0].ResponseMessage);
      }
    } catch (error) {
      console.error("Error uploading PAN card:", error);
    }
  };
  const validatestoreName = async (value) => {
    if (value !== profileDetail?.store_name) {
      const response = await checkStoreNameAvailable(value);
      if (response?.status === 200) {
        return true; // For example, checking if value is 'hello'
      }
      return false; // For example, checking if value is 'hello'
    } else {
      return true; // For example, checking if value is 'hello'
    }
  };
  return (
    <div className="seller-profile">
      <div className="seller-form-wrapper">
        <div className="title">Store Settings</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-container">
            <div className="upload-logo">
            <div className="image-div">
            <h5>Profile Image</h5>
            {logoPic?.url ? (
              <img
                src={
                  logoPic?.url 
                    ? `${logoPic?.url}`
                    : "https://img.freepik.com/free-icon/user_318-159711.jpg"
                }
                alt="profile"
                className="profile-img"
                onClick={() => logoImgRef.current.click()}
              />
            ) : (
              <img
                src={
                  profileDetail.profile_image
                    ? `${domainName}${profileDetail?.logo}`
                    : "https://img.freepik.com/free-icon/user_318-159711.jpg"
                }
                alt="profile"
                className="profile-img"
                onClick={() => logoImgRef.current.click()}
              />
            )}
            <input
              type="file"
              ref={logoImgRef}
              accept="image/*"
              onChange={(e) => onImageSelect(e)}
              hidden
            />
          </div>
              {imageError && <div className="error">Logo is required.</div>}
            </div>

            <div className="input-wrapper">
              <label>Enter name of your Store</label>
              <input
                type="text"
                placeholder="Enter Your Store Name."
                {...register("storeName", {
                  required: "Name is required.",
                  validate: {
                    customValidation: (value) =>
                      validatestoreName(value) || "Store name already in use.",
                  },
                  maxLength: {
                    value: 20,
                    message: "Name should be less then 20 characters.",
                  },
                  onChange: (e) => {
                    setValue("storeUrl", `www.tart.aapkacfo.com/${e.target.value}`);
                  },
                })}
              />
              {console.log(errors)}
              {errors.storeName && (
                <div classstoreName="error">{errors.storeName.message}</div>
              )}
              {errors.name?.type === "customValidation" && (
                <div className="error">Store name already in use.</div>
              )}
            </div>
            <div className="input-wrapper">
              <label>Store Link preview</label>
              <input
                type="text"
                placeholder="www.tart.aapkacfo.com/John'sArtHaven."
                {...register("storeUrl", {
                  required: "Store Link is required.",
                })}
                disabled
              />
              {errors.storeUrl && (
                <div className="error">{errors.storeUrl.message}</div>
              )}
            </div>
            <div className="input-wrapper">
              <label>Description</label>
              <textarea
                rows={2}
                type="text"
                placeholder="Welcome to a world of creativity! Explore my unique collection of paintings and digital art."
                {...register("description", {
                  required: "Description is required.",
                  maxLength: {
                    value: 500,
                    message: "Description should be less then 500 characters.",
                  },
                })}
              />
              {errors.description && (
                <div className="error">{errors.description.message}</div>
              )}
            </div>
            <div className="input-wrapper">
              <label>Business Address (if applicable)</label>
              <input
                type="text"
                placeholder="Enter Your address."
                {...register("address", {
                  maxLength: {
                    value: 100,
                    message: "Address should be less then 100 characters.",
                  },
                })}
              />
              {errors.address && (
                <div className="error">{errors.address.message}</div>
              )}
            </div>

            <div className="btn-wrapper">
              <button className="btn-primary">Save Changes</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default StoreSetting;
