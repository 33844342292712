import { useState } from "react";
import "./Shipping.scss";
import EmptyImg from "../../../../assets/images/empty-cart.png";
import { Address } from "../Address/Address";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addAddress } from "../../../../apiCall";
import { toast } from "react-hot-toast";
import AddAddressModal from "../../../../components/modals/AddAddressModal/AddAddressModal";

export const Shipping = ({
  addresses,
  isLoading,
  selectAddress,
  selectedAddress,
}) => {
  const [show, setShowModal] = useState(false);
  return (
    <div data-aos="fade-up" className="cart-shipping">
    
       
     
      {addresses.length !== 0 &&
        addresses.map((address) => (
          <Address
            address={address}
            key={address.id}
            selectAddress={selectAddress}
            selectedAddress={selectedAddress}
          />
        ))}
         <div className="add-button">
          <button className="btn-secondary" onClick={() => setShowModal(true)}>
            add address
          </button>
        </div>
      
      {!addresses.length && !show && !isLoading ? (
        <div className="empty-addresses">
          <img src={EmptyImg} alt="empty-addresses" className="empty-img" />
        </div>
      ) : null}
       <AddAddressModal
        show={show}
        setShowModal={setShowModal}
        onHide={() => setShowModal(false)}
      />
    </div>
  );
};
