import React from "react";
import "./ConfirmationModal.scss";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
const ConfirmationModal = (props) => {
  const reasons = [
    "I have changed my mind",
    "I have found a better deal",
    "Ordered by mistake",
    "It is too expensive",
    "Delay of delivery",
  ];

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="cancel-modal">
        <div className="close">
          <IoClose size={20} onClick={props.onHide} />
        </div>
        <h3>Are you sure you want to Cancel Order?</h3>

        <div className="buttons">
          <button onClick={props.confirmFun} className="btn-secondary">
            Yes
          </button>
          <button
            onClick={() => {
              props.onHide();
            }}
            className="btn-primary"
          >
            No
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
