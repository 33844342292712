import { Modal } from "react-bootstrap";
import { domainName } from "../../../Constants";
import { IoCloseSharp } from "react-icons/io5";
import "./InvoiceModal.scss";

export const InvoiceModal = ({ order, date, updatedDate, ...props }) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="invoice-header-offcanvas">
        <span className="h5">order summary</span>
        <IoCloseSharp className="icon" onClick={() => props.onHide(false)} />
      </div>
      {/* <div className="underline-invoice-header"></div> */}
      <Modal.Body className="invoice-content">
        <span className="fw-500 order-id">Order ID : #{order.id}</span>
        <span className="title">Order Placed : {date}</span>
        <span className="title">Shipping address</span>
        <div className="address">
          <span>{order?.name}</span>
          <span style={{ textTransform: "uppercase" }}>{order?.address}</span>
          <span>{order?.state}</span>
        </div>
        <div className="deliver-date">Order delivered: {updatedDate}</div>
        {order.product.map((item) => (
          <div className="product-details">
            <img
              src={`${domainName}/uploads/${item.productDetail.main_image}`}
              alt="product"
            />
            <div>
              <span className="name">
                {item.productDetail.title} <br />{" "}
                <span>{item.productDetail.category__title}</span>
              </span>
              <span className="quantity">Qyt:{item.quantity}</span>
              <span className="price">
                ₹{item.productDetail.price * item.quantity}
              </span>
            </div>
          </div>
        ))}
        <div className="order-summary">
          {/* <span className="h5">Order Summary</span> */}
          <div className="underline-invoice-header"></div>
          <div className="flex-between sub-total">
            <span>Subtotal</span>
            <span>₹{order.total_amount - order.tax -order.shipping_fee + order.membership_discount +order.refrral_discount}</span>
          </div>
          {order?.membership_discount >0 ?<div className="flex-between">
            <span>Membership Discount</span>
            <span>₹{order.membership_discount}</span>
          </div>:<></>}
          {order?.refrral_discount >0 ?<div className="flex-between">
            <span>Refrral Discount</span>
            <span>₹{order.refrral_discount}</span>
          </div>:<></>}
          <div className="flex-between">
            <span>Tax</span>
            <span>₹{order.tax}</span>
          </div>
          <div className="flex-between">
            <span>Shipping</span>
            <span>₹{order.shipping_fee}</span>
          </div>
          <div className="underline-invoice-header"></div>
          <div className="flex-between total">
            <div className="h5">
              Total <span>Monthly payment</span>{" "}
            </div>
            <div className="h5 amount">
              ₹{order.total_amount} <span>₹6045/month with EMI</span>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
