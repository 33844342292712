import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import "./ProductReviewModal.scss";
import { addReview, editReview, getReviews } from "../../../apiCall";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { domainName } from "../../../Constants";
import { IoCloseSharp } from "react-icons/io5";
import { BsPersonCircle } from "react-icons/bs";
import { Rating } from "react-simple-star-rating";

export const ProductReviewModal = ({
  order,
  reviewData,
  setReviewData,
  setReviewingProductId,
  reviewingProductId,
  ...props
}) => {
  const user = JSON.parse(sessionStorage.getItem("user_details"));
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");

  useEffect(() => setReviewData(null), []);

  useQuery({
    queryKey: ["review", reviewingProductId],
    queryFn: () => getReviews(reviewingProductId),
    onSuccess: (response) => {
      setReviewData(
        response.data?.value.reviews.find(
          (review) => review.email === user?.email
        )
      );
    },
    onError: (err) => {
      // handle error
    },
  });

  useEffect(() => {
    setRating(reviewData?.ratings);
    setReview(reviewData?.review);
  }, [reviewData]);

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);
  };

  // submit review
  const submitReview = async () => {
    const data = {
      productId: reviewingProductId,
      ratings: rating,
      review,
    };
    try {
      const response = await addReview(data);
      if (response.data?.status[0].Error === "False") {
        toast.success("Review submitted");
        props.onHide();
      } else {
        toast.error(response.data?.status[0].ResponseMessage);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  // update review
  const updateReview = async () => {
    const data = {
      productId: reviewingProductId,
      ratings: rating,
      review,
    };
    try {
      const response = await editReview(data);
      if (response.data?.status[0].Error === "False") {
        toast.success("Review updated");
        props.onHide();
      } else {
        toast.error(response.data?.status[0].ResponseMessage);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  // cancel review
  const cancelReview = () => {
    props.onHide();
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="header-offcanvas">
        <span className="h5">
          {reviewData ? "Edit" : "Write"} product review
        </span>
        <IoCloseSharp className="icon" onClick={cancelReview} />
      </div>
      <Modal.Body className="review-content">
        <div className="">
          <span className="profile">
            {!user?.profile_image ? (
              <BsPersonCircle className="icon" />
            ) : (
              <img
                src={`${domainName}${user?.profile_image}`}
                alt="profile"
                className="profile_img"
              />
            )}
            {user?.name}
          </span>

          <div className="rating">
            <span>{reviewData ? "Edit" : "Add"} Rating</span>
            <Rating onClick={handleRating} initialValue={rating} size={25} />
          </div>
          <div className="input-box">
            <label htmlFor="">Write Review</label>
            <textarea
              name=""
              id=""
              rows="5"
              value={review}
              onChange={(e) => setReview(e.target.value)}
            />
          </div>
          <div className="buttons">
            <button className="btn-secondary" onClick={cancelReview}>
              Cancel
            </button>
            {!reviewData ? (
              <button className="btn-primary" onClick={submitReview}>
                Post review
              </button>
            ) : (
              <button className="btn-primary" onClick={updateReview}>
                Post review
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
