import React, { useEffect, useState } from "react";
import { ReactComponent as status1 } from "../../../../../assets/svgs/Property 1=2.svg";
import { ReactComponent as status2 } from "../../../../../assets/svgs/Property 1=Default.svg";
import { ReactComponent as status3 } from "../../../../../assets/svgs/Property 1=3.svg";
import productImg from "../../../../../assets/arts/art (2).png";
import { MdOutlineContentCopy } from "react-icons/md";
import "./EarningPayouts.scss";
import { OrderDetailModal } from "../../../../../components/modals/OrderDetailModal/OrderDetailModal";
import { FormatDate } from "../../../../../utils/FormateDate";
import toast from "react-hot-toast";
import ApexCharts from "apexcharts";
import { LineChart } from "./components/LineChart/LineChart";
import { IoIosArrowRoundUp } from "react-icons/io";
import { useQuery } from "@tanstack/react-query";
import {
  getDashboard,
  getRecentOrder,
  orderStatus,
} from "../../../../../apiCall";
import { domainName } from "../../../../../Constants";

const EarningPayouts = ({ setPage }) => {
  const filterOption = [
    "Confirmed",
    "Shipped",
    "Delivered",
    "Canceled",
    "Returned",
  ];
  const [dashboardData, setDashboardData] = useState({});
  const [activeEarningPeriod, setActiveEarningPeriod] = useState({});

  const [orderDetailData, setOrderDetailData] = useState({});
  const [recentOrder, setRecentOrder] = useState([]);
  const { isLoading } = useQuery(["earning"], getDashboard, {
    onSuccess: (data) => {
      if (data.status === 200) {
        setDashboardData(data.data?.value);
        setActiveEarningPeriod({
          period: "1_day",
          earning: data.data?.value?.last_day_earnings,
        });
      } else {
        toast.error("something went wrong there. please login again.");
      }
    },
    onError: (e) => {
      if (e.message) {
        return toast.error(e.message);
      }
      toast.error("Something went wrong");
    },
  });
  const {} = useQuery({
    queryKey: ["recentOrder"],
    queryFn: () => getRecentOrder(1),
    onSuccess: (data) => {
      console.log(data);
      if (data.status === 200) {
        setRecentOrder(data.data?.value);
      } else {
        toast.error("something went wrong there. please login again.");
      }
    },
    onError: (e) => {
      if (e.message) {
        return toast.error(e.message);
      }
      toast.error("Something went wrong");
    },
  });
  const handelOrderStatus = async (data) => {
    const re = await orderStatus(data);
    console.log(re);
    if (re?.status === 200) {
      toast.success("Order status changed successfully.");
    } else {
      // if (Re?.response.status === 409) {
      toast.error(re?.response.data.status[0].ResponseMessage);
    }
  };
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="earning-payout-container">
      <div className="list card-container">
        <div className="title">
          Earnings
          <hr />
        </div>
        <div className="statistics ">
          <div className="statistics-card">
            <span className="value">
              ₹
              {dashboardData?.total_earnings
                ? dashboardData?.total_earnings
                : 0}
            </span>
            <span>Total Earnings</span>
          </div>
          <div className="statistics-card">
            <span className="value">
              ₹
              {dashboardData?.pending_payouts
                ? dashboardData?.pending_payouts
                : 0}
            </span>
            <span>Pending Payouts</span>
          </div>
          <div className="statistics-card">
            <span className="value">
              ₹{dashboardData?.total_payouts ? dashboardData?.total_payouts : 0}
            </span>
            <span>Completed Payouts</span>
          </div>
        </div>
        <div className="growth-percentage">
          <div className="current-earning">
            {activeEarningPeriod?.earning}{" "}
            <span className="percentage">
              {" "}
              <IoIosArrowRoundUp size={16} />
              {activeEarningPeriod?.earning === 0?0:(((activeEarningPeriod?.earning) /
                dashboardData?.total_earnings ) *
                100).toFixed(2)}{" "}
              %{" "}
            </span>
          </div>
          <div className="date">Based on Dec 21, 5:45 PM, 2023</div>
          <div className="filter">
            <button
              className={`${
                activeEarningPeriod?.period === "1_day" ? "active" : ""
              }`}
              onClick={() =>
                setActiveEarningPeriod({
                  period: "1_day",
                  earning: dashboardData?.last_day_earnings,
                })
              }
            >
              {" "}
              1 Day
            </button>
            <button
              className={`${
                activeEarningPeriod?.period === "1_week" ? "active" : ""
              }`}
              onClick={() =>
                setActiveEarningPeriod({
                  period: "1_week",
                  earning: dashboardData?.last_week_earnings,
                })
              }
            >
              {" "}
              1 Week
            </button>
            <button
              className={`${
                activeEarningPeriod?.period === "1_month" ? "active" : ""
              }`}
              onClick={() =>
                setActiveEarningPeriod({
                  period: "1_month",
                  earning: dashboardData?.last_month_earnings,
                })
              }
            >
              1 Month
            </button>
            <button
              className={`${
                activeEarningPeriod?.period === "4_month" ? "active" : ""
              }`}
              onClick={() =>
                setActiveEarningPeriod({
                  period: "4_month",
                  earning: dashboardData?.last_4_month_earnings,
                })
              }
            >
              4 Month
            </button>
            <button
              className={`${
                activeEarningPeriod?.period === "1_year" ? "active" : ""
              }`}
              onClick={() =>
                setActiveEarningPeriod({
                  period: "1_year",
                  earning: dashboardData?.last_year_earnings,
                })
              }
            >
              Year
            </button>
            <button
              className={`${
                activeEarningPeriod?.period === "3_year" ? "active" : ""
              }`}
              onClick={() =>
                setActiveEarningPeriod({
                  period: "3_year",
                  earning: dashboardData?.last_3_year_earnings,
                })
              }
            >
              {" "}
              3 Year
            </button>
          </div>
        </div>

        <div className="chart">
          <LineChart dashboardData={dashboardData} />
        </div>
        <div className="table-container">
          {recentOrder.length > 0 ? (
            <table>
              <thead>
                <th>Artwork Title </th>
                <th>Price</th>
                <th>Stock</th>
                <th>Earrings</th>
                <th>Shipping</th>
                <th>Status</th>
                <th>Action</th>
              </thead>
              <tbody>
                <>
                  {recentOrder.map((order, index) => (
                    <tr>
                      <td>
                        <div className="data-wrapper">
                          {order.product.map((product) => (
                            <div
                              className="product-detail-wrapper"
                              id={`recent-order-${index}`}
                            >
                              <img
                                src={`${domainName}/uploads/${product.productDetail.main_image}`}
                                alt=""
                              />
                              <div className="product-detail">
                                <div className="name">
                                  {product.productDetail.title}
                                </div>
                                <div className="category">
                                  {product.productDetail.category__title}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </td>
                      <td>
                        <div className="data-wrapper">
                          {order.product.map((product) => (
                            <span>₹{product.productDetail.price}</span>
                          ))}
                        </div>
                      </td>
                      <td>
                        <div className="data-wrapper">
                          {order.product.map((product) => (
                            <span>{product.quantity}</span>
                          ))}
                        </div>
                      </td>
                      <td>₹{order.total_amount}</td>
                      <td>
                        <div className="shipping-type">Local delivery</div>
                      </td>
                      <td>
                        <select
                          defaultValue={order.status}
                          onChange={(e) =>
                            handelOrderStatus({
                              orderId: order.id.toString(),
                              status: e.target.value,
                            })
                          }
                        >
                          <option value="Received" disabled>
                            Received
                          </option>
                          {filterOption.map((option, index) => (
                            <option value={option}>{option}</option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <div className="action-wrapper">
                          <span
                            onClick={() => {
                              setOrderDetailData(order);
                              setShowModal(true);
                            }}
                          >
                            View Details
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              </tbody>
            </table>
          ) : (
            "You don't have any orders."
          )}
        </div>
      </div>
      <OrderDetailModal
        order={orderDetailData}
        show={showModal}
        onHide={() => setShowModal(false)}
      />
    </div>
  );
};

export default EarningPayouts;
