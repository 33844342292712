import React from "react";
import { useForm } from "react-hook-form";
import { createSupportTicket } from "../../../../../../../apiCall";
import toast from "react-hot-toast";
import { useStateValue } from "../../../../../../../StateProvider";
import { ValidateNumberLength } from "../../../../../../../utils/ValidateNumberLength";

const SupportForm = () => {
  const [, dispatch] = useStateValue();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = async (data) => {
    dispatch({ type: "SET_LOADING", status: true });

    const re = await createSupportTicket(data);
    console.log(re);
    if (re?.status === 201) {
      toast.success(re?.data.status[0].ResponseMessage);
      reset();
    } else {
      toast.error(re?.response.data.status[0].ResponseMessage);
    }
    dispatch({ type: "SET_LOADING", status: false });
  };
  return (
    <div className="seller-support">
      <div className="seller-form-wrapper">
        <div className="title">Contact customer support</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-container">
            <div className="row-wrapper">
              <div className="input-wrapper">
                <label>Name</label>
                <input
                  type="text"
                  placeholder="Enter Your Name."
                  {...register("name", {
                    required: "Name is required.",
                    maxLength: {
                      value: 20,
                      message: "Name should be less then 20 characters.",
                    },
                  })}
                />
                {errors.name && (
                  <div className="error">{errors.name.message}</div>
                )}
              </div>
              <div className="input-wrapper">
                <label>Email</label>
                <input
                  type="email"
                  placeholder="john.doe@email.com"
                  {...register("email", {
                    required: "Email is required.",
                  })}
                />
                {errors.email && (
                  <div className="error">{errors.email.message}</div>
                )}
              </div>
            </div>
            <div className="input-wrapper">
              <label>Mobile Number</label>
              <input
                type="number"
                placeholder="9173xxxxxx"
                {...register("phone_number", {
                  required: "Mobile Number is required.",
                  pattern: {
                    value: /^[6-9]\d{9}$/,
                    message: "Invalid Mobile Number.",
                  },
                })}
                onChange={(e) =>
                  ValidateNumberLength("phone_number", e, setValue)
                }
              />
              {errors.phone_number && (
                <div className="error">{errors.phone_number.message}</div>
              )}
            </div>

            <div className="input-wrapper">
              <label>Message</label>
              <textarea
                rows={6}
                type="text"
                placeholder="Leave us a message"
                {...register("message", {
                  required: "Message is required.",
                  maxLength: {
                    value: 500,
                    message: "Message should be less then 500 characters.",
                  },
                })}
              />
              {errors.message && (
                <div className="error">{errors.message.message}</div>
              )}
            </div>

            <div className="btn-wrapper">
              <button className="btn-primary">Submit</button>
            </div>
          </div>
        </form>
        <div className="contact-info">
          <h3>Ways to Contact Us:</h3>
          <span>Tart@example.com</span>
          <span>(603) 555-0123</span>
          <span>8502 Preston Rd. Inglewood, Maine 98380</span>
        </div>
      </div>
    </div>
  );
};

export default SupportForm;
