import { BrowserRouter, Route, Routes, useLocation, useParams } from "react-router-dom";
import "./App.scss";
import { Signup } from "./pages/Signup/Signup";
import { Navbar } from "./components/Navbar/Navbar";
import { Login } from "./pages/Login/Login";
import { Home } from "./pages/Home/Home";
import { Footer } from "./components/Footer/Footer";
import { Shop } from "./pages/Shop/Shop";
import { ProductDetails } from "./pages/ProductDetails/ProductDetails";
import { useEffect } from "react";
import { ProfileOptions } from "./components/ProfileOptions/ProfileOptions";
import { Checkout } from "./pages/Checkout/Checkout";
import { useStateValue } from "./StateProvider";
import { Rental } from "./pages/Rental/Rental";
import { Settings } from "./pages/Settings/Settings";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { BuildCustomArt } from "./pages/BuildCustomArt/BuildCustomArt";
import { ForgotPassword } from "./pages/ForgotPassword/ForgotPassword";
import { ContactUs } from "./pages/ContactUs/ContactUs";
import { AboutUs } from "./pages/AboutUs/AboutUs";
import { Toaster } from "react-hot-toast";
import { ProtectRoute, PublicRoute } from "./ProtectRoutes";
import AOS from "aos";
import "aos/dist/aos.css";
import { useQuery } from "@tanstack/react-query";
import { fetchUser, getFavorites, getSellerFavorites } from "./apiCall";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { googleClientId } from "./Constants";
import { LoadingSpinner } from "./components/LoadingSpinner";
import { SellerProductDetails } from "./pages/Seller/ProductDetails/SellerProductDetails";
import { BecomeSellerNavbar } from "./components/NavbarDashboard/BecomeSellerNavbar";
import { SellerNavbar } from "./pages/Seller/Components/Navbar/SellerNavbar";
import SellerHome from "./pages/Seller/SellerHome/SellerHome";
import SellerSignup from "./pages/Seller/SellerSignup/SellerSignup";
import SellerDashboard from "./pages/Seller/SellerDashboard/SellerDashboard";
import { SellerShop } from "./pages/Seller/SellerShop/SellerShop";
import { SellerCheckout } from "./pages/Seller/Checkout/SellerCheckout";
import { SellerSettings } from "./pages/Seller/SellerSettings/Settings";
import Membership from "./pages/Membership/Membership";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse/TermsOfUse";

export const ScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return null;
};

function App() {
    const [{ showProfileOptions, isLoading }, dispatch] = useStateValue();
    AOS.init({
        once: true,
        delay: 400,
        duration: 1000,
        // offset:220
    });
    // Get user data
    useQuery({
        queryKey: ["user-data"],
        queryFn: fetchUser,
        onSuccess: (data) => {
            // console.log(data.data);
            if (data.data?.user) {
                sessionStorage.setItem(
                    "user_details",
                    JSON.stringify(data.data.user)
                );
            }
        },
        onError: (err) => {
            // handle error
        },
    });

    // get list of favorites
    useQuery(["favorites"], (JSON.parse(sessionStorage.getItem('user_details'))?.user_type || JSON.parse(localStorage.getItem('user_details'))?.user_type) === 'seller_customer' ? getSellerFavorites : getFavorites, {
        onSuccess: (data) => {
            if (data.data?.status[0]?.Error === "False") {
                dispatch({ type: "SET_FAVORITE_LIST", data: data.data.value });
            }
        },
        onError: (err) => console.log(err),
    });
    return (
        <div
            onClick={() =>
                showProfileOptions &&
                dispatch({ type: "PROFILE_OPTIONS_VIEW", status: false })
            }>
            <GoogleOAuthProvider clientId={googleClientId}>
                <BrowserRouter>
                    <Toaster position='top-center' reverseOrder={false} />
                    {isLoading && <LoadingSpinner />}
                    <ScrollToTop />
                    <Routes>
                        <Route
                            path='/signup'
                            element={
                                <PublicRoute>
                                    <Navbar />
                                    <Signup />
                                </PublicRoute>
                            }
                        />
                        <Route
                            path='/login'
                            element={
                                <PublicRoute>
                                    <Navbar />
                                    <Login />
                                </PublicRoute>
                            }
                        />
                        <Route
                            path='/'
                            element={
                                <ProtectRoute>
                                    <Navbar />
                                    <Home />
                                    {/* <Footer /> */}
                                </ProtectRoute>
                            }
                        />
                        <Route
                            path='/shop'
                            element={
                                <ProtectRoute>
                                    <Navbar />
                                    <Shop />
                                    <Footer />
                                </ProtectRoute>
                            }
                        />
                        <Route
                            path='/product-info/:productId'
                            element={
                                <ProtectRoute>
                                    <Navbar />
                                    <ProductDetails />
                                    <Footer />
                                </ProtectRoute>
                            }
                        />
                        <Route
                            path='/checkout'
                            element={
                                <ProtectRoute>
                                    <Navbar />
                                    <Checkout />
                                    <Footer />
                                </ProtectRoute>
                            }
                        />
                        <Route
                            path='/rental'
                            element={
                                <ProtectRoute>
                                    <Navbar />
                                    <Rental />
                                    <Footer />
                                </ProtectRoute>
                            }
                        />
                        <Route
                            path='/settings/:page'
                            element={
                                <ProtectRoute>
                                    <Navbar />
                                    <Settings />
                                    <Footer />
                                </ProtectRoute>
                            }
                        />
                        <Route
                            path='/dashboard/:page'
                            element={
                                <ProtectRoute>
                                    <Navbar />
                                    <Dashboard />
                                </ProtectRoute>
                            }
                        />
                        <Route
                            path='/build-custom-art'
                            element={
                                <ProtectRoute>
                                    <Navbar />
                                    <BuildCustomArt />
                                </ProtectRoute>
                            }
                        />
                        <Route
                            path='/forgot-password'
                            element={
                                <ProtectRoute>
                                    <Navbar />
                                    <ForgotPassword />
                                </ProtectRoute>
                            }
                        />
                        <Route
                            path='/contact-us'
                            element={
                                <ProtectRoute>
                                    <Navbar />
                                    <ContactUs />
                                    <Footer />
                                </ProtectRoute>
                            }
                        />
                        <Route
                            path='/about-us'
                            element={
                                <ProtectRoute>
                                    <Navbar />
                                    <AboutUs />
                                    <Footer />
                                </ProtectRoute>
                            }
                        />
                        <Route
                            path='/membership'
                            element={
                                <ProtectRoute>
                                    <Navbar />
                                    <Membership />
                                    <Footer />
                                </ProtectRoute>
                            }
                        />
                        <Route
                            path='/become-seller'
                            element={
                                <ProtectRoute>
                                    <Navbar />
                                    <SellerHome />
                                    <Footer />
                                </ProtectRoute>
                            }
                        />
                        <Route
                            path='/privacy-policy'
                            element={
                                <ProtectRoute>
                                    <Navbar />
                                    <PrivacyPolicy />
                                    <Footer />
                                </ProtectRoute>
                            }
                        />
                        <Route
                            path='/terms-of-use'
                            element={
                                <ProtectRoute>
                                    <Navbar />
                                    <TermsOfUse />
                                    <Footer />
                                </ProtectRoute>
                            }
                        />
                        <Route
                            path='/seller/:storename/product-info/:productId'
                            element={
                                <ProtectRoute>
                                    <SellerNavbar />
                                    <SellerProductDetails />
                                    <Footer />
                                </ProtectRoute>
                            }
                        />

                        <Route
                            path='/seller'
                            element={
                                <>
                                    <BecomeSellerNavbar />
                                    {/* <Footer /> */}
                                </>
                            }>
                            <Route
                                path='dashboard/:page'
                                element={
                                    <ProtectRoute>
                                        <SellerDashboard />
                                    </ProtectRoute>
                                }
                            />
                            <Route
                                path='signup'
                                element={
                                    <ProtectRoute>
                                        <SellerSignup />
                                    </ProtectRoute>
                                }
                            />
                        </Route>
                        <Route
                            path='/seller/:storename/signup'
                            element={
                                <PublicRoute>
                                    <SellerNavbar />
                                    <Signup />
                                </PublicRoute>
                            }
                        />
                        <Route
                            path='/seller/:storename/login'
                            element={
                                <PublicRoute>
                                    <SellerNavbar />
                                    <Login />
                                </PublicRoute>
                            }
                        />
                        <Route
                            path='/seller/:storename/about-us'
                            element={
                                <ProtectRoute>
                                    <SellerNavbar />
                                    <AboutUs />
                                    <Footer />
                                </ProtectRoute>
                            }
                        />
                        <Route
                            path='/seller/:storename/contact-us'
                            element={
                                <ProtectRoute>
                                    <SellerNavbar />
                                    <ContactUs />
                                    <Footer />
                                </ProtectRoute>
                            }
                        />
                        <Route
                            path='/seller/:storename'
                            element={
                                <ProtectRoute>
                                    <SellerNavbar />
                                    <SellerShop />
                                    <Footer />
                                </ProtectRoute>
                            }
                        />
                        <Route
                            path='/seller/:storename/become-seller'
                            element={
                                <ProtectRoute>
                                    <SellerNavbar />
                                    <SellerHome />
                                    <Footer />
                                </ProtectRoute>
                            }
                        />
                        <Route
                            path='/seller/:storename/build-custom-art'
                            element={
                                <ProtectRoute>
                                    <SellerNavbar />
                                    <BuildCustomArt />
                                </ProtectRoute>
                            }
                        />
                        <Route
                            path='/seller/:storename/settings/:page'
                            element={
                                <ProtectRoute>
                                    <SellerNavbar />
                                    <SellerSettings />
                                    <Footer />
                                </ProtectRoute>
                            }
                        />
                        <Route
                            path='/seller/:storename/checkout'
                            element={
                                <ProtectRoute>
                                    <SellerNavbar />
                                    <SellerCheckout />
                                    <Footer />
                                </ProtectRoute>
                            }
                        />
                    </Routes>
                </BrowserRouter>
            </GoogleOAuthProvider>
        </div>
    );
}

export default App;
