import React from "react";
import "./CategoryModal.scss";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { useStateValue } from "../../../StateProvider";
import { addSellerCategories, contact } from "../../../apiCall";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
const AddCategoryModal = (props) => {
  const [, dispatch] = useStateValue();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",

    },
  });
  const onSubmit = async (values) => {
    dispatch({ type: "SET_LOADING", status: true });
    try {
      const response = await addSellerCategories(values);
      if (response.data.status[0].Error === "False") {
        toast.success("Category Added successfully");
        reset();
        props.refetch();
        props.onHide();

      } else {
        toast(response.data.status[0].ResponseMessage, { icon: "⚠️" });
      }
    } catch (err) {
      toast.error(err.message);
    }
    dispatch({ type: "SET_LOADING", status: false });
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="category-modals"
    >
      <Modal.Body className="category-modal">
        <div className="close">
          <IoClose size={20} onClick={props.onHide} />
        </div>
        <h3>Add New Category</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-box">
            <label htmlFor="">Category Name</label>
            <input
              type="text"
              placeholder="Enter Category name"
              {...register("title", {
                required: "This field is required",
                
              })}
            />
            <small className="error">{errors.title?.message}</small>
          </div>

          <div className="buttons">
            <button
           
              className="btn-primary"
            >
              Add New Category
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddCategoryModal;
