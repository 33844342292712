import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Multiselect from "multiselect-react-dropdown";
import {
    GetCountries,
    GetState,
    GetLanguages, //async functions
} from "react-country-state-city";

const ShippingOption = ({ setActiveStep, onCancel, setMyData, myData }) => {
    const [formData, setFormData] = useState({ countery: "", state: "" });
    // const counteryOption = ["india", "uk"];
    const counteryOption = [
        { title: "India", id: 0 },
        { title: "uk", id: 1 },
    ];

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const onSubmit = (data) => {
        setMyData((pre) => {
            return {
                ...pre,
                shipping_option_name: data.shipping_option_name,
                shipping_time_estimate: data?.shipping_time_estimate,
                shipping_fee: data.shipping_fee,
                countries: formData.countery.map((country) => country.name),
                states: formData.state.map((state) => state.name),
            };
        });
        console.log(data);
        setActiveStep(3);
    };
    const [countriesList, setCountriesList] = useState([]);
    const [stateList, setStateList] = useState([]);
    useEffect(() => {
      reset({
          shipping_option_name: myData.shipping_option_name,
          shipping_time_estimate: myData.shipping_time_estimate,
          shipping_fee: myData.shipping_fee,
      });
      setFormData({countery: myData?.countries, state: myData?.states})
        GetCountries().then((result) => {
            setCountriesList(result);
        });
    }, []);

    useEffect(() => {
        let states = [];
        setStateList([]);
        for (let indx in formData?.countery) {
            GetState(formData?.countery[indx].id).then((result) => {
                // console.log("for country", formData?.countery[indx],result)
                setStateList((pre) => [...pre, ...result]);
            });
        }
        console.log("total ", stateList);
    }, [formData?.countery]);
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='input-container'>
                {/* <div className='input-wrapper'>
                    <label>Shipping Option Name</label>
                    <input
                        type='text'
                        placeholder='Local delivery'
                        {...register("shipping_option_name", {
                            required: "Shipping Option is required.",
                            maxLength: {
                                value: 50,
                                message:
                                    "Shipping Option should be less then 50 characters.",
                            },
                        })}
                    />
                    {errors.shipping_option_name && (
                        <div className='error'>
                            {errors.shipping_option_name.message}
                        </div>
                    )}
                </div>
                <div className='input-wrapper'>
                    <label>Shipping Time Estimate</label>
                    <input
                        type='number'
                        placeholder='1-3 business days'
                        {...register("shipping_time_estimate", {
                            required: "Shipping Time is required.",
                            min: {
                                value: 1,
                                message: "Shipping Time must be greter then 1",
                            },
                        })}
                    />
                    {errors.shipping_time_estimate && (
                        <div className='error'>
                            {errors.shipping_time_estimate.message}
                        </div>
                    )}
                </div> */}
                <div className='input-wrapper'>
                    <label>Shipping Fee</label>
                    <div className='input-inner-wrapper'>
                        <input
                            type='number'
                            placeholder='$10.00'
                            {...register("shipping_fee", {
                                required: "Shipping Fee is required.",
                                min: {
                                    value: 1,
                                    message:
                                        "Shipping Fee must be greter then 1",
                                },
                            })}
                        />
                        <span>$</span>
                    </div>
                    {errors.shipping_fee && (
                        <div className='error'>
                            {errors.shipping_fee.message}
                        </div>
                    )}
                </div>

                <div className='row'>
                    <div className='input-wrapper'>
                        <div className='input-inner-wrapper'>
                            <Multiselect
                                options={countriesList}
                                onRemove={(selectedList) =>
                                    setFormData({
                                        ...formData,
                                        countery: selectedList,
                                    })
                                }
                                onSelect={(selectedList) =>
                                    setFormData({
                                        ...formData,
                                        countery: selectedList,
                                    })
                                }
                                selectedValues={formData.countery}
                                displayValue='name'
                                onChange={(e) => {
                                    console.log(e);
                                    return setFormData({
                                        ...formData,
                                        countery: e.target.value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className='input-wrapper'>
                        <div className='input-inner-wrapper'>
                            <Multiselect
                                options={stateList}
                                onRemove={(selectedList) =>
                                    setFormData({
                                        ...formData,
                                        state: selectedList,
                                    })
                                }
                                onSelect={(selectedList) =>
                                    setFormData({
                                        ...formData,
                                        state: selectedList,
                                    })
                                }
                                selectedValues={formData.state}
                                displayValue='name'
                                onChange={(e) => {
                                    console.log(e);
                                    return setFormData({
                                        ...formData,
                                        state: e.target.value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className='btn-wrapper'>
                    <div
                        className='cancel-btn btn-secondary'
                        onClick={() => onCancel()}>
                        Cancel
                    </div>
                    <button className='btn-primary' type='submit'>
                        Save and Next
                    </button>
                </div>
            </div>
        </form>
    );
};

export default ShippingOption;
