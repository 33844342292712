import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MdOutlineCheckBox } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import "../../SellerSignup.scss";
import {
  GetCountries,
  GetState,
  GetCity,
  GetLanguages, //async functions
} from "react-country-state-city";
import { sellerSignup, sendPhoneOtp, verifyPhoneOtp } from "../../../../../apiCall";
import toast from "react-hot-toast";
import { useStateValue } from "../../../../../StateProvider";

const SellerRegister = ({ setActiveTab }) => {
  const [, dispatch] = useStateValue();

  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [cityid, setCityid] = useState();

  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  useEffect(() => {
    GetCountries().then((result) => {
      setCountriesList(result);
    });
  }, []);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      gender: "male",
      phoneNumber: "",
      email: "",
      country: "",
      city: "",
      sellingType: "art",
      isEmailNotificationActive: false,
    },
  });
  const onSubmit = async (data) => {
    const Re = await sellerSignup({...data, token: token});

    console.log(Re)
    console.log(Re?.status)
    if (Re?.status === 200) {
      toast.success("The seller registered successfully.")

      console.log(Re.data.value);
      sessionStorage.setItem("token", Re.data.access_token);
      dispatch({ type: "SET_LOGIN_STATUS", status: true });
      dispatch({ type: "SET_USER_DETAIL", detail: Re.data.value });
      setActiveTab("panVerification");
    } else if(Re?.response?.status === 401){
      setError("otp", {type: "custom", message: "Please verify phone number first."})
    } else {
      // if (Re?.response.status === 409) {
      toast.error(Re?.response.data.status[0].ResponseMessage);
    }
    // }
  };
  const [gender, setGender] = useState("male");
  const [sellingType, setSellingType] = useState("art");
  const [isOtpSent, setIsOtpSent] = useState(false)
  const [token, setToken] = useState()
  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const sendVerificationOtp = async (e) => {
    e.preventDefault()
    if (!getValues("phoneNumber")) {
        setError("phoneNumber", {type: "custom", message: "Phone Number is required"})
        return;
      }else if(!getValues("phoneNumber")?.match(/^[6-9]\d{9}$/)){
        setError("phoneNumber", {type: "custom", message: "Invalid Mobile Number"})
        return;
      }else{
        setError("phoneNumber", null)
      }
    dispatch({ type: "SET_IS_LOADING", status: true });

    const Re = await sendPhoneOtp({ phone_number: getValues("phoneNumber")});
    dispatch({ type: "SET_IS_LOADING", status: false });
    console.log(Re);

    if (Re?.data?.status[0].Error === "False") {
        toast.success("OTP Sent successfully.");
        setIsOtpSent(true)
        sessionStorage.setItem("reset_pass_token", Re.data.token)
    } else if (Re?.data?.status) {
        toast.error(Re.data.status[0].ResponseMessage);
    } else {
        toast.error("Something went wrong, Please try again.");
    }
  };
  const verifyOtp = async (e) => {
    e.preventDefault();
    if(!isOtpSent){
      setError("phoneNumber", {type: "custom", message: "Please send OTP first."})
      return;
    }else{
      setError("phoneNumber", null)
    }
    if (!getValues("otp")) {
      setError("otp", {type: "custom", message: "OTP is required."})
      return;
    } else {
      setError("otp", null)
    }

    dispatch({ type: "SET_IS_LOADING", status: true });
    const Re = await verifyPhoneOtp({phone_number: getValues("phoneNumber"), phone_number_otp: getValues("otp")});
    dispatch({ type: "SET_IS_LOADING", status: false });
    console.log(Re);

    if (Re?.data?.status[0].Error === "False") {
        setToken(Re.data.token);
        toast.success("Phone number verified.");
    } else if (Re?.response?.data?.status) {
        toast.error(Re?.response.data.status[0].ResponseMessage);
    } else {
        toast.error("Something went wrong, Please try again.");
    }
  };

  return (
    <div className="seller-register">
      <div className="seller-form-wrapper" data-aos="fade-down">
        <div className="title">Register for Seller</div>
        <form>
          <div className="input-container">
            <div className="input-wrapper">
              <label>Name</label>
              <input
                type="text"
                placeholder="Enter Your Name."
                {...register("name", {
                  required: "Name is required.",
                  maxLength: {
                    value: 20,
                    message: "Name should be less then 20 characters.",
                  },
                })}
              />
              {errors.name && (
                <div className="error">{errors.name.message}</div>
              )}
            </div>
            <div className="gender">
              <div className="gender-input-wrapper">
                <div className="input-wrapper">
                  <input
                    type="checkbox"
                    value="male"
                    checked={gender === "male"}
                    onChange={handleGenderChange}
                  />
                  <label>Male</label>
                </div>
                <div className="input-wrapper">
                  <input
                    type="checkbox"
                    value="female"
                    checked={gender === "female"}
                    onChange={handleGenderChange}
                  />
                  <label>Female</label>
                </div>
              </div>
              {errors.gender && (
                <div className="error">{errors.gender.message}</div>
              )}
            </div>
            <div className="address">
              <div className="input-wrapper">
                <label>Your country</label>
                <select
                  type="text"
                  value={country?.name}
                  {...register("country", {
                    required: "Country is required.",
                    onChange: (e) => {
                      const country = countriesList.find(
                        (item) => item.name === e.target.value
                      );

                      console.log(country);
                      setCountry(country);
                      setValue("country", country?.name);
                      GetState(country?.id).then((result) => {
                        setStateList(result);
                      });
                    },
                  })}
                >
                  <option key={0} value="">
                    Select Country
                  </option>
                  {countriesList.map((item, index) => (
                    <option key={index + 1} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {errors.country && (
                  <div className="error">{errors.country.message}</div>
                )}
              </div>
              <div className="input-wrapper">
                <label>Your State</label>
                <select
                  type="text"
                  placeholder="state,"
                  value={state?.name}
                  {...register("state", {
                    required: "State is required.",
                    onChange: (e) => {
                      const state = stateList.find(
                        (item) => item.name === e.target.value
                      ); //here you will get full state object.

                      setState(state);
                      setValue("state", state?.name);

                      GetCity(country?.id, state.id).then((result) => {
                        setCityList(pre => result);
                      });
                    },
                  })}
                >
                  <option key={0} value="">
                    Select State
                  </option>
                  {stateList.map((item, index) => (
                    <option key={index + 1} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {errors.state && (
                  <div className="error">{errors.state.message}</div>
                )}
              </div>
              <div className="input-wrapper">
                <label>Your city</label>
                <select
                  type="text"
                  placeholder="city,"
                  value={cityid}
                  {...register("city", {
                    required: "City is required.",
                    onChange: (e) => {
                      const city = cityList.find(
                        (item) => item.name === e.target.value
                      ); //here you will get full city object.
                      setCityid(city?.name);
                      setValue("city", city?.name);
                    },
                  })}
                >
                  <option key={0} value="">
                    Select City
                  </option>
                  {cityList.map((item, index) => (
                    <option key={index + 1} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {errors.city && (
                  <div className="error">{errors.city.message}</div>
                )}
              </div>
            </div>
            <div className="input-wrapper">
              <label>Mobile Number</label>
              <div className="input-btn-container">
                <input
                  type="number"
                  placeholder="Enter your phone number"
                  {...register("phoneNumber", {
                    required: "Mobile Number is required.",
                    pattern:{
                      value:/^[6-9]\d{9}$/,
                      message:"Invalid Mobile Number."
                    }
                  })}
                />
                <button className="btn-primary" onClick={(e) => sendVerificationOtp(e)}>Send OTP</button>
              </div>
              {errors.phoneNumber && (
                <div className="error">{errors.phoneNumber.message}</div>
              )}
            </div>
            <div className="input-wrapper">
              <label>Mobile OTP</label>
              <div className="input-btn-container">
                <input
                  type="number"
                  placeholder="XXXXXX"
                  {...register("otp", {
                    required: "OTP is required.",
                  })}
                />
                <button className="btn-primary" onClick={(e) => verifyOtp(e)}>Verify OTP</button>
              </div>
              {errors.otp && (
                <div className="error">{errors.otp.message}</div>
              )}
            </div>
            <div className="input-wrapper">
              <label>Email</label>
              <input
                type="email"
                placeholder="john.doe@email.com"
                {...register("email", {
                  required: "Email Number is required.",
                })}
              />
              {errors.email && (
                <div className="error">{errors.email.message}</div>
              )}
            </div>
            <div className="email-notification">
              <div className="input-wrapper">
                <input
                  type="checkbox"
                  {...register("isEmailNotificationActive", {})}
                />
                <label>i agree receive email notificationsmail</label>
              </div>
            </div>
            <div className="product-type-wrapper">
              <label>What are you selling?</label>
              <div className="product-type">
                <div
                  className={`${
                    sellingType === "art" ? " btn-primary" : "btn-secondary"
                  }`}
                  onClick={() => {
                    setSellingType("art");
                    setValue("sellingType", "art");
                  }}
                >
                  Art
                </div>
                <div
                  className={`${
                    sellingType === "drawings"
                      ? " btn-primary"
                      : "btn-secondary"
                  }`}
                  onClick={() => {
                    setSellingType("drawings");
                    setValue("sellingType", "drawings");
                  }}
                >
                  Drawings
                </div>
              </div>
            </div>
            <button className="btn-primary" onClick={handleSubmit(onSubmit)}>Next</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SellerRegister;
