import React from "react";
import "./ProgressBar.scss";
import { ReactComponent as Status1 } from "../../../../../../../../../assets/svgs/Property 1=2.svg";
import { ReactComponent as Status2 } from "../../../../../../../../../assets/svgs/Property 1=Default.svg";
import { ReactComponent as Status3 } from "../../../../../../../../../assets/svgs/Property 1=3.svg";
const ProgressBar = ({ actveStep = 0 }) => {
  const stepList = [
    "Artwork Listing",
    "Details",
    "Shipping Information",
    "Artist's Profile & Reviews",
  ];
  return (
    <div className="progress-bar-container">
      <ul>
        {stepList.map((step, index) => (
          <li key={"prog-"+index}>
            {index > actveStep && <Status1 />}
            {index === actveStep && <Status2 />}
            {index < actveStep && <Status3 />}

            {step}
          </li>
        ))}
        <div className="line"></div>
      </ul>
    </div>
  );
};

export default ProgressBar;
