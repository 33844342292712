import React, { useEffect, useState } from "react";
import "./Faq.scss";
import { useStateValue } from "../../../../../../../StateProvider";
import { useQuery } from "@tanstack/react-query";
import { getSellerFaq } from "../../../../../../../apiCall";
import toast from "react-hot-toast";
import { SellerAccordionFAQ } from "../../../../../../Home/components/SellerAccordionFAQ/SellerAccordionFAQ";

const Faq = ({ setActiveTab }) => {
  // const faqs = [
  //   {
  //     title: "What can I sell?",
  //     content:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, ",
  //   },
  //   {
  //     title: "How much money can I make?",
  //     content:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, ",
  //   },
  //   {
  //     title: "How much time will I need to invest?",
  //     content:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, ",
  //   },
  //   {
  //     title: "How do I price my service?",
  //     content:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, ",
  //   },
  // ];
  const [, dispatch] = useStateValue();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["support-ticket"],
    queryFn: () => getSellerFaq(),
    onSuccess: (data) => {
      console.log(data);
      if (data.status === 200) {
        setFaqs(data.data?.value);
      } else {
        toast.error(data.response.data.status[0].ResponseMessage);
      }
    },
    onError: (e) => {
      if (e.message) {
        return toast.error(e.message);
      }
      toast.error("Something went wrong");
    },
  });
  const [faqs, setFaqs] = useState(
    data?.status === 200 ? data.data?.value : []
  );
  useEffect(() => {
    if (isLoading) {
      dispatch({ type: "SET_LOADING", status: true });
    } else {
      dispatch({ type: "SET_LOADING", status: false });
    }
  }, [isLoading]);
  return (
    <div className="faq card-container">
      <div className="title">Frequently asked questions</div>
      <div className="row">
      
        <div className="right-column">
          <div className="faq-wrapper">
            {faqs.map((faq) => (
              <SellerAccordionFAQ title={faq.title} content={faq.description} />
            ))}
          </div>
        </div>
      </div>
   
    </div>
  );
};

export default Faq;
