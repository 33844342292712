import { LuLayoutDashboard, LuSettings } from "react-icons/lu";
import { BsHandbag, BsSuitHeart } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import { RiLogoutBoxLine } from "react-icons/ri";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useStateValue } from "../../StateProvider";

export const ProfileOptions = ({
  storename,
  handleMouseEnter,
  handleMouseLeave,
}) => {
  const [, dispatch] = useStateValue();
  const navigate = useNavigate();

  // Do user logout
  const doLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    dispatch({ type: "SET_LOGIN_STATUS", status: false });
    navigate("/");
  };
  return (
    <div className="profile-options-bg">
      {console.log(storename)}
      <div
        className="profile-options"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* <Link className="underline-none" to={`/dashboard/${"dashboard"}`}> */}
        {/* <div className="option">
            <LuLayoutDashboard className="icon" />
            Dashboard
          </div> */}
        {/* </Link> */}
        <Link
          className="underline-none"
          to={
            storename
              ? `/seller/${storename}/settings/my-orders`
              : "/settings/my-orders"
          }
        >
          <div className="option">
            <BsHandbag className="icon" />
            My Orders
          </div>
        </Link>
        <Link
          className="underline-none"
          to={
            storename
              ? `/seller/${storename}/settings/favorites`
              : "/settings/favorites"
          }
        >
          <div className="option">
            <BsSuitHeart className="icon" />
            Favorites
          </div>
        </Link>
        <Link
          className="underline-none"
          to={
            storename
              ? `/seller/${storename}/settings/edit-profile`
              : `/settings/${"edit-profile"}`
          }
        >
          <div className="option">
            <FaRegEdit className="icon" />
            Edit Profile
          </div>
        </Link>
        {/* <Link className="underline-none" to={storename ? `/seller/${storename}/settings/settings` : `/settings/${"settings"}`}>
          <div className="option">
            <LuSettings className="icon" />
            Settings
          </div>
        </Link> */}
        <div className="option" onClick={doLogout}>
          <RiLogoutBoxLine className="icon" />
          Log Out
        </div>
      </div>
    </div>
  );
};
