import React, { useRef, useState } from "react";
import "./EditProfile.scss";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { updateProfileImg, updateUser } from "../../../../../apiCall";
import { useQueryClient } from "@tanstack/react-query";
import { domainName } from "../../../../../Constants";

const EditProfile = () => {
  const user = JSON.parse(sessionStorage.getItem("user_details"));
  const queryClient = useQueryClient();
  const [enableEdit, setEnableEdit] = useState(false);
  const imgRef = useRef();
  const [profilePic, setProfilePic] = useState();
  const enableEditOption = () => {
    setEnableEdit(true);
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: user?.name || "",
      email: user.email || "",
      phoneNumber: user?.phone_number || "",
    },
  });
  const onImageSelect = async (e) => {
    const fData = new FormData();
    fData.append("profile_img", e.target.files[0]);
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (
        file.type === "image/x-png" ||
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      ) {
        try {
          const response = await updateProfileImg(fData);
          if (response.data?.status[0].Error === "False") {
            setProfilePic({ url: URL.createObjectURL(file) });
            queryClient.invalidateQueries("user-data");
            toast.success(response.data?.status[0].Message);
          } else {
            toast(response.data?.status[0].ResponseMessage, { icon: "⚠️" });
          }
        } catch (err) {
          toast.error(err.message);
        }
      } else {
        return toast("Select an image file", {
          icon: "❌",
          position: "top-center",
          style: {
            borderRadius: "10px",
          },
        });
      }
    }
  };
  const cancelEditProfile = () => {
    setValue("name", user?.name || "");
    setValue("email", user?.email || "");
    setValue("phoneNumber", user?.phone_number || "");
    setEnableEdit(false);
  };

  const handleFormUpdate = async (values) => {
    try {
      const response = await updateUser(values);
      if (response.data?.status[0].Message === "success") {
        queryClient.invalidateQueries("user-data");
        toast.success(response.data?.status[0].ResponseMessage);
        if (localStorage.getItem('token')) {
          localStorage.setItem("token", response.data.access_token);
        } else {
          sessionStorage.setItem("token", response.data.access_token);
        }
      } else {
        toast(response.data?.status[0].ResponseMessage, { icon: "⚠️" });
      }
    } catch (err) {
      toast.error(err.message);
      console.log(err, "error");
    }
  };
  return (
    <div className="edit-profile">
      <h2>Edit Profile</h2>
      <div className="image-div">
        <h5>Profile Image</h5>
        <img
          src={
            profilePic?.url || user.profile_image
              ? `${domainName}${user?.profile_image}`
              : "https://img.freepik.com/free-icon/user_318-159711.jpg"
          }
          alt="profile"
          className="profile-img"
          onClick={() => imgRef.current.click()}
        />
        <input
          type="file"
          ref={imgRef}
          accept="image/x-png,image/jpeg,image/jpg,image/png"
          onChange={(e) => onImageSelect(e)}
          hidden
        />
      </div>
      <form onSubmit={handleSubmit(handleFormUpdate)}>
        <div className="input-wrapper">
          <div className="input-box">
            <label htmlFor="">Name</label>
            <input
              className={`${!enableEdit && "disabled"}`}
              type="text"
              {...register("name", {
                required: "Name is required",
                pattern: {
                  value: /^[a-zA-Z0-9_\s-]+$/,
                  message: "Special characters are not allowed",
                },
              })}
              disabled={!enableEdit}
            />
            <small className="error">{errors?.name?.message}</small>
          </div>
          <div className="input-box">
            <label htmlFor="">Email</label>
            <input
              className={`${!enableEdit && "disabled"}`}
              type="email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                  message: "Enter a valid email",
                },
              })}
              disabled={!enableEdit}
            />
            <small className="error">{errors?.email?.message}</small>
          </div>

          <div className="input-box">
            <label htmlFor="">Mobile Number</label>
            <input
              className={`${!enableEdit && "disabled"}`}
              type="tel"
              {...register("phoneNumber", {
                required: "Phone number is required",
                pattern: {
                  value: /^[6-9]\d{9}$/i,
                  message: "Enter a valid phone number",
                },
              })}
              disabled={!enableEdit}
            />
            <small className="error">{errors?.phoneNumber?.message}</small>
          </div>
        </div>
        <div className="btn-wrapper">
          {!enableEdit && (
            <button
              type="button"
              className="btn-primary"
              onClick={enableEditOption}
            >
              Edit
            </button>
          )}

          <div className="buttons">
            {enableEdit && (
              <button
                type="button"
                className="btn-secondary"
                onClick={cancelEditProfile}
              >
                Cancel
              </button>
            )}
            {enableEdit && (
              <button type="submit" className="btn-primary">
                Save
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditProfile;
