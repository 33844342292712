import "./Footer.scss";
import homeImg3 from "../../assets/images/home-img-3.png";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
  const navigateTo = useNavigate()
  return (
    <div className="footer-container">
      <div className="row-1">
        <div className="left-column">
          <span>
            Explore the beauty of art through our diverse collection of
            artworks.
          </span>
          <button onClick={() => navigateTo('/shop')}>Explore the Gallery</button>
        </div>
        <div className="above-footer">
          <div className="pointer">
            <span>About</span>
            <div className="line"></div>
            <div className="nav-link">
              <span onClick={() => navigateTo('/about-us')}>About Us</span>
              <span onClick={() => navigateTo('/contact-us')}>Contact</span>
              <span onClick={() => navigateTo('/privacy-policy')}>Privacy Policy</span>
              <span onClick={() => navigateTo('/terms-of-use')}>Terms of Use</span>
            </div>
          </div>
          <div>
            <span>Contact</span>
            <div className="line"></div>
            <div className="nav-link">
              <span>Tart Art@example.com</span>
              <span>(603) 555-0123</span>
              <span>
                8502 Preston Rd. <br /> Inglewood, Maine <br /> 98380
              </span>
            </div>
          </div>
          <div>
            <span>Social Media</span>
            <div className="line"></div>
            <div className="nav-link pointer">
              <span>INSTAGRAM</span>
              <span>FACEBOOK</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row-2">
        <div className="footer-img">
          <img src={homeImg3} alt="" />
        </div>
        <span>© 2023 Tart Art</span>
      </div>
    </div>
  );
};
