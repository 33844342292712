import React, { useState } from "react";

import { useForm } from "react-hook-form";
import "./ViewOrderDetail.scss";
import { domainName } from "../../../../../../../Constants";

const ViewOrderDetail = ({ setActiveTab, setPage, order }) => {
  const [actveStep, setActiveStep] = useState(0);
  function formatDate(inputDate) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const dateObj = new Date(inputDate);

    const formattedDate = dateObj.toLocaleDateString("en-US", options);

    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";
    const formattedTime = `${hours % 12}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;

    return `${formattedDate}, ${formattedTime}`;
  }

  const date = formatDate(order?.create_at);
  const updatedDate = formatDate(order?.update_at);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
  };
  const onCancel = () => {
    setPage("ArtWorkList");
    setActiveTab("");
  };
  return (
    <>
      <div className="order-detail-container">
        <div className="title">Add Shipping details</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-container">
            <div className="input-wrapper">
              <label>Shipping Option Name</label>
              <input
                type="text"
                placeholder="Local delivery"
                {...register("shippingOption", {
                  required: "Shipping Option is required.",
                  maxLength: {
                    value: 50,
                    message:
                      "Shipping Option should be less then 50 characters.",
                  },
                })}
              />
              {errors.shippingOption && (
                <div className="error">{errors.shippingOption.message}</div>
              )}
            </div>
            <div className="input-wrapper">
              <label>Shipping Time Estimate</label>
              <input
                type="number"
                placeholder="1-3 business days"
                {...register("shippingTime", {
                  required: "Shipping Time is required.",
                  min: {
                    value: 1,
                    message: "Shipping Time must be greter then 1",
                  },
                })}
              />
              {errors.shippingTime && (
                <div className="error">{errors.shippingTime.message}</div>
              )}
            </div>
            <div className="input-wrapper">
              <label>Shipping Fee</label>
              <div className="input-inner-wrapper">
                <input
                  type="number"
                  placeholder="$10.00"
                  {...register("shippingFee", {
                    required: "Shipping Fee is required.",
                    min: {
                      value: 1,
                      message: "Shipping Fee must be greter then 1",
                    },
                  })}
                />
                <span>$</span>
              </div>
              {errors.shippingFee && (
                <div className="error">{errors.shippingFee.message}</div>
              )}
            </div>

            <div className="btn-wrapper">
              <div
                className="cancel-btn btn-secondary"
                onClick={() => onCancel()}
              >
                Clear all
              </div>
              <button className="btn-primary" onClick={() => setActiveStep(3)}>
                Send
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="order-detail">
        <div className="order-header-offcanvas">
          <span className="h5">order summary</span>
        </div>
        <span className="fw-500 order-id">Order ID : #{order.id}</span>
        <span className="title">Order Placed : {date}</span>
        <span className="title">Shipping address</span>
        <div className="address">
          <span>{order?.name}</span>
          <span style={{ textTransform: "uppercase" }}>{order?.address}</span>
          <span>{order?.state}</span>
        </div>
        <div className="deliver-date">Order delivered: {updatedDate}</div>
        {order.product.map((item) => (
          <div className="product-details">
            <img
              src={`${domainName}/uploads/${item.productDetail.main_image}`}
              alt="product"
            />
            <div>
              <span className="name">
                {item.productDetail.title} <br />{" "}
                <span>{item.productDetail.category__title}</span>
              </span>
              <span className="quantity">Qyt:{item.quantity}</span>
              <span className="price">
                ₹{item.productDetail.price * item.quantity}
              </span>
            </div>
          </div>
        ))}
        <div className="order-summary">
          <span className="h5">Order Summary</span>
          <div className="underline-cancel-order-header"></div>
          <div className="flex-between sub-total">
            <span>Subtotal</span>
            <span>₹{order.total_amount}</span>
          </div>
          <div className="flex-between">
            <span>Shipping</span>
            <span>-----</span>
          </div>
          <div className="underline-cancel-order-header"></div>
          <div className="flex-between total">
            <div className="h5">
              Order Total <span>Monthly payment</span>{" "}
            </div>
            <div className="h5 amount">
              ₹{order.total_amount} <span>₹6045/month with EMI</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewOrderDetail;
