import { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import "./AccordionFAQ.scss";

export const AccordionFAQ = ({ title, content,setFaqOpenContent,faqOpenContent }) => {
  const [open, setOpen] = useState(false);
  useEffect(()=>{
    console.log("SDFdsfdsf",faqOpenContent)
    if(faqOpenContent!== title){
      setOpen(false)
    }
  },[faqOpenContent])


  const setContent = ()=>{

    if(open){
      setOpen(false)
      setFaqOpenContent("")
    }
    else{
      setFaqOpenContent(title)
      setOpen(true)

    }

  }
  return (
    <div className="accordion-faq-box">
      <div
        className="question"
        aria-controls="accordion-description"
        aria-expanded={open}
        onClick={() => setContent()}
      >
        <span>{title}</span>
        {open ? (
          <AiFillCaretUp className="icon" />
        ) : (
          <AiFillCaretDown className="icon" />
        )}
      </div>
      <Collapse in={open}>
        <div id="accordion-description">{content}</div>
      </Collapse>
    </div>
  );
};
