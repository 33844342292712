import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  addSellerProduct,
  uploadSellerProductImages,
  editSellerProduct,
} from "../../../../../../../../../apiCall";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Bio = ({ setActiveStep,setActiveTab, onCancel, myData, isEdit }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const navigateTo = useNavigate();
  useEffect(() => {
    reset({ owner: myData.owner, bio: myData.bio });
  }, [myData]);
  const onSubmit = async (data) => {
    console.log(data);
    console.log(myData);
    const final_data = { ...myData, owner: data.owner, bio: data.bio };

    let Re = undefined;
    if (isEdit) {
      Re = await editSellerProduct(final_data, myData.id);
    } else {
      Re = await addSellerProduct(final_data);
    }
    console.log(Re);
    if (Re?.status === 201 ||Re?.status === 200) {
      if (myData?.images.length > 0) {
        let filesData = new FormData();
        myData?.images?.forEach((file, index) => {
          filesData.append(`files`, file); // Append each file with a unique key
        });

        if (myData?.images?.length === 0) {
          filesData = [];
          if (isEdit) {
            toast.success("Product updated.");
          } else {
            toast.success("Product added.");
          }
          return;
        }

        const ImageRe = await uploadSellerProductImages(
          filesData,
          Re.data.value
        );

        console.log("Image upload response", ImageRe);

        if (ImageRe.data?.status[0].Error === "False") {
          if (isEdit) {
            toast.success("Product updated.");
          } else {
            toast.success("Product added.");
          }
        } else {
          if (ImageRe.data?.status) {
            toast.error(ImageRe.data.status[0].ResponseMessage);
          } else {
            toast.error("Something went wrong, Please try again.");
          }
        }
      }
      setActiveTab("");
    } else {
      // if (Re?.response.status === 409) {
      toast.error(Re?.response?.data?.status[0].ResponseMessage);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="input-container">
        <div className="input-wrapper">
          <label>Artist Name</label>
          <input
            type="text"
            placeholder="Enter Artist Name."
            {...register("owner", {
              required: "Artist Name is required.",
              maxLength: {
                value: 50,
                message: "Artist Name should be less then 50 characters.",
              },
            })}
          />
          {errors.owner && <div className="error">{errors.owner.message}</div>}
        </div>

        <div className="input-wrapper">
          <label>Bio</label>
          <textarea
            rows={10}
            type="text"
            placeholder="Welcome to a world of creativity! Explore my unique collection of paintings and digital art."
            {...register("bio", {
              required: "Bio is required.",
              maxLength: {
                value: 500,
                message: "Bio should be less then 500 characters.",
              },
            })}
          />
          {errors.bio && <div className="error">{errors.bio.message}</div>}
        </div>
        <div className="btn-wrapper">
          <div className="cancel-btn btn-secondary" onClick={() => onCancel()}>
            Cancel
          </div>
          <button className="btn-primary" type="submit">
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default Bio;
