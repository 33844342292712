import { useState } from "react";
import "./Address.scss";
import { useForm } from "react-hook-form";
import { deleteSellerAddress, updateSellerAddress } from "../../../../../apiCall";
import { toast } from "react-hot-toast";
import { useStateValue } from "../../../../../StateProvider";
import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { MdOutlineCheckBox } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import AddAddressModal from "../../../../../components/modals/UpdateAddressModal/UpdateAddressModal";
import UpdateAddressModal from "../../../../../components/modals/UpdateAddressModal/UpdateAddressModal";

export const Address = ({
  address,
  selectedAddress,
  selectAddress,
}) => {
  const [show, setShowModal] = useState(false);
  const [, dispatch] = useStateValue();
  const { pathname } = useLocation();

  const handleEditOpen = () => {
    setShowModal(true);
  };



  // delete address
  const doDelete = async () => {
    try {
      const response = await deleteSellerAddress(address.id);
      if (response.data?.status[0]?.Error === "False") {
        toast.success(response.data?.status[0]?.ResponseMessage);
        dispatch({ type: "DELETE_USER_ADDRESS", id: address.id });
      } else {
        toast.error(response.data?.status[0].ResponseMessage);
      }
    } catch (err) {
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  };


  return (
    <div className="checkout-address-div">
      <div className="address-side">
        <>
          
            <div className="delivery-info">
              {/* {selectedAddress.id === address.id ? (
                <MdOutlineCheckBox size={20} />
              ) : (
                <MdCheckBoxOutlineBlank
                  size={20}
                  onClick={() => selectAddress(address)}
                />
              )} */}
              <div
                className="details"
                onClick={() =>
                  pathname !== "/dashboard/shipping" && selectAddress(address)
                }
              >
                <h1>{address.name}</h1>
                <p className="uppercase">
                  {address.city}, {address.pin_code} {address.state}
                </p>
                {/* <p>{address.email}</p>
                <p>{address.phone_number}</p> */}
              </div>
              <div className="address-options">
                <span onClick={handleEditOpen}>Edit</span>
                <span onClick={doDelete}>Delete</span>
              </div>
            </div>
          
        </>
      </div>
      <UpdateAddressModal
        address={address}
        show={show}
        setShowModal={setShowModal}
        onHide={() => setShowModal(false)}
      />
    </div>
  );
};
