import React, { useRef, useState } from "react";
import "./OrdersList.scss";
import { HiMiniMagnifyingGlass } from "react-icons/hi2";
import productImg from "../../../../../../../assets/arts/art (2).png";
import { getSellerOrders, getSelleruserOrders, orderStatus } from "../../../../../../../apiCall";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import { domainName } from "../../../../../../../Constants";
const OrdersList = ({ setActiveTab, setPage, setViewOrder }) => {
  const filterOption = [
    "Confirmed",
    "Shipped",
    "Delivered",
    "Canceled",
    "Returned",
    "All",
  ];
  const [orderDetailShowModal, setOrderDetailShowModal] = useState(false);
  const [activeFilter, setActiveFilter] = useState("all");
  const [orderDetailData, setOrderDetailData] = useState({});
  const [statusFilter, setStatusFilter] = useState("All");
  const searchInputRef = useRef();
  const { data: order } = useQuery({
    queryKey: ["Orders", statusFilter],
    queryFn: () => getSelleruserOrders(statusFilter),
    onSuccess: (data) => {
      console.log(data);
      if (data.status === 200) {
        setOrders(data.data?.value);
        setFilteredItems(data?.data?.value);
      } else {
        toast.error("something went wrong there. please login again.");
      }
    },
    onError: (e) => {
      if (e.message) {
        return toast.error(e.message);
      }
      toast.error("Something went wrong");
    },
  });
  const [orders, setOrders] = useState(
    order?.status === 200 ? order?.data?.value : []
  );
  const [filteredItems, setFilteredItems] = useState(orders);

  const handleSearch = (input) => {
    const newfilteredItems = orders.filter((item) =>
      item.name.toLowerCase().includes(input.toLowerCase())
    );
    setFilteredItems(newfilteredItems);
  };

  const handelOrderStatus = async (data) => {
    const re = await orderStatus(data);
    console.log(re);
    if (re?.status === 200) {
      toast.success("Order status changed successfully.");
    } else {
      // if (Re?.response.status === 409) {
      toast.error(re?.response.data.status[0].ResponseMessage);
    }
  };

  return (
    <div className="order-list">
      <div className="search-bar-wrapper">
        <div className="category">
          <select name="" id="">
            <option value="">Paintings</option>
            <option value="">Paintings</option>
            <option value="">Paintings</option>
          </select>
        </div>
        <div className="search-bar">
          <div className="search-bar-inner-wrapper">
            <input
              type="text"
              name=""
              placeholder="Search"
              ref={searchInputRef}
            />
            <HiMiniMagnifyingGlass size={20} />
          </div>
          <div className="btn-wrapper">
            <button
              className="btn-primary"
              onClick={() => handleSearch(searchInputRef.current.value)}
            >
              Search
            </button>
          </div>
        </div>
      </div>

      <div className="filter">
        {filterOption.map((option, index) => (
          <>
            {statusFilter === option ? (
              <button
                className="btn-primary"
                onClick={() => setStatusFilter(option)}
              >
                {option}
              </button>
            ) : (
              <button
                className="btn-secondary"
                onClick={() => setStatusFilter(option)}
              >
                {option}
              </button>
            )}
          </>
        ))}
      </div>
      <div className="list">
        <div className="list-header">
          <div className="title">Orders</div>
          {/* <button
            className="btn-secondary"
            onClick={() => {
              setActiveTab("addArtWork");
              setPage("addNewArt");
            }}
          >
            Add new artworks
          </button> */}
        </div>
        <div className="table-container">
          {filteredItems.length > 0 ? (
            <table>
              <thead>
                <th>Artwork Title </th>
                <th>Price</th>
                <th>Stock</th>
                <th>Earrings</th>
                <th>Shipping</th>
                <th>Status</th>
                <th>Action</th>
              </thead>
              <tbody>
                <>
                  {filteredItems.map((order, index) => (
                    <tr>
                      <td>
                        <div className="data-wrapper">
                          {order.product.map((product) => (
                            <div
                              className="product-detail-wrapper"
                              id={`recent-order-${index}`}
                            >
                              <img
                                src={`${domainName}/uploads/${product.productDetail.main_image}`}
                                alt=""
                              />
                              <div className="product-detail">
                                <div className="name">
                                  {product.productDetail.title}
                                </div>
                                <div className="category">
                                  {product.productDetail.category__title}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </td>
                      <td>
                        <div className="data-wrapper">
                          {order.product.map((product) => (
                            <span>₹{product.productDetail.price}</span>
                          ))}
                        </div>
                      </td>
                      <td>
                        <div className="data-wrapper">
                          {order.product.map((product) => (
                            <span>{product.quantity}</span>
                          ))}
                        </div>
                      </td>
                      <td>₹{order.total_amount}</td>
                      <td>
                        <div className="shipping-type">Local delivery</div>
                      </td>
                      <td>
                        <select
                          defaultValue={order.status}
                          onChange={(e) =>
                            handelOrderStatus({
                              orderId: order.id.toString(),
                              status: e.target.value,
                            })
                          }
                        >
                          <option value="Received" disabled>
                            Received
                          </option>
                          {filterOption.map((option, index) => (
                            <option value={option}>{option}</option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <div className="action-wrapper">
                          <span
                            onClick={() => {
                              setViewOrder(order);
                              setActiveTab("viewOrderDetail");
                            }}
                          >
                            View Details
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              </tbody>
            </table>
          ) : (
            "You don't have any orders."
          )}
        </div>
      </div>
    </div>
  );
};

export default OrdersList;
