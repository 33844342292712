import React, { useEffect, useRef, useState } from "react";
import "./AddAddressModal.scss";
import { Modal } from "react-bootstrap";
import { VerifyOTP, addAddress, addSellerAddress, updateAddress } from "../../../apiCall";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useStateValue } from "../../../StateProvider";
import axios from "axios";
import { IoClose } from "react-icons/io5";
import { ValidateNumberLength } from "../../../utils/ValidateNumberLength";

function AddAddressModal({ address, setShowModal, ...props }) {
  const [editOpen, setEditOpen] = useState(false);
  const [, dispatch] = useStateValue();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [pincode, setPincode] = useState();
  const [pincodeErr, setPincodeErr] = useState("");
  const [cityErr, setCityErr] = useState("");
  const [stateErr, setStateErr] = useState("");
  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const {storename} = useParams()
  console.log(storename)
  const checkPincode = async (e) => {
    if (e.target.value.length === 0) {
      return setPincodeErr("Pincode is required");
    } else {
      setPincodeErr("");
    }
    if (!/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/.test(e.target.value)) {
      setCity("");
      setState("");
      return setPincodeErr("Pincode is invalid");
    } else {
      setPincodeErr("");
      try {
        const response = await axios.get(
          `https://api.postalpincode.in/pincode/${e.target.value}`
        );
        if (response.data[0].Status === "Success") {
          console.log(response)
          setPincode(e.target.value);
          setCity(response.data[0].PostOffice[0].District);
          setState(response.data[0].PostOffice[0].State);
          setPincodeErr("");
          setCityErr("");
          setStateErr("");
        } else {
          setCity("");
          setState("");
          setPincodeErr("Pincode is invalid");
        }
      } catch (err) {}
    }
  };
  const handleCityChange = (e) => {
    setCity(e.target.value);
    if (!e.target.value.length) {
      return setCityErr("City is required");
    }
    setCityErr("");
  };

  const handleStateChange = (e) => {
    setState(e.target.value);
    if (!e.target.value.length) {
      return setStateErr("State is required");
    }
    setStateErr("");
  };

  const checkRequiredFields = () => {
    if (!pincode) {
      setPincodeErr("Pincode is required");
    }
    if (!state) {
      setStateErr("State is required");
    }
    if (!city) {
      setCityErr("City is required");
    }
  };
  const addAddressMutation = useMutation(addAddress, {
    onSuccess: (data) => {
      if (data.data?.status[0]?.Error === "False") {
        toast.success("Added address");
        console.log("add address response", data.data);
        queryClient.invalidateQueries(["addresses"]);
        setShowModal(false);
        reset();
        setCity("");
        setState("");
        setPincode("");
      }
    },
    onError: (err) => {
      console.log(err, "error");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error("Something went wrong");
      }
    },
  });
  const addSellerAddressMutation = useMutation(addSellerAddress, {
    onSuccess: (data) => {
      if (data.data?.status[0]?.Error === "False") {
        toast.success("Added address");
        console.log("add address response", data.data);
        queryClient.invalidateQueries(["addresses"]);
        setShowModal(false);
        reset();
        setCity("");
        setState("");
        setPincode("");
      }
    },
    onError: (err) => {
      console.log(err, "error");
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error("Something went wrong");
      }
    },
  });

  // add address
  const handleFormSubmit = (values) => {
    if (!pincode || !city || !state) {
      console.log("hello");
      if (!pincode) {
        setPincodeErr("Pincode is required");
      }
      if (!state) {
        setStateErr("State is required");
      }
      if (!city) {
        setCityErr("City is required");
      }
    } else {
      values = { ...values, city: city, state: state, pin_code: pincode };
      if(storename){
        addSellerAddressMutation.mutate(values)
      }else{
        addAddressMutation.mutate(values);
      }
    }
  };
  return (
    <Modal
      {...props}
      size=""
      aria-labelledby="contained-modal-title-vcenter"
      className="modal add-address-modal"
      centered
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="header">
          <h4>Add Address</h4>
          <IoClose size={20} onClick={() => setShowModal(false)} />
        </div>
        <div className="inputs">
          <div className="row">
            <div className="input-box">
              <label htmlFor="">Name</label>
              <input
                type="text"
                defaultValue={getValues("name")}
                {...register("name", {
                  required: "Name is required",
                  pattern: {
                    value: /^[a-zA-Z0-9_\s-]+$/,
                    message: "Special characters are not allowed",
                  },
                })}
              />
              <small className="error">{errors.name?.message}</small>
            </div>
          </div>
          <div className="row">
            <div className="input-box">
              <label htmlFor="">Mobile Number</label>
              <input
                type="number"
                defaultValue={getValues("phone_number")}
                {...register("phone_number", {
                  required: "Phone number is required",
                  pattern: {
                    value: /^[6-9]\d{9}$/i,
                    message: "Enter a valid phone number",
                  },
                })}
              onChange={(e)=>ValidateNumberLength("phone_number",e,setValue)}

              />
              <small className="error">{errors.phone_number?.message}</small>
            </div>
            <div className="input-box">
              <label htmlFor="">Email</label>
              <input
                type="email"
                defaultValue={getValues("email")}
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                    message: "Enter a valid email",
                  },
                })}
              />
              <small className="error">{errors.email?.message}</small>
            </div>
          </div>
          <div className="row">
          <div className="input-box">
              <label htmlFor="">Pincode</label>
              <input
                type="tel"
                defaultValue={pincode}
                onChange={checkPincode}
              />
              <small className="error">{pincodeErr}</small>
            </div>
            <div className="input-box">
              <label htmlFor="">City</label>
              <input type="text" value={city} onChange={handleCityChange} />
              <small className="error">{cityErr}</small>
            </div>
            <div className="input-box">
              <label htmlFor="">State</label>
              <input type="text" value={state} onChange={handleStateChange} />
              <small className="error">{stateErr}</small>
            </div>
            
          </div>
          <div className="row">
            <div className="input-box">
              <label htmlFor="">Address</label>
              <input
                type="text"
                defaultValue={getValues("address")}
                {...register("address", {
                  required: "Address is required",
                  minLength: {
                    value: 10,
                    message: "Minimum 10 characters required",
                  },
                })}
              />
              <small className="error">{errors.address?.message}</small>
            </div>
          </div>
        </div>
        <div className="buttons">
          <button
            type="submit"
            className="btn-primary"
            onClick={checkRequiredFields}
          >
            Save
          </button>
          {/* <button className="btn-secondary" onClick={cancelAddAddressModal}>
            cancel
          </button> */}
        </div>
      </form>
    </Modal>
  );
}

export default AddAddressModal;
