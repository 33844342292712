import React, { useRef, useState } from "react";
import "../../SellerSignup.scss";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useStateValue } from "../../../../../StateProvider";
import { checkStoreNameAvailable, sellerStore } from "../../../../../apiCall";
import { useNavigate } from "react-router-dom";

const SetupStore = ({ setActiveTab }) => {
  const [{ userLoggedIn, userDetail }, dispatch] = useStateValue();
const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const fileInputRef = useRef();
  console.log(userDetail);

  const [imagePreview, setImagePreview] = useState(null);
  const [imageError, setImageError] = useState(false);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setImageError(false);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleClick = () => {
    console.log("asdasd");
    fileInputRef.current.click();
  };
  const onSubmit = async (data) => {
    if (imagePreview) {
      try {
        const formData = new FormData();
        formData.append("storeLogo", fileInputRef.current.files[0]);

        // Make POST request to your FastAPI endpoint
        const response = await sellerStore(formData, data);

        if (response?.status === 200) {
          console.log(response.data.value);
          toast.success("Seller store detail added successfully.");
          console.log(userDetail);
          userDetail.is_store_detail_added = true;
          console.log(userDetail);
          dispatch({ type: "SET_USER_DETAIL", detail: userDetail });
          navigate("/seller/dashboard/home")
        } else {
          // if (Re?.response.status === 409) {
          toast.error(response?.response.data.status[0].ResponseMessage);
        }
      } catch (error) {
        console.error("Error uploading PAN card:", error);
      }
      setImageError(false);
    } else {
      setImageError(true);
    }
  };
  const validatestoreName = async (value) => {
    const response = await checkStoreNameAvailable(value);
    if (response?.status === 200) {
      return true; // For example, checking if value is 'hello'
    }
    return false; // For example, checking if value is 'hello'
  };
  return (
    <div className="seller-register">
      <div className="seller-form-wrapper" data-aos="fade-down">
        <div className="title">Register for Seller</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-container">
            <div className="upload-logo">
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{ width: "200px", height: "200px" }}
                  className="preview-image"
                  onClick={handleClick}
                />
              ) : (
                <div className="upload-file-button" onClick={handleClick}>
                  <span>+</span>
                  <span>Add Logo</span>
                </div>
              )}

              <input
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
                accept="image/*"
                onChange={handleFileChange}
              />
              {imageError && <div className="error">Logo is required.</div>}
            </div>
            <div className="input-wrapper">
              <label>Enter name of your Store</label>
              <input
                type="text"
                placeholder="Enter Your Store Name."
                {...register("name", {
                  required: "Name is required.",
                  validate: {
                    customValidation: (value) =>
                      validatestoreName(value) || "Store name already in use.",
                  },
                  maxLength: {
                    value: 20,
                    message: "Name should be less then 20 characters.",
                  },
                  onChange: (e) => {
                    setValue("storeLink", `www.tart.aapkacfo.com/seller/${e.target.value}`);
                  },
                })}
              />
              {console.log(errors)}
              {errors.name && (
                <div className="error">{errors.name.message}</div>
              )}
              {errors.name?.type === "customValidation" && (
                <div className="error">Store name already in use.</div>
              )}
            </div>
            <div className="input-wrapper">
              <label>Store Link preview</label>
              <input
                type="text"
                placeholder="www.tart.aapkacfo.com/seller/John'sArtHaven."
                {...register("storeLink", {
                  required: "Store Link is required.",
                })}
                disabled
              />
              {errors.storeLink && (
                <div className="error">{errors.storeLink.message}</div>
              )}
            </div>
            <div className="input-wrapper">
              <label>Description</label>
              <textarea
                rows={2}
                type="text"
                placeholder="Welcome to a world of creativity! Explore my unique collection of paintings and digital art."
                {...register("description", {
                  required: "Description is required.",
                  maxLength: {
                    value: 500,
                    message: "Description should be less then 500 characters.",
                  },
                })}
              />
              {errors.description && (
                <div className="error">{errors.description.message}</div>
              )}
            </div>
            <div className="input-wrapper">
              <label>Business Address (if applicable)</label>
              <input
                type="text"
                placeholder="Enter Your address."
                {...register("address", {
                  maxLength: {
                    value: 100,
                    message: "Address should be less then 100 characters.",
                  },
                })}
              />
              {errors.address && (
                <div className="error">{errors.address.message}</div>
              )}
            </div>

            <button className="btn-primary">Setup Store</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SetupStore;
