import React, { useState } from "react";

import { useForm } from "react-hook-form";
import "./OrderCancellationRequest.scss";
import { domainName } from "../../../../../../../Constants";
import { OrderDetailModal } from "../../../../../../../components/modals/OrderDetailModal/OrderDetailModal";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import {
  getReturnRequest,
  returnOrderRequest,
} from "../../../../../../../apiCall";
function formatDate(inputDate) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const dateObj = new Date(inputDate);

  const formattedDate = dateObj.toLocaleDateString("en-US", options);

  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const period = hours >= 12 ? "PM" : "AM";
  const formattedTime = `${hours % 12}:${minutes
    .toString()
    .padStart(2, "0")} ${period}`;

  return `${formattedDate}, ${formattedTime}`;
}
const OrderCancellationRequest = ({ setActiveTab, setPage }) => {
  const [cancelRequests, setCancelRequests] = useState([]);
  const [cancelRequestData, setcancelRequestData] = useState({});

  const { isLoading: isCancellRequestLoading, refetch } = useQuery({
    queryKey: ["returnRequestAll"],
    queryFn: () => getReturnRequest("all"),
    onSuccess: (data) => {
      console.log(data);
      if (data.status === 200) {
        setCancelRequests(data.data?.value);
      } else {
        toast.error("something went wrong there. please login again.");
      }
    },
    onError: (e) => {
      if (e.message) {
        return toast.error(e.message);
      }
      toast.error("Something went wrong");
    },
  });
  const handelRequestAction = async (data) => {
    const re = await returnOrderRequest(data);
    console.log(re);
    if (re?.status === 200) {
      toast.success("request updated.");
      refetch();
    } else {
      // if (Re?.response.status === 409) {
      toast.error(re?.response.data.status[0].ResponseMessage);
    }
  };
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="order-cancellation-request card-container">
        <div className="title">
          <span>Requests</span>
          <span className="view-all" onClick={()=>{setActiveTab("");setPage("Dashboard")}} >Back To Dashboard</span>
        </div>
        <div className="request-list">
          {cancelRequests.length > 0 ? (
            <>
              {cancelRequests.map((order) => (
                <div className="request-wrapper">
                  <span className="title">Request #{order.return_id}</span>
                  <span>
                    Order Number: <strong>{order.order_id}</strong>{" "}
                  </span>
                  <span>
                    Date:{" "}
                    <strong>{formatDate(order.order_details.create_at)}</strong>
                  </span>
                  <span>
                    Total Amount:{" "}
                    <strong>{order.order_details.total_amount}</strong>
                  </span>
                  <span>
                    Reason: <strong>{order.reason}</strong>
                  </span>

                  <span
                    className="view-detail"
                    onClick={() => {
                      setcancelRequestData(order);
                      setShowModal(true);
                    }}
                  >
                    {" "}
                    View Details
                  </span>
                  <div className="action-btn">
                    <button
                      className="btn-secondary"
                      onClick={() =>
                        handelRequestAction({
                          returnRequestId: order.return_id,
                          status: "Rejected",
                        })
                      }
                    >
                      Reject
                    </button>
                    <button
                      className="btn-primary"
                      onClick={() =>
                        handelRequestAction({
                          returnRequestId: order.return_id,
                          status: "Initiated",
                        })
                      }
                    >
                      Accept
                    </button>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <span>You don't have any requests.</span>
          )}
        </div>
      </div>
      <OrderDetailModal
        order={cancelRequestData}
        handelRequestAction={handelRequestAction}
        show={showModal}
        orderCancellationDeatil={{ id: 1, reason: "Change of Mind" }}
        onHide={() => setShowModal(false)}
      />
    </>
  );
};

export default OrderCancellationRequest;
