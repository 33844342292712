import React from "react";
import "./CategoryModal.scss";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { useStateValue } from "../../../StateProvider";
import { contact, removeSellerCategories } from "../../../apiCall";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
const DeleteCategoryModal = (props) => {
  const [, dispatch] = useStateValue();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const onSubmit = async () => {
    dispatch({ type: "SET_LOADING", status: true });
    try {
      const response = await removeSellerCategories(
        props.editDeteleCategory.id
      );
      if (response.data.status[0].Error === "False") {
        toast.success("Category Removed successfully");
        props.refetch();
        props.onHide();
      } else {
        toast(response.data.status[0].ResponseMessage, { icon: "⚠️" });
      }
    } catch (err) {
      toast.error(err.message);
    }
    dispatch({ type: "SET_LOADING", status: false });
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="category-modals"
    >
      <Modal.Body className="category-modal">
        <div className="close">
          <IoClose size={20} onClick={props.onHide} />
        </div>
        <h3>Are you sure you want to delete this Category?</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="buttons">
            <button className="btn-secondary">Yes</button>
            <div
           
              className="btn-primary"
            >
              No
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteCategoryModal;
