import React, { useEffect, useState } from "react";
import { ReactComponent as status1 } from "../../../../../assets/svgs/Property 1=2.svg";
import { ReactComponent as status2 } from "../../../../../assets/svgs/Property 1=Default.svg";
import { ReactComponent as status3 } from "../../../../../assets/svgs/Property 1=3.svg";
import ArtWorkList from "./components/ArtWorkList/ArtWorkList";
import AddUpdateArtWork from "./components/AddUpdateArtWork/AddUpdateArtWork";
import { useStateValue } from "../../../../../StateProvider";

const ManageArtWork = ({ setPage }) => {
  const[{userDetail}] = useStateValue()
  const [activateTab, setActiveTab] = useState("");
  const [editProduct, setEditProduct] = useState()

  switch (activateTab) {
    case "addArtWork":
      return (
        <AddUpdateArtWork
          setActiveTab={setActiveTab}
          setPage={setPage}
          formType={"addArtWork"}
          editProduct={null}
        />
      );
    case "updateArtWork":
      return (
        <AddUpdateArtWork
          setActiveTab={setActiveTab}
          setPage={setPage}
          formType={"updateArtWork"}
          editProduct={editProduct}
        />
      );
    default:
      return <ArtWorkList setActiveTab={setActiveTab} setPage={setPage} setEditProduct={setEditProduct}/>;
  }
};

export default ManageArtWork;
