import React from 'react';
import { domainName } from '../../../../../../Constants';
import { useNavigate } from 'react-router-dom';
import "./ProductDetails.scss";
import { useStateValue } from '../../../../../../StateProvider';

const ProductDetails = ({
    product,
    setShowReviewModal,
    setReviewingProductId,
    order,
    handleCancelClick,
    handleReturnClick,
    setShowInvoiceModal
  }) => {
    console.log(order,'order')
    const navigate = useNavigate();    
  
    return (
      <div className="art-details" key={product.productDetail.id}>
        <img
          src={`${domainName}/uploads/${product.productDetail.main_image}`}
          alt="art"
          onClick={() => navigate(`/product-info/${product.productDetail.id}`)}
        />
        <div className="details">
          <div className="info">
            <span>{product.productDetail.title}</span>
            <span>{product.productDetail.owner}</span>
            <span>Categories : {product.productDetail.category__title}{product?.productDetail?.weight?` | Weight : ${product?.productDetail?.weight/1000} kg `:""}{product?.productDetail?.width?`| Dimensions : ${product?.productDetail?.width} x ${product?.productDetail?.height} x ${product?.productDetail?.length} cm`:""} </span>
            <span>Quantity : {product.quantity}</span>
            <span>₹{((product.productDetail.price*product.quantity)).toLocaleString("en-IN")}</span>
          </div>
          <div className="buttons">
            {order?.status === "Completed" && (
              <button
                className="btn-secondary button"
                onClick={() => {
                  setReviewingProductId(product.productDetail.id);
                  setShowReviewModal(true);
                }}
              >
                Write product review
              </button>
            )}
            {order?.status === "Received" || order?.status === "Packed" ? (
              <button className="btn-secondary button" onClick={handleCancelClick}>
                Cancel Order
              </button>
            ) : null}
            {order?.status === "Completed" && (
              <button
                className="btn-secondary button"
                onClick={handleReturnClick}
              >
                Return Order
              </button>
            )} 
            <button
              className="btn-secondary button"
              onClick={() => setShowInvoiceModal(true)}
            >
              Product Invoice
            </button>
          </div>
        </div>
      </div>
    );
  };
  

export default ProductDetails;