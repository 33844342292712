import React, { useEffect, useState } from "react";

import "./privacyPolicy.scss";
import { GetPrivacyPolicy } from "../../apiCall";

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const [content, setContent] = useState();

    useEffect(() => {
        const getData = async () => {
            const re = await GetPrivacyPolicy()

            if(re?.data?.status[0]?.Error === "False"){
                setContent(re?.data?.value)
            }
        }

        getData()
        window.scrollTo({ top: 0, behavior: "instant" });
    }, []);
    return (
        <div className='privacy-policy-main'>
            <h3>Privacy Policy</h3>
            <hr />
            {content && <div dangerouslySetInnerHTML={{ __html: content }}></div>}
        </div>
    );
};

export default PrivacyPolicy;