import React from 'react';
import "./OrderSuccessModal.scss"
import { Modal } from 'react-bootstrap';
import OrderSuccessImg from "../../../assets/images/green-bg-success.png";


const OrderSuccessModal = (props) => {
    return (
        <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="checkout-modal">
          <img src={OrderSuccessImg} alt="" />
          <h4>Order placed, thank you!</h4>
          <p>Confirmation will be sent to your email.</p>
          {/* <h4>Delivery date: Feb 18, 2022</h4> */}
        </Modal.Body>
      </Modal>
    );
};

export default OrderSuccessModal;