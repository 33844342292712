import React, { useState } from "react";
import "./ArtworkCategories.scss";
import AddCategoryModal from "../../../../../components/modals/AddCategoryModal/AddCategoryModal";
import UpdateCategoryModal from "../../../../../components/modals/AddCategoryModal/UpdateCategoryModal";
import DeleteCategoryModal from "../../../../../components/modals/AddCategoryModal/DeleteCategoryModal";
import { useQuery } from "@tanstack/react-query";
import { getSellerCategories } from "../../../../../apiCall";

const ArtworkCategories = () => {
  const [addCategoryModalShow, setAddCategoryModalShow] = useState(false);
  const [updateCategoryModalShow, setUpdateCategoryModalShow] = useState(false);
  const [deleteCategoryModalShow, setDeleteCategoryModalShow] = useState(false);
  const [editDeteleCategory, setEditDeteleCategory] = useState({});
  const { data: categories,refetch } = useQuery(
    ["seller-categories-list"],
    getSellerCategories,
    {
      onSuccess: (data) => {},
      onError: (e) => {
        // console.log(e)
      },
    }
  );
  return (
    <div className="card-container artwork-category">
      <div className="header">
        <div className="title">Existing Categories</div>
        <button
          className="btn-secondary"
          onClick={() => setAddCategoryModalShow(true)}
        >
          Add New Category
        </button>
      </div>
      {categories?.data?.value && categories?.data?.value.length > 0 ? (
        <table>
          <thead>
            <th className="category">Categories</th>
            <th className="action">Action</th>
          </thead>
          <tbody>
            <>
              {categories?.data?.value.map((item) => (
                <tr>
                  <td className="category">{item?.title}</td>
                  <td className="action">
                    <div className="action-wrapper">
                      <span onClick={() => {setUpdateCategoryModalShow(true);setEditDeteleCategory(item)}}>
                        Edit
                      </span>
                      <span onClick={() => {setDeleteCategoryModalShow(true);setEditDeteleCategory(item)}}>
                        Delete
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </>
          </tbody>
        </table>
      ) : (
        <h4>You don't have any category.</h4>
      )}
      <AddCategoryModal
        show={addCategoryModalShow}
        refetch={refetch}
        onHide={() => setAddCategoryModalShow(false)}
      />
      <UpdateCategoryModal
        show={updateCategoryModalShow}
        refetch={refetch}
        onHide={() => setUpdateCategoryModalShow(false)}
        editDeteleCategory={editDeteleCategory}
      />
      <DeleteCategoryModal
        show={deleteCategoryModalShow}
        refetch={refetch}
        onHide={() => setDeleteCategoryModalShow(false)}
        editDeteleCategory={editDeteleCategory}
      />
    </div>
  );
};

export default ArtworkCategories;
