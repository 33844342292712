import { useNavigate, useParams } from "react-router";
import "./Settings.scss";
import { useEffect, useState } from "react";
import OrderDetails from "./components/OrderDetails/OrderDetails";
import artImg1 from "../../assets/arts/art (2).png";
import artImg2 from "../../assets/arts/art (3).png";
import artImg3 from "../../assets/arts/art (4).png";
import artImg4 from "../../assets/arts/art (5).png";
import artImg5 from "../../assets/arts/art (7).png";
import { Shipping } from "./components/Shipping/Shipping";
import EmptyWishlistImg from "../../assets/images/empty-favorites.png";

import { useForm } from "react-hook-form";
import EditProfile from "./components/EditProfile/EditProfile";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  cancelOrder,
  getAddresses,
  getOrders,
  returnOrder,
} from "../../apiCall";
import ConfirmReturnOrderModal from "../../components/modals/ConfirmReturnOrderModal/ConfirmReturnOrderModal";
import toast from "react-hot-toast";
import CancelOrder from "./components/OrderDetails/components/CancelOrder/CancelOrder";
import ReturnOrder from "./components/OrderDetails/components/ReturnOrder/ReturnOrder";
import { useStateValue } from "../../StateProvider";
import { ProductItem } from "../../components/ProductItem/ProductItem";

const imagesArr = [
  artImg1,
  artImg2,
  artImg3,
  artImg4,
  artImg5,
  artImg1,
  artImg2,
  artImg3,
  artImg4,
  artImg5,
];

export const Settings = () => {
  const [{ userCart, cartTotal,favorites, userAddresses }, dispatch] = useStateValue();

  const params = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [currentShow, setCurrentShow] = useState("");
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [cancellingOrderId, setCancellingOrderId] = useState("");
  const [showReturnConfirm, setShowReturnConfirm] = useState(false);
  const [returningOrderId, setReturningOrderId] = useState("");
  const [cancellingReason, setCancellingReason] = useState("");
  const [returningReason, setReturningReason] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState("");

  const [orders, setOrders] = useState([]);
  // cancel order mutation function
  const cancelMutation = useMutation(cancelOrder, {
    onSuccess: (data) => {
      if (data.data?.status[0].Error === "False") {
        setShowCancelConfirm(false);
        queryClient.invalidateQueries(["all-orders"]);
        toast.success("Order cancellation successful");
        setCancellingReason("");
        setCancellingOrderId("");
      }
    },
    onError: (err) => console.log(err, "error"),
  });

  // cancel order
  const doCancelOrderConfirm = () => {
    if (!cancellingReason) {
      return toast("Select reason to cancel order", { icon: "⚠️" });
    }
    cancelMutation.mutate({
      reason: cancellingReason,
      orderId: cancellingOrderId.toString(),
    });
  };

  // return order mutation function
  const returnMutation = useMutation(returnOrder, {
    onSuccess: (data) => {
      if (data.data?.status[0].Error === "False") {
        setShowReturnConfirm(false);
        queryClient.invalidateQueries(["all-orders"]);
        toast.success("Order returned successfully");
        setReturningReason("");
        setReturningOrderId("");
      }
    },
    onError: (err) => console.log(err, "error"),
  });

  // do return order
  const doReturnOrderConfirm = () => {
    if (!returningReason) {
      return toast("Select reason to return products", { icon: "⚠️" });
    }
    returnMutation.mutate({
      reason: returningReason,
      orderId: returningOrderId.toString(),
    });
  };

  const { isLoading: ordersLoading } = useQuery(["all-orders"], getOrders, {
    onSuccess: (data) => {
      if (data.data?.status[0].Error === "False") {
        setOrders(
          data.data.value.sort((order1, order2) => order2.id - order1.id)
        );
      }
    },
    onError: (err) => console.log(err, "orders error response"),
  });
  const { isLoading: addressesIsLoading } = useQuery(
    ["addresses"],
    getAddresses,
    {
      onSuccess: (data) => {
        if (data.data?.value) {
          setAddresses(data.data.value);
          dispatch({ type: "SET_USER_ADDRESSES", addresses: data.data.value });
          sessionStorage.setItem("addresses", JSON.stringify(data.data.value));
        }
      },
      onError: (err) => console.log(err),
    }
  );
  useEffect(() => {
    setCurrentShow(params.page ? params.page.toString() : "edit-profile");
  }, [params.page]);

  return (
    <div className="main">
      {!showCancelConfirm && !showReturnConfirm && (
        <>
          <h1>Settings</h1>
          <div className="pages">
            <div
              onClick={() => {
                navigate("/settings/edit-profile");
                setCurrentShow("edit-profile");
              }}
              className={`${currentShow === "edit-profile" && "active"}`}
            >
              <span>Edit profile</span>
            </div>
            <div
              onClick={() => {
                navigate("/settings/my-orders");
                setCurrentShow("my-orders");
              }}
              className={`${currentShow === "my-orders" && "active"}`}
            >
              <span>My orders</span>
            </div>
            <div
              onClick={() => {
                navigate("/settings/favorites");
                setCurrentShow("favorites");
              }}
              className={`${currentShow === "favorites" && "active"}`}
            >
              <span>Favorites</span>
            </div>
            <div
              onClick={() => {
                navigate("/settings/shipping");
                setCurrentShow("shipping");
              }}
              className={`${currentShow === "shipping" && "active"}`}
            >
              <span>Address</span>
            </div>
          </div>
          {currentShow === "edit-profile" && <EditProfile />}
          {currentShow === "my-orders" && (
            <div className="my-orders" data-aos="fade-up">
              <h2>My orders</h2>
              {orders.length > 0
                ? orders.map((order) => (
                    <OrderDetails
                      order={order}
                      setShowCancelConfirm={setShowCancelConfirm}
                      setCancellingOrderId={setCancellingOrderId}
                      setShowReturnConfirm={setShowReturnConfirm}
                      setReturningOrderId={setReturningOrderId}
                      key={order.id}
                    />
                  ))
                : !ordersLoading && (
                    <div className="empty-orders">
                      <h3 className="h3">You haven't ordered anything yet!</h3>
                      <button
                        className="btn-primary"
                        onClick={() => navigate("/shop")}
                      >
                        Shop
                      </button>
                    </div>
                  )}
            </div>
          )}
          {currentShow === "favorites" && (
           <div className="favorites" data-aos="fade-up">
              <h2>Favorites</h2>

           {favorites.length > 0 ? (
             <div className="favorites-list">
               {favorites.map((art) => {
                 return <ProductItem item={art} key={art.id} />;
               })}
             </div>
           ) : (
             <div className="empty-wishlist">
               <img
                 src={EmptyWishlistImg}
                 alt="empty-box"
                 className="empty-box-img"
               />
             </div>
           )}
         </div>
          )}
          {currentShow === "shipping" && (
            <div className="delivery-info-wrapper">
              <h1>Delivery Information</h1>
              <Shipping
                addresses={userAddresses}
                isLoading={addressesIsLoading}
                selectedAddress={selectedAddress}
                selectAddress={setSelectedAddress}
              />
            </div>
          )}
        </>
      )}
      {showCancelConfirm && (
        <CancelOrder
          orders={orders}
          cancellingOrderId={cancellingOrderId}
          setcancellingreason={setCancellingReason}
          docancelorderconfirm={doCancelOrderConfirm}
          setcancellingorderId={setCancellingOrderId}
          setShowCancelConfirm={setShowCancelConfirm}
        />
      )}
      {showReturnConfirm && (
        <ReturnOrder
          orders={orders}
          returningOrderId={returningOrderId}
          setreturningreason={setReturningReason}
          doreturnorderconfirm={doReturnOrderConfirm}
          setcancellingorderId={setCancellingOrderId}
          setShowReturnConfirm={setShowReturnConfirm}
        />
      )}

      {/* <ConfirmCancelOrderModal
        show={showCancelConfirm}
        onHide={() => setShowCancelConfirm(false)}
        setcancellingreason={setCancellingReason}
        docancelorderconfirm={doCancelOrderConfirm}
        setcancellingorderId={setCancellingOrderId}
      /> */}
      {/* <ConfirmReturnOrderModal
        show={showReturnConfirm}
        onHide={() => setShowReturnConfirm(false)}
        setreturningreason={setReturningReason}
        doreturnorderconfirm={doReturnOrderConfirm}
        setreturningorderId={setReturningOrderId}
      /> */}
    </div>
  );
};
