import React, { useRef, useState } from "react";
import "../../SellerSignup.scss";
import { useForm } from "react-hook-form";
import { sellerPanDetail } from "../../../../../apiCall";
import { useStateValue } from "../../../../../StateProvider";
import toast from "react-hot-toast";

const PanVerification = ({ setActiveTab }) => {
  const [{ userLoggedIn, userDetail }, dispatch] = useStateValue();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const fileInputRef = useRef();
  console.log(userDetail)

  const [imagePreview, setImagePreview] = useState(null);
  const [imageError, setImageError] = useState(false);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setImageError(false);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleClick = () => {
    fileInputRef.current.click();
  };
  const onSubmit = async (data) => {
    if (imagePreview) {
      try {
        const formData = new FormData();
        formData.append("panCardImage", fileInputRef.current.files[0]);

        // Make POST request to your FastAPI endpoint
        const response = await sellerPanDetail(formData, data.panCardNumber);

        if (response?.status === 200) {
          console.log(response.data.value);
          toast.success("Pancard Uploaded Successfully.")
          console.log(userDetail)
          userDetail.is_pan_card_added = true
          console.log(userDetail)
          dispatch({ type: "SET_USER_DETAIL", detail: userDetail });
          setActiveTab("setupStore");
        } else {
          // if (Re?.response.status === 409) {
          toast.error(response?.response.data.status[0].ResponseMessage);
        }
      } catch (error) {
        console.error("Error uploading PAN card:", error);
      }
      setImageError(false);
    } else {
      setImageError(true);
    }
  };
  return (
    <div className="seller-register">
      <div className="seller-form-wrapper" data-aos="fade-down">
        <div className="title">PAN Verification</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-container">
            <div className="input-wrapper">
              <label>
                Please upload a scanned copy or image of your PAN card for
                verification.
              </label>
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{ width: "200px", height: "200px" }}
                  className="preview-image"
                  onClick={handleClick}
                />
              ) : (
                <div className="upload-file-button" onClick={handleClick}>
                  Drag or upload files
                </div>
              )}

              <input
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
                accept="image/*"
                onChange={handleFileChange}
              />
              {imageError && (
                <div className="error">Pancard image is required.</div>
              )}
            </div>
            <div className="input-wrapper">
              <label>Pan Number</label>
              <input
                type="text"
                placeholder="Enter Your pan number."
                {...register("panCardNumber", {
                  required: "panCard  is required.",
                  pattern: {
                    value: /^[A-Z]{5}[0-9]{4}[A-Z]$/,
                    message: "Invalid PAN Card Number",
                  },
                })}
              />
              {errors.panCardNumber && (
                <div className="error">{errors.panCardNumber.message}</div>
              )}
            </div>
            <button
              className="btn-primary"
              // onClick={() => setActiveTab("setupStore")}
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PanVerification;
