export const googleClientId =
  "166799978388-o4iam7041vam51iee3alrjj6iuh3i9oj.apps.googleusercontent.com";
// export const domainName = "http://127.0.0.1:8000";
export const domainName = "https://www.admin.tart.aapkacfo.com";

export const api = {
  signup: "/api/signup",
  login: "/api/login",
  emailAvailability: "/api/isEmailAvailable",
  profile: "/api/profile",
  updateProfileImg: "/api/update-profile-img",
  categories: "/api/get-categories-list",
  allProducts: "/api/get-all-products-list",
  recentProducts: "/api/get-recent-products",
  popularProducts: "/api/get-popular-products",
  categoryProducts: "/api/get-category-products/",
  productInfo: "/api/get-product-info/",
  cart: "/api/get-cart",
  otp: "/api/otp",
  verify_otp: "/api/verify-otp",
  reset_password: "/api/resetPassword",
  privacy: "/api/privacy-policy",
  terms: "/api/terms-of-use",
  send_otp: "/api/send-otp",
  verify_otp: "/api/verify-otp",
  addToCart: "/api/add-to-cart",
  updateQuantity: "/api/update-quantity",
  deleteFromCart: "/api/delete-from-cart/",
  getAddresses: "/api/get-addresses",
  addAddress: "/api/add-address",
  deleteAddress: "/api/delete-address/",
  updateAddress: "/api/update-address",
  payment: "/api/payment",
  membershipPayment: "/api/membership-payment",
  createOrder: "/api/create-order",
  getOrders: "/api/get-orders",
  cancelOrder: "/api/cancel-order",
  returnOrder: "/api/return-order",
  getFavorites: "/api/get-favourite-list",
  addFavorite: "/api/add-favourite-list/",
  removeFavorite: "/api/remove-from-favourite-list/",
  verifyGoogleToken: "/api/verify/google_access_token",
  notifications: "/api/notification-details",
  getReviews: "/api/get-all-reviews/",
  addReview: "/api/add-review",
  editReview: "/api/edit-review",
  contactUs: "/api/contact-us",
  consultancy: "/api/consultancy",
  getCustomerMemberShipPlan: "/api/membership/customer",
  activeMembership: "/api/membership/active-plan",
  calculateDiscount: "/api/membership/calculate-discount",
  
};
export const sellerApi = {
  sellerSignup: "/api/seller/create-seller",
  sellerPanDetail: "/api/seller/seller-pan-card",
  sellerCategories: "/api/seller/category",
  sellerStore: "/api/seller/seller-store",
  sellerDetail: "/api/seller/get-seller-detail",
  checkStoreNameAvailable: "/api/seller/store-name-available",
  addProduct: "/api/seller/product",
  addProductImage: "/api/seller/product-image",
  productDetails: "/api/seller/product-details",
  allProducts: "/api/seller/get-all-products-list",
  homePageProducts: "/api/seller/get-homepage-products",
  productInfo: "/api/seller/get-product-info/",
  addToCart: "/api/seller/add-to-cart",
  getAddresses: "/api/seller/get-addresses",
  addAddress: "/api/seller/add-address",
  cart: "/api/seller/get-cart",
  deleteAddress: "/api/seller/delete-address/",
  updateAddress: "/api/seller/update-address",
  deleteFromCart: "/api/seller/delete-from-cart/",
  updateQuantity: "/api/seller/update-quantity",
  createOrder: "/api/seller/order/create-order",
  cancelOrder: "/api/seller/order/cancel-order",
  getOrders: "/api/seller/order/get-orders",
  getSellerOrders: "/api/seller/order/get-seller-orders",
  returnOrder: "/api/seller/order/return-order",
  getFavorites: "/api/seller/get-favourite-list",
  addFavorite: "/api/seller/add-favourite-list/",
  removeFavorite: "/api/seller/remove-from-favourite-list/",
  getDashboard: "/api/seller/dashboard",
  getReturnRequest: "/api/seller/order/get-return-orders-request",
  returnOrderRequest: "/api/seller/order/return-order-request",
  getRecentOrder: "/api/seller/order/get-recent-orders",
  orderStatus: "/api/seller/order/status",
  getSellerProfile: "/api/seller/profile",
  updateSellerInfo: "/api/seller/seller-info",
  updateSellerStoreInfo: "/api/seller/seller-store-info",
  updateSellerStoreLogo: "/api/seller/update-logo-img",
  notificationSetting: "/api/seller/notification",
  supportTicket: "/api/seller/support-ticket",
  getSellerFaq: "/api/seller/faq",
  getSellerMemberShipPlan: "/api/membership/seller",
};
