import React, { useState } from "react";
import "./SellerDashboard.scss";
import {
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from "react-router-dom";
import { RiShoppingBagLine } from "react-icons/ri";
import { AiOutlineShop } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { BiSupport } from "react-icons/bi";
import { ReactComponent as Dashboard } from "../../../assets/svgs/element-2 1.svg";
import { ReactComponent as ManageArt } from "../../../assets/svgs/box-1 1 (1).svg";
import { ReactComponent as Memberships } from "../../../assets/svgs/mdi_badge-outline.svg";
import { ReactComponent as Payout } from "../../../assets/svgs/uiw_pay.svg";
import { ReactComponent as ArtCategory } from "../../../assets/svgs/carbon_expand-categories.svg";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import logo from "../../../assets/images/black-logo.png";
import { BiLogOutCircle } from "react-icons/bi";
import SellerDashbordSidebar from "./components/SellerDashbordSidebar/SellerDashbordSidebar";
import { IoMdNotificationsOutline } from "react-icons/io";
import { SellerShop } from "../SellerShop/SellerShop";

import {
  AiOutlineMenu,
  AiOutlineMessage,
  AiOutlineShoppingCart,
} from "react-icons/ai";
// import profile from "../../../assets/images/profile_pic.jpg";
import ManageArtWork from "./components/ManageArtWork/ManageArtWork";
import { IoIosArrowForward } from "react-icons/io";
import Orders from "./components/Orders/Orders";
import DashboardHome from "./components/DashboardHome/DashboardHome";
import EarningPayouts from "./components/EarningPayouts/EarningPayouts";
import ProfileSettings from "./components/ProfileSettings/ProfileSettings";
import ArtworkCategories from "./components/ArtworkCategories/ArtworkCategories";
import HelpSupport from "./components/HelpSupport/HelpSupport";
import SupportTicket from "./components/SupportTicket/SupportTicket";
import Notification from "./components/Notification/Notification";
import SellerMembership from "./components/SellerMembership/SellerMembership";
import { domainName } from "../../../Constants";
import { useStateValue } from "../../../StateProvider";
import { PiCaretDownBold } from "react-icons/pi";
import { LuLayoutDashboard, LuSettings } from "react-icons/lu";
import { FaRegCalendarCheck, FaUserEdit } from "react-icons/fa";
import { LiaHeart } from "react-icons/lia";
import { MdOutlineLocalShipping } from "react-icons/md";
import { ProfileOptions } from "./components/ProfileOptions/ProfileOptions";

const dashboardOptions = [
  { url: "dashboard", icon: LuLayoutDashboard, title: "Dashboard" },
  { url: "my-orders", icon: FaRegCalendarCheck, title: "My Orders" },
  { url: "favorites", icon: LiaHeart, title: "Favorites" },
  { url: "message", icon: AiOutlineMessage, title: "Message" },
  { url: "settings", icon: LuSettings, title: "Settings" },
  { url: "edit-profile", icon: FaUserEdit, title: "Edit Profile" },
  { url: "shipping", icon: MdOutlineLocalShipping, title: "Shipping" },
  { url: "support-ticket", title: "Support Ticket" },
];

const SellerDashboard = () => {
  const params = useParams();
  const userDetails = JSON.parse(localStorage.getItem("user_details")) || JSON.parse(sessionStorage.getItem("user_details"))
  const [page, setPage] = useState("Dashboard");
  console.log(userDetails, "USER DETAILS")
  const [profile, setProfile] = useState(userDetails?.profile_image ? domainName + userDetails?.profile_image : "https://img.freepik.com/free-icon/user_318-159711.jpg")
  const navigate = useNavigate();
  const [{ showProfileOptions, userLoggedIn }, dispatch] = useStateValue();
  const titleList = {
    addNewArt: {
      title: "Add New Artworks",
      subTitle: ["Dashboard", "Add New Artworks"],
    },
    ArtWorkList: {
      title: "Manage listed artworks",
      subTitle: ["Dashboard", "Manage listed artworks"],
    },
    OrderList: {
      title: "Sales and Orders",
      subTitle: ["Dashboard", "View sales history"],
    },
    ViewOrderDetail: {
      title: "Sales and Orders",
      subTitle: ["Dashboard", "View sales history", "View Details"],
    },
    Dashboard: {
      title: "Dashboard Overview",
      // subTitle: ["Dashboard", "View sales history","View Details"],
    },
    CancellationRequest: {
      title: "Order Cancellation Requests",
      // subTitle: ["Dashboard", "View sales history","View Details"],
    },
    Earning: {
      title: "Earnings and Payouts",
      subTitle: ["Dashboard", "Earnings and Payouts"],
    },
    ProfileInfo: {
      title: "Profile Settings",
      subTitle: ["Dashboard", "Account information"],
    },
    StoreSetting: {
      title: "Profile Settings",
      subTitle: ["Dashboard", "Store Settings"],
    },
    ManageNotification: {
      title: "Profile Settings",
      subTitle: ["Dashboard", "Manage notification"],
    },
    ArtCategory: {
      title: "Artwork Categories",
      subTitle: ["Dashboard", "Manage categories"],
    },
    SupportFAQ: {
      title: "Support and Help Center",
      subTitle: ["Dashboard", "Help articles and FAQs"],
    },
    SupportForm: {
      title: "Support and Help Center",
      subTitle: ["Dashboard", "Contact customer support"],
    },
    SupportTicket: {
      title: "Support Ticket",
      subTitle: ["Dashboard", "Support Ticket"],
    },
    Notification: {
      title: "Notifications",
      subTitle: ["Dashboard", "Notifications"],
    },
    Memberships: {
      title: "Memberships",
      subTitle: ["Dashboard", "Memberships"],
    },
  };
  return (
    <div className="dashboard-container">
      <div className="left">
        <SellerDashbordSidebar setPage={setPage} />
      </div>
      <div className="right" data-aos="fade-left">
        <div className="header">
          <div className="title-wrapper">
            <div className="title">{titleList[page]?.title}</div>
            {titleList[page]?.subTitle ? (
              <div className="sub-title">
                {titleList[page]?.subTitle?.map((subTitle, index) => (
                  <>
                    {subTitle}
                    {index + 1 !== titleList[page].subTitle.length && (
                      <IoIosArrowForward />
                    )}
                  </>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="notification">
            <div
              className="icon"
              onClick={() => {
                setPage("Notification");
                navigate("/seller/dashboard/notification");
              }}
            >
              <IoMdNotificationsOutline size={20} />
            </div>
            {/* <div className="profile">
              <img src={profile} alt="" />
              <span>{userDetails?.name}</span>
            </div> */}
            <div
                className="profile sm-view-profile"
                onClick={() =>
                  dispatch({
                    type: "PROFILE_OPTIONS_VIEW",
                    status: !showProfileOptions,
                  })
                }
              >
                <img
                  src={
                    userDetails?.profile_image
                      ? `${domainName}${userDetails?.profile_image}`
                      : "https://img.freepik.com/free-icon/user_318-159711.jpg"
                  }
                  alt="profile"
                  className="profile-img"
                />
                <span>{userDetails?.name}</span>
                <PiCaretDownBold className="icon" />
              </div>
          </div>
        </div>

        {(() => {
          switch (params.page) {
            case "manage-artworks":
              return <ManageArtWork setPage={setPage} />;
              case "membership":
                return <SellerMembership setPage={setPage} />;
                case "orders":
                  return <Orders setPage={setPage} />;
                  case "earnings-payouts":
                    return <EarningPayouts setPage={setPage} />;
            case "profile-settings":
              return <ProfileSettings setPage={setPage} setProfile={setProfile} />;
              case "shop":
                return <SellerShop />;
                case "artwork-categories":
                  return <ArtworkCategories setPage={setPage} />;
                  case "support":
                    return <HelpSupport setPage={setPage} />;
                    case "support-ticket":
                      return <SupportTicket setPage={setPage} />;
                      
                      case "notification":
                        return <Notification setPage={setPage} />;
                        case "home":
                          return <DashboardHome setPage={setPage} />;
                          
                          default:
                            return;
                          }
                        })()}
      </div>
      <div className="seller-profile-options">
        {showProfileOptions && <ProfileOptions storename={'dashboard'} />}
      </div>
    </div>
  );
};

export default SellerDashboard;
