import React, { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { domainName } from "../../../../../../../Constants";
import "./ReturnOrder.scss";
import ConfirmationModal from "../../../../../../../components/modals/ConfirmationModal/ConfirmationModal";
import toast from "react-hot-toast";

const ReturnOrder = ({
  orders,
  setShowReturnConfirm,
  doreturnorderconfirm,
  returningOrderId,
  date,
  updatedDate,
  ...props
}) => {
  const order = orders.filter((item) => item.id === returningOrderId)[0];
  const [show,setShow] =useState(false)
  const [isResonSelected,setIsResonSelected] =useState(false)
  const onShow = ()=> {
    if(isResonSelected){
      setShow(true)
    }
    else{
      toast("Select reason to return products", { icon: "⚠️" });
    }

  }
  const reasons = [
    "I have changed my mind",
    "I have found a better deal",
    "Ordered by mistake",
    "It is too expensive",
    "Delay of delivery",
  ];
  return (
    <div className="return-order">
      <h5>Return Order</h5>
      <div className="row">

      <div className="left">
        <div className="return-confirm">
          <h3>Reason for Return:</h3>
          <div className="form">
            {/* <h4>Select reason for cancelling the order</h4> */}
            <div className="radio-options">
              {reasons.map((reason) => (
                <div
                  className="radio"
                  key={reason}
                  onClick={() => {props.setreturningreason(reason);setIsResonSelected(true)}}
                >
                  <input type="radio" name="reason" id="" value={reason} />
                  <label htmlFor="">{reason}</label>
                </div>
              ))}
            </div>
            <div className="buttons">
              <button
                onClick={() => {
                  setShowReturnConfirm(false)
                }}
                className="btn-secondary"
              >
                Back
              </button>
              <button
                onClick={onShow}
                className="btn-primary"
              >
               Return Order
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="right">
        <div className="return-order-content">
          <div className="return-order-header-offcanvas">
            <span className="h5">order summary</span>
          </div>
          <span className="fw-500 order-id">Order ID : #{order.id}</span>
          <span className="title">Order Placed : {date}</span>
          <span className="title">Shipping address</span>
          <div className="address">
            <span>{order?.name}</span>
            <span style={{ textTransform: "uppercase" }}>{order?.address}</span>
            <span>{order?.state}</span>
          </div>
          <div className="deliver-date">Order delivered: {updatedDate}</div>
          {order.product.map((item) => (
            <div className="product-details">
              <img
                src={`${domainName}/uploads/${item.productDetail.main_image}`}
                alt="product"
              />
              <div>
                <span className="name">
                  {item.productDetail.title} <br />{" "}
                  <span>{item.productDetail.category__title}</span>
                </span>
                <span className="quantity">Qyt:{item.quantity}</span>
                <span className="price">
                  ₹{item.productDetail.price * item.quantity}
                </span>
              </div>
            </div>
          ))}
          <div className="order-summary">
            {/* <span className="h5">Order Summary</span> */}
            <div className="underline-return-order-header"></div>
            <div className="flex-between sub-total">
              <span>Subtotal</span>
              <span>₹{order.total_amount}</span>
            </div>
            <div className="flex-between">
              <span>Shipping</span>
              <span>-----</span>
            </div>
            <div className="underline-return-order-header"></div>
            <div className="flex-between total">
              <div className="h5">
                Total <span>Monthly payment</span>{" "}
              </div>
              <div className="h5 amount">
                ₹{order.total_amount} <span>₹6045/month with EMI</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <ConfirmationModal confirmFun={doreturnorderconfirm} show={show}
        onHide={() => setShow(false)} />
    </div>
  );
};

export default ReturnOrder;
