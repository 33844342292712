import React from "react";
import ReactDOM from "react-dom";
import ReactApexChart from "react-apexcharts";

export const LineChart = ({dashboardData}) => {
  const options = {
    chart: {
      type: "area",
      stacked: false,
      height: 350,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "zoom",
      },
    },
    colors:['#03A01C', '#E91E63', '#9C27B0'],
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    title: {
      text: "Earnings",
      align: "left",
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    // yaxis: {
    //   labels: {
    //     formatter: function (val) {
    //       return (val / 1000000).toFixed(0);
    //     },
    //   },
    //   title: {
    //     text: 'Price'
    //   },
    // },
    xaxis: {
      categories: dashboardData?.month_names,
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return `${val}$`;
        },
      },
    },
  };

  const series = [
    {
      name: "earning",
      data:  dashboardData?.earnings_list,
    },
  ];

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={350}
          
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};
