import "./BecomeSellerNavbar.scss";
import Logo from "../../assets/images/black-logo.png";
import { IoClose } from "react-icons/io5";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { GrFormClose } from "react-icons/gr";

import { MdOutlineLocalShipping } from "react-icons/md";
import { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { RiShoppingBagLine } from "react-icons/ri";
import { AiOutlineShop } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { BiSupport } from "react-icons/bi";
import { ReactComponent as Dashboard } from "../../assets/svgs/element-2 1.svg";
import { ReactComponent as ManageArt } from "../../assets/svgs/box-1 1 (1).svg";
import { ReactComponent as Memberships } from "../../assets/svgs/mdi_badge-outline.svg";
import { ReactComponent as Payout } from "../../assets/svgs/uiw_pay.svg";
import { ReactComponent as ArtCategory } from "../../assets/svgs/carbon_expand-categories.svg";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { BiLogOutCircle } from "react-icons/bi";

// import logo from "../../../assets/images/black-logo.png";
export const BecomeSellerNavbar = () => {
  const { pathname } = useLocation();
  const userDetails = JSON.parse(sessionStorage.getItem("user_details"));
  const [showDashboardMenu, setShowDashboardMenu] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = useState("manage-artworks");

  const options = [
    { url: "/home", icon: Dashboard, title: "Dashboard" },
    { url: "/manage-artworks", icon: ManageArt, title: "Manage Artworks" },
    // { url: "/membership", icon: Memberships, title: "Memberships" },
    { url: "/orders", icon: RiShoppingBagLine, title: "Orders" },
    { url: "/shop", icon: AiOutlineShop, title: "Shop" },
    { url: "/earnings-payouts", icon: Payout, title: "Earnings and Payouts" },
    { url: "/profile-settings", icon: CgProfile, title: "Profile Settings" },
    {
      url: "/artwork-categories",
      icon: ArtCategory,
      title: "Artwork Categories",
    },
    {
      url: "/support",
      icon: BiSupport,
      title: "Support and Help Center",
    },
  ];
  return (
    <>
      <div
        className="seller-navbar-main drgert"
        style={{ display: `${pathname !== "/seller/signup" ? "none" : "flex"}` }}
      >
        <div className="logo-and-search">
          {/* {pathname === "/seller" ? ( */}
            <AiOutlineMenu
              onClick={() => setShowDashboardMenu(!showDashboardMenu)}
              className="menu-icon"
            />
          {/* ) : null} */}
          <Link to={"/"}>
            <img src={Logo} alt="logo" className="logo-img" />
          </Link>
        </div>
        {/* <div className="profile">
          {pathname === "/seller/signup" ? (
            <IoClose size={20} />
          ) : (
            <img
              src={"https://img.freepik.com/free-icon/user_318-159711.jpg"}
              alt="profile"
              className="profile-img"
            />
          )}
        </div> */}
      </div>
      <Offcanvas
        show={showDashboardMenu}
        onHide={() => setShowDashboardMenu(false)}
        placement={"start"}
      >
        <div className="offcanvas-header-dashboard">
          <div className="logo">
            <img src={Logo} alt="" />
          </div>
          <GrFormClose
            className="icon"
            onClick={() => setShowDashboardMenu(false)}
          />
        </div>
        <Offcanvas.Body className="seller-dashboard-offcanvas-body">
          <div className="offcanvas-inner">
            <div className="options">
              <div className="option-inner-wrapper">
                
                <div className="chat btn-secondary">
                  <IoChatbubbleEllipsesOutline size={20} /> Chats
                </div>
                {options.map((option, i) => (
                  <div
                    onClick={() => {
                      navigate(`/seller/dashboard${option.url}`);
                      setShowDashboardMenu(false);
                    }}
                    className={`option ${pathname === `/seller/dashboard/${option.url}` ? "active":""}`}
                    key={i}
                  >
                    {option.icon && <option.icon className="icon" size={20} />}
                    {option.title}
                  </div>
                ))}
              </div>
              <div className="logout-support">
                <div className="logout">
                  <BiLogOutCircle size={20} />
                  Log Out
                </div>
                <button className="btn-primary">Support</button>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <Outlet />
    </>
  );
};
