import React, { useState } from "react";
import "./Overview.scss";
import { HiMiniMagnifyingGlass } from "react-icons/hi2";
import productImg from "../../../../../../../assets/arts/art (2).png";
import { MdOutlineContentCopy } from "react-icons/md";
import toast from "react-hot-toast";
import {
  OrderDetailModal,
  ReturnOrderDetailModal,
} from "../../../../../../../components/modals/OrderDetailModal/OrderDetailModal";
import { useQuery } from "@tanstack/react-query";
import {
  getDashboard,
  getRecentOrder,
  getReturnRequest,
  orderStatus,
  returnOrderRequest,
} from "../../../../../../../apiCall";
import { domainName } from "../../../../../../../Constants";

function formatDate(inputDate) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const dateObj = new Date(inputDate);

  const formattedDate = dateObj.toLocaleDateString("en-US", options);

  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const period = hours >= 12 ? "PM" : "AM";
  const formattedTime = `${hours % 12}:${minutes
    .toString()
    .padStart(2, "0")} ${period}`;

  return `${formattedDate}, ${formattedTime}`;
}
const Overview = ({ setActiveTab, setPage }) => {
  const filterOption = [
    "Confirmed",
    "Shipped",
    "Delivered",
    "Canceled",
    "Returned",
  ];

  const [dashboardData, setDashboardData] = useState({});
  const [cancelRequests, setCancelRequests] = useState([]);
  const [orderDetailData, setOrderDetailData] = useState({});
  const [recentOrder, setRecentOrder] = useState([]);
  const { isLoading } = useQuery(
    ["dashboard"],
    getDashboard,
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          setDashboardData(data.data?.value);
        } else {
          toast.error("something went wrong there. please login again.");
        }
      },
      onError: (e) => {
        if (e.message) {
          return toast.error(e.message);
        }
        toast.error("Something went wrong");
      },
    }
  );
  const { isLoading: isCancellRequestLoading, refetch } = useQuery({
    queryKey: ["returnRequest"],
    queryFn: () => getReturnRequest(1),
    onSuccess: (data) => {
      console.log(data);
      if (data.status === 200) {
        setCancelRequests(data.data?.value);
      } else {
        toast.error("something went wrong there. please login again.");
      }
    },
    onError: (e) => {
      if (e.message) {
        return toast.error(e.message);
      }
      toast.error("Something went wrong");
    },
  });
  const {} = useQuery({
    queryKey: ["recentOrder"],
    queryFn: () => getRecentOrder(1),
    onSuccess: (data) => {
      console.log(data);
      if (data.status === 200) {
        setRecentOrder(data.data?.value);
      } else {
        toast.error("something went wrong there. please login again.");
      }
    },
    onError: (e) => {
      if (e.message) {
        return toast.error(e.message);
      }
      toast.error("Something went wrong");
    },
  });
  const handelRequestAction = async (data) => {
    const re = await returnOrderRequest(data);
    console.log(re);
    if (re?.status === 200) {
      toast.success("request updated.");
      refetch();
    } else {
      // if (Re?.response.status === 409) {
      toast.error(re?.response.data.status[0].ResponseMessage);
    }
  };
  const handelOrderStatus = async (data) => {
    const re = await orderStatus(data);
    console.log(re);
    if (re?.status === 200) {
      toast.success("Order status changed successfully.");
    } else {
      // if (Re?.response.status === 409) {
      toast.error(re?.response.data.status[0].ResponseMessage);
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [orderDetailShowModal, setOrderDetailShowModal] = useState(false);
  return (
    <div className="overview-container">
      <div className="statistics ">
        <div className="statistics-card">
          <span className="value">
            {dashboardData?.total_artwork_count
              ? dashboardData?.total_artwork_count
              : 0}
          </span>
          <span>Total Artworks</span>
        </div>
        <div className="statistics-card">
          <span className="value">
            {dashboardData?.artwork_sold_count
              ? dashboardData?.artwork_sold_count
              : 0}
          </span>
          <span>Artworks Sold</span>
        </div>
        <div className="statistics-card">
          <span className="value">
            ₹{dashboardData?.total_earnings ? dashboardData?.total_earnings : 0}
          </span>
          <span>Total Earnings</span>
        </div>
        <div className="statistics-card">
          <span className="value">
            ₹
            {dashboardData?.pending_payouts
              ? dashboardData?.pending_payouts
              : 0}
          </span>
          <span>Pending Payouts</span>
        </div>
        <div className="statistics-card">
          <span className="value">
            ₹{dashboardData?.total_payouts ? dashboardData?.total_payouts : 0}
          </span>
          <span>Completed Payouts</span>
        </div>
      </div>
      <div className="store-url card-container">
        <div className="title">Your Store URLs</div>
        <div className="url-wrapper">
          <div className="url">{dashboardData?.store_url}</div>
          <div
            className="copy-btn"
            onClick={() => {
              navigator.clipboard.writeText(`${dashboardData?.store_url}`);
              toast.success("Copied.");
            }}
          >
            <MdOutlineContentCopy size={16} /> Copy link
          </div>
        </div>
      </div>
      <div className="order-cancellation-request card-container">
        <div className="title">
          <span>Order Cancellation Requests</span>
          <span
            className="view-all"
            onClick={() => {
              setPage("CancellationRequest");
              setActiveTab("CancellationRequest");
            }}
          >
            View all
          </span>
        </div>
        <div className="request-list">
          {cancelRequests.length > 0 ? (
            <>
              {cancelRequests.map((order) => (
                <div className="request-wrapper">
                  <span className="title">Request #{order.return_id}</span>
                  <span>
                    Order Number: <strong>{order.order_id}</strong>{" "}
                  </span>
                  <span>
                    Date:{" "}
                    <strong>{formatDate(order.order_details.create_at)}</strong>
                  </span>
                  <span>
                    Total Amount:{" "}
                    <strong>{order.order_details.total_amount}</strong>
                  </span>
                  <span>
                    Reason: <strong>{order.reason}</strong>
                  </span>

                  <span
                    className="view-detail"
                    onClick={() => {
                      setOrderDetailData(order);
                      setShowModal(true);
                    }}
                  >
                    {" "}
                    View Details
                  </span>
                  <div className="action-btn">
                    <button
                      className="btn-secondary"
                      onClick={() =>
                        handelRequestAction({
                          returnRequestId: order.return_id,
                          status: "Rejected",
                        })
                      }
                    >
                      Reject
                    </button>
                    <button
                      className="btn-primary"
                      onClick={() =>
                        handelRequestAction({
                          returnRequestId: order.return_id,
                          status: "Initiated",
                        })
                      }
                    >
                      Accept
                    </button>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <span>You don't have any requests.</span>
          )}
        </div>
      </div>

      <div className="list card-container">
        <div className="title">Recent Activity</div>
        <div className="table-container">
          <div className="table-title">Latest Sale</div>
          {recentOrder.length > 0 ? (
            <table>
              <thead>
                <th>Artwork Title </th>
                <th>Price</th>
                <th>Stock</th>
                <th>Earrings</th>
                <th>Shipping</th>
                <th>Status</th>
                <th>Action</th>
              </thead>
              <tbody>
                <>
                  {recentOrder.map((order, index) => (
                    <tr>
                      <td>
                        <div className="data-wrapper">
                          {order.product.map((product) => (
                            <div
                              className="product-detail-wrapper"
                              id={`recent-order-${index}`}
                            >
                              <img
                                src={`${domainName}/uploads/${product.productDetail.main_image}`}
                                alt=""
                              />
                              <div className="product-detail">
                                <div className="name">
                                  {product.productDetail.title}
                                </div>
                                <div className="category">
                                  {product.productDetail.category__title}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </td>
                      <td>
                        <div className="data-wrapper">
                          {order.product.map((product) => (
                            <span>₹{product.productDetail.price}</span>
                          ))}
                        </div>
                      </td>
                      <td>
                        <div className="data-wrapper">
                          {order.product.map((product) => (
                            <span>{product.quantity}</span>
                          ))}
                        </div>
                      </td>
                      <td>₹{order.total_amount}</td>
                      <td>
                        <div className="shipping-type">Local delivery</div>
                      </td>
                      <td>
                        <select
                          defaultValue={order.status}
                          onChange={(e) =>
                            handelOrderStatus({
                              orderId: order.id.toString(),
                              status: e.target.value,
                            })
                          }
                        >
                          <option value="Received" disabled>
                            Received
                          </option>
                          {filterOption.map((option, index) => (
                            <option value={option}>{option}</option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <div className="action-wrapper">
                          <span
                            onClick={() => {
                              setOrderDetailData(order);
                              setOrderDetailShowModal(true);
                            }}
                          >
                            View Details
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              </tbody>
            </table>
          ) : (
            "You don't have any orders."
          )}
        </div>
      </div>
      <ReturnOrderDetailModal
        order={orderDetailData}
        handelRequestAction={handelRequestAction}
        show={showModal}
        onHide={() => setShowModal(false)}
      />
      <OrderDetailModal
        order={orderDetailData}
        show={orderDetailShowModal}
        onHide={() => setOrderDetailShowModal(false)}
      />
    </div>
  );
};

export default Overview;
