import React from "react";
import "./MembershipCard.scss";
import {ReactComponent as CheckIcon} from "../../assets/svgs/check-single.svg";
import { useStateValue } from "../../StateProvider";
import toast from "react-hot-toast";

const MembershipCard = ({ data,displayRazorpay,handleOrderSuccessPopup }) => {
  const [{userLoggedIn},dispatch]= useStateValue()
  const handelSubmit = (planId)=>{
    if(userLoggedIn){
      console.log(planId)

      displayRazorpay(planId,handleOrderSuccessPopup)
    }
    else{
      toast.error("please login first.")
    }
  }
  return (
    <div className="membership-card ">
      <div className="header">
        <div className="amount">₹{data.price}/month</div>
        <div className="plan-name">{data.plan_name}</div>
      </div>
      <div className="content">
        <div className="features">
          <ul>
            {data?.feature?.map((item, index) => (
              <li id={`${index}`}>
                <CheckIcon />
                {item?.title}
              </li>
            ))}
          </ul>
        </div>
        <div className="button-wrapper">
          <button className="btn-orange" onClick={()=>handelSubmit(data.id)}>Choose</button>
        </div>
      </div>
      {data.is_populer && <div className="popular-tag">Most Popular</div>}
    </div>
  );
};

export default MembershipCard;
