import React, { useEffect, useState } from "react";
import PanVerification from "./components/PanVerification/PanVerification";
import SetupStore from "./components/SetupStore/SetupStore";
import SellerRegister from "./components/SellerRegister/SellerRegister";
import { useStateValue } from "../../../StateProvider";
import { useNavigate } from "react-router-dom";

const SellerSignup = () => {
  const [{ userLoggedIn, userDetail }, dispatch] = useStateValue();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(userLoggedIn && !userDetail?.is_pan_card_added)
    if (userLoggedIn && !userDetail?.is_pan_card_added) {
      setActiveTab("panVerification");
    } else {
      if (userLoggedIn && !userDetail?.is_store_detail_added) {
        setActiveTab("setupStore");
      } else {
        if(userLoggedIn ){
          navigate("/seller/dashboard/home");
        }
      }
    }
  }, [userDetail]);
  const [activeTab, setActiveTab] = useState("");
  switch (activeTab) {
    case "panVerification":
      return <PanVerification setActiveTab={setActiveTab} />;
    case "setupStore":
      return <SetupStore setActiveTab={setActiveTab} />;

    default:
      return <SellerRegister setActiveTab={setActiveTab} />;
  }
};

export default SellerSignup;
