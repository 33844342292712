import React, { useEffect, useState } from "react";
import { ReactComponent as status1 } from "../../../../../assets/svgs/Property 1=2.svg";
import { ReactComponent as status2 } from "../../../../../assets/svgs/Property 1=Default.svg";
import { ReactComponent as status3 } from "../../../../../assets/svgs/Property 1=3.svg";
import OrdersList from "./components/OrdersList/OrdersList";
import ViewOrderDetail from "./components/ViewOrderDetail/ViewOrderDetail";

const Orders = ({ setPage }) => {
  const [activateTab, setActiveTab] = useState("");
  const dummyOrder = {
    id: 39,
    coupon_code: null,
    name: "prit manvar",
    phone_number: 8238378605,
    address: "139, Vikaram nagar 2, punaga, surat.",
    city: "Surat",
    state: "Gujarat",
    status: "Received",
    pin_code: 395010,
    shipping_fee: 0,
    shipping_weight: 0,
    total_amount: 120000,
    total_discount: 0,
    create_at: "2024-04-23T18:42:14.959416Z",
    update_at: "2024-04-24T17:38:35.976302Z",
    product: [
      {
        quantity: 4,
        discount: 0,
        update_at: "2024-04-23T18:42:15.407423Z",
        productDetail: {
          id: 7,
          title: "Product 4",
          main_image: "product_image/art_4.png",
          category: 1,
          category__title: "Paintings",
          price: 30000,
          owner: "nandas",
          emi: null,
          available_for_rent: false,
          discount: 0,
        },
      },
    ],
  };
  const [viewOrderDetail, setViewOrderDetail] = useState(dummyOrder);

  switch (activateTab) {
    case "viewOrderDetail":
      return (
        <ViewOrderDetail
          setActiveTab={setActiveTab}
          setPage={setPage}
          formType={"addArtWork"}
          order={viewOrderDetail}

        />
      );

    default:
      return (
        <OrdersList
          setActiveTab={setActiveTab}
          setPage={setPage}
          setViewOrder={setViewOrderDetail}

        />
      );
  }
};

export default Orders;
