import "./OrderDetails.scss";
import { useEffect, useState } from "react";
import { Collapse, Modal } from "react-bootstrap";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
// import StepProgressbar from"../../../../../components/StepProgressbar/StepProgressbar";
import artImg from"../../../../../assets/arts/art (5).png";
import { domainName } from"../../../../../Constants";
import { useNavigate } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { IoCloseSharp } from "react-icons/io5";
import { BsPersonCircle } from "react-icons/bs";
import { addReview, editReview, getReviews } from"../../../../../apiCall";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import ProductDetails from "./components/ProductDetails/ProductDetails";
import { InvoiceModal } from"../../../../../components/modals/InvoiceModal/InvoiceModal";
import { ProductReviewModal } from"../../../../../components/modals/ProductReviewModal/ProductReviewModal";
import { useStateValue } from "../../../../../StateProvider";
import StepProgressbar from "./components/StepProgressbar/StepProgressbar";

const OrderDetails = ({
  order,
  setShowCancelConfirm,
  setCancellingOrderId,
  setShowReturnConfirm,
  setReturningOrderId,
}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [reviewData, setReviewData] = useState(null);
  const [reviewingProductId, setReviewingProductId] = useState("");
  const [{tax_percentage}] = useStateValue()  

  function formatDate(inputDate) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const dateObj = new Date(inputDate);

    const formattedDate = dateObj.toLocaleDateString("en-US", options);

    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";
    const formattedTime = `${hours % 12}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;

    return `${formattedDate}, ${formattedTime}`;
  }

  const date = formatDate(order.create_at);
  const updatedDate = formatDate(order.update_at);


  const handleCancelClick = () => {
    setShowCancelConfirm(true);
    setCancellingOrderId(order.id);
  };

  const handleReturnClick = () => {
    setShowReturnConfirm(true);
    setReturningOrderId(order.id);
  };

  return (
    <div className="setting-details-div">
      <div
        className="summary-details"
        aria-controls="expanded-order-details"
        aria-expanded={open}
        onClick={() => setOpen(!open)}
      >
        <span>Order ID : {order.id}</span>
        <span>Order Placed : {date}</span>
        <span>Order Amount: ₹{(parseInt(order.total_amount) + parseInt(order.tax)).toLocaleString('en-IN')}</span>
        {order.status === "Delivered" && (
          <span>Order delivered: {updatedDate}</span>
        )}
        {open ? (
          <AiFillCaretUp className="icon" />
        ) : (
          <AiFillCaretDown className="icon" />
        )}
      </div>
      <Collapse in={open}>
        <div className="expanded-order-details">
          {order.status === "Delivered" && (
            <span>Order delivered: {updatedDate}</span>
          )}
          {/* <h2>Order delivered: April 6, 3:00 PM by Raj Yadav</h2> */}
          <StepProgressbar orderStatus={order.status} />
          {order.product.map((product) => (
            <ProductDetails
              key={product.productDetail.id}
              order={order}
              product={product}
              setShowReviewModal={setShowReviewModal}
              setReviewData={setReviewData}
              reviewData={reviewData}
              handleCancelClick={handleCancelClick}
              handleReturnClick={handleReturnClick}
              setShowInvoiceModal={setShowInvoiceModal}
              setReviewingProductId={setReviewingProductId}
            />
          ))}
          
        </div>
      </Collapse>

      <ProductReviewModal
        show={showReviewModal}
        onHide={() => setShowReviewModal(false)}
        order={order}
        reviewData={reviewData}
        setReviewData={setReviewData}
        setReviewingProductId={setReviewingProductId}
        reviewingProductId={reviewingProductId}
      />

      <InvoiceModal
        show={showInvoiceModal}
        onHide={() => setShowInvoiceModal(false)}
        order={order}
        date = {date}
        updatedDate={updatedDate}
      />
    </div>
  );
};

export default OrderDetails;





// product details card
// const ProductDetails = ({
//   product,
//   setShowReviewModal,
//   setReviewingProductId,
//   order,
// }) => {
//   console.log(order,'order')
//   const navigate = useNavigate();

//   return (
//     <div className="art-details" key={product.productDetail.id}>
//       <img
//         src={`${domainName}/uploads/${product.productDetail.main_image}`}
//         alt="art"
//         onClick={() => navigate(`/product-info/${product.productDetail.id}`)}
//       />
//       <div className="details">
//         <div className="info">
//           <span>{product.productDetail.title}</span>
//           <span>{product.productDetail.owner}</span>
//           <span>Categories : {product.productDetail.category__title}</span>
//           <span>Quantity : {product.quantity}</span>
//           <span>₹{product.productDetail.price.toLocaleString("en-IN")}</span>
//         </div>
//         <div className="buttons">
//           {order?.status === "Completed" && (
//             <button
//               className="btn-secondary button"
//               onClick={() => {
//                 setReviewingProductId(product.productDetail.id);
//                 setShowReviewModal(true);
//               }}
//             >
//               Write product review
//             </button>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };
