import React, { useState } from "react";
import MembershipCard from "../../components/MembershipCard/MembershipCard";
import "./Membership.scss";
import { useQuery } from "@tanstack/react-query";
import { consultancy, getCustomerMemberShipPlan } from "../../apiCall";
import toast from "react-hot-toast";
import { displayRazorpay } from "./components/Razorpay";
import MembershipSuccessModal from "../../components/modals/MembershipSuccessModal/MembershipSuccessModal";
import { useForm } from "react-hook-form";
import { useStateValue } from "../../StateProvider";
import { ValidateNumberLength } from "../../utils/ValidateNumberLength";

const Membership = () => {
  // const data = [
  //   {
  //     id: 1,
  //     amount: 5000,
  //     plan_name: "Free version",
  //     features: [
  //       "Networking with other artists.",
  //       "Networking with other artists.",
  //       "Networking with other artists.",
  //       "Networking with other artists.",
  //     ],
  //     is_populer: true,
  //   },
  //   {
  //     id: 1,
  //     amount: 5000,
  //     plan_name: "Free version",
  //     features: [
  //       "Networking with other artists.",
  //       "Networking with other artists.",
  //       "Networking with other artists.",
  //       "Networking with other artists.",
  //     ],
  //     is_populer: true,
  //   },
  // ];
  const [, dispatch] = useStateValue();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      phone_number: "",
    },
  });
  const { isLoading, data } = useQuery(
    ["cuxtomer-plan"],
    getCustomerMemberShipPlan,
    {
      onSuccess: (data) => {
        console.log(data);
        if (data?.status === 200) {
          setPlanDetail(data.data?.value);
        } else {
          toast.error(data.response.data.status[0].ResponseMessage);
        }
      },
      onError: (e) => {
        if (e.message) {
          return toast.error(e.message);
        }
        toast.error("Something went wrong");
      },
    }
  );
  const [planDetail, setPlanDetail] = useState(
    data?.status === 200 ? data.data?.value : []
  );
  const [showMembershipSuccessModal, setShowMembershipSuccessModal] =
    useState(false);

  const handleOrderSuccessPopup = () => {
    setShowMembershipSuccessModal(true);
    setTimeout(() => {
      setShowMembershipSuccessModal(false);
    }, 3000);
  };

  const onSubmit = async (values) => {
    dispatch({ type: "SET_LOADING", status: true });
    try {
      const response = await consultancy(values);
      if (response.data.status[0].Error === "False") {
        toast.success("Consultancy request submitted successfully");
        reset();
      } else {
        toast(response.data.status[0].ResponseMessage, { icon: "⚠️" });
      }
    } catch (err) {
      toast.error(err.message);
    }
    dispatch({ type: "SET_LOADING", status: false });
  };

  return (
    <div className="membership">
      <div className="membership-plan">
        <div className="title">Membership Benefits for Sellers</div>
        <div className="membership-plan-detail">
          {planDetail.map((item) => (
            <MembershipCard
              data={item}
              displayRazorpay={displayRazorpay}
              handleOrderSuccessPopup={handleOrderSuccessPopup}
            />
          ))}
        </div>
      </div>
      <div className="schedule-call">
        <div className="title">Curious About Our Memberships? 🤔</div>
        <div className="content">
          We're here to assist you. Feel free to contact us with any questions,
          for support, or to discover more about how Tart can bring about a
          transformation
        </div>
        <div className="input-conatiner">
          <label>Phone number</label>
          <input
            type="number"
            placeholder="91738XXXXX"
            {...register("phone_number", {
              required: "This field is required",
              pattern: {
                value: /^[6-9]\d{9}$/i,
                message: "Invalid phone number",
              },
            })}
            onChange={(e) => ValidateNumberLength("phone_number", e, setValue)}
          />

          <small className="error">{errors.phone_number?.message}</small>
        </div>
        <div className="button-wrapper">
          <button className="btn-primary" onClick={handleSubmit(onSubmit)}>
            Schedule Consultation
          </button>
        </div>
      </div>
      <MembershipSuccessModal
        show={showMembershipSuccessModal}
        onHide={() => setShowMembershipSuccessModal(false)}
      />
    </div>
  );
};

export default Membership;
