import React from "react";
import "./MoreFromArtist.scss";
import { ProductCard } from "../ProductCard/ProductCard";
import art2 from "../../../../../assets/arts/art (2).png";
import art3 from "../../../../../assets/arts/art (3).png";
import art4 from "../../../../../assets/arts/art (4).png";
import artist_bg from "../../../../../assets/images/more_from_artist_bg.jpg";
import profile_pic from "../../../../../assets/images/Vector_profile.png";
// import vectorFill from "../../../../../assets/images/Vector_black.png";

const MoreFromArtist = () => {
  const products = [
    {
      id: 1,
      main_image: art2,
      title: "Structural Landscape",
      category__title: "Gregg Rosen/Paintings",
      owner: "",
      price: "₹1,85,323.00",
      available_for_rent: true,
      // emi: 299,
    },
    {
      id: 1,
      main_image: art3,
      title: "Structural Landscape",
      category__title: "Gregg Rosen/Paintings",
      owner: "",
      price: "₹1,85,323.00",
      available_for_rent: true,
    },
    {
      id: 1,
      main_image: art4,
      title: "Structural Landscape",
      category__title: "Gregg Rosen/Paintings",
      owner: "",
      price: "₹1,85,323.00",
      available_for_rent: true,
    },
    {
      id: 1,
      main_image: art2,
      title: "Structural Landscape",
      category__title: "Gregg Rosen/Paintings",
      owner: "",
      price: "₹1,85,323.00",
      available_for_rent: true,
    },
    {
      id: 1,
      main_image: art3,
      title: "Structural Landscape",
      category__title: "Gregg Rosen/Paintings",
      owner: "",
      price: "₹1,85,323.00",
      available_for_rent: true,
    },
    {
      id: 1,
      main_image: art4,
      title: "Structural Landscape",
      category__title: "Gregg Rosen/Paintings",
      owner: "",
      price: "₹1,85,323.00",
      available_for_rent: true,
    },
    {
      id: 1,
      main_image: art2,
      title: "Structural Landscape",
      category__title: "Gregg Rosen/Paintings",
      owner: "",
      price: "₹1,85,323.00",
      available_for_rent: true,
    },
    {
      id: 1,
      main_image: art3,
      title: "Structural Landscape",
      category__title: "Gregg Rosen/Paintings",
      owner: "",
      price: "₹1,85,323.00",
      available_for_rent: true,
    },
  ];
  return (
    <div className="more-from-artist">
      <div className="row">
        <div className="left-column">
          <div className="profile-card">
            <div className="title">Meet Your Artists</div>
            <div className="artist-deatil-wrapper">
              <div className="background">
                <img src={artist_bg} alt="" />
              </div>
              <div className="profile-img-wrapper">
                <div
                  className="profile-img"
                  style={{ backgroundImage: ` url(${profile_pic})` }}
                ></div>
                {/* <img src={profile_pic} alt="" /> */}
              </div>
              <div className="artist-detail">
                <div className="name">May</div>
                <div className="shop-name">Shop name: May art gallery</div>
                <div className="artist-review">
                  <div className="like-count count">
                    <strong>786.6k</strong>
                    <span>Like</span>
                  </div>
                  <div className="arts-count count">
                    <strong>786.6k</strong>
                    <span>Arts</span>
                  </div>
                  <div className="visitor-count count">
                    <strong>786.6k</strong>
                    <span>Visitor</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="button-wrapper">
              <button className="btn-primary">Visit shop</button>
            </div>
          </div>
        </div>
        <div className="right-column">
          <div className="title">More from artists</div>
          <div className="slider-wrapper">
            {products.map((product) => (
              <ProductCard item={product} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreFromArtist;
