import React, { useState } from "react";
import "./SellerDashbordSidebar.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { RiShoppingBagLine } from "react-icons/ri";
import { AiOutlineShop } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { BiSupport } from "react-icons/bi";
import { ReactComponent as Dashboard } from "../../../../../assets/svgs/element-2 1.svg";
import { ReactComponent as ManageArt } from "../../../../../assets/svgs/box-1 1 (1).svg";
import { ReactComponent as Memberships } from "../../../../../assets/svgs/mdi_badge-outline.svg";
import { ReactComponent as Payout } from "../../../../../assets/svgs/uiw_pay.svg";
import { ReactComponent as ArtCategory } from "../../../../../assets/svgs/carbon_expand-categories.svg";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import logo from "../../../../../assets/images/black-logo.png";
import { BiLogOutCircle } from "react-icons/bi";
import { useStateValue } from "../../../../../StateProvider";
const options = [
  { url: "/home", icon: Dashboard, title: "Dashboard",pageName:"Dashboard" },
  { url: "/manage-artworks", icon: ManageArt, title: "Manage Artworks",pageName:"ArtWorkList" },
  { url: "/membership", icon: Memberships, title: "Memberships" ,pageName:"Memberships"},
  { url: "/orders", icon: RiShoppingBagLine, title: "Orders",pageName:"OrderList" },
  // { url: "/shop", icon: AiOutlineShop, title: "Shop" },
  { url: "/earnings-payouts", icon: Payout, title: "Earnings and Payouts",pageName:"Earning" },
  { url: "/profile-settings", icon: CgProfile, title: "Profile Settings",pageName:"ProfileInfo" },
  {
    url: "/artwork-categories",
    icon: ArtCategory,
    title: "Artwork Categories",
    pageName:"ArtCategory"
  },
  {
    url: "/support",
    icon: BiSupport,
    title: "Support and Help Center",
    pageName:"SupportFAQ",
  },
];

const SellerDashbordSidebar = ({setPage}) => {
  const { pathname } = useLocation();
  const [{ userDetail, userLoggedIn }, dispatch] = useStateValue();

  // const [page, setPage] = useState("support-ticket");

  const navigate = useNavigate();
  const doLogout = () => {
    sessionStorage.clear();
    localStorage.clear()
    dispatch({ type: "SET_LOGIN_STATUS", status: false });
    dispatch({ type: "SET_USER_DETAIL", detail: {} });
    navigate("/");
  };
  return (
    <div className="sidebar">
      <div className="sidebar-options" data-aos="fade-right">
        <div className="option-inner-wrapper">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <div className="chat btn-secondary">
            <IoChatbubbleEllipsesOutline size={20} /> Chats
          </div>
          {options.map((option, i) => (
            <div
              onClick={() => {
                navigate(`/seller/dashboard${option.url}`);
                setPage(option.pageName)
              }}
              className={`option ${pathname === `/seller/dashboard${option.url}` ? "active":""}`}
              key={i}
            >
              {option.icon && <option.icon className="icon" size={20} />}
              {option.title}
            </div>
          ))}
        </div>
        <div className="logout-support">
          <div className="logout" onClick={doLogout}>
            <BiLogOutCircle size={20} />
            Log Out
          </div>
          <button className="btn-primary" onClick={() => {
                navigate(`/seller/dashboard/support-ticket`);
                setPage("SupportTicket")
              }}>Support</button>
        </div>
      </div>
    </div>
  );
};

export default SellerDashbordSidebar;
