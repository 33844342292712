import axios from "axios";
import { domainName, api, sellerApi } from "./Constants";
import { request } from "./utils/axios-utils";

const token = sessionStorage.getItem("token");

// sign up
export const signup = (data) => {
  return axios.post(`${domainName}${api.signup}`, data);
};

// check whether the email is available or not
export const checkEmailAvailability = (data) => {
  return axios.post(`${domainName}${api.emailAvailability}`, data);
};

// login
export const login = (data) => {
  return axios.post(`${domainName}${api.login}`, data);
};

// get user details
export const fetchUser = () => {
  return request({ url: `${domainName}${api.profile}`, method: "get" });
};

// update user details
export const updateUser = (data) => {
  return request({ url: `${domainName}${api.profile}`, method: "put", data });
};

// update user details
export const updateProfileImg = (data) => {
  return request({
    url: `${domainName}${api.updateProfileImg}`,
    method: "post",
    data,
  });
};

// get all products
export const fetchAllProducts = () => {
  return axios.get(`${domainName}${api.allProducts}`);
};

// get categories
export const categoriesList = () => {
  return axios.get(`${domainName}${api.categories}`);
};

// get recent products
export const fetchRecentProducts = () => {
  return axios.get(`${domainName}${api.recentProducts}`);
};

// get popular products
export const fetchPopularProducts = () => {
  return axios.get(`${domainName}${api.popularProducts}`);
};

// get product info
export const fetchProductInfo = ({ queryKey }) => {
  return axios.get(`${domainName}${api.productInfo}${queryKey[1]}`);
};

// get cart items
export const getCart = () => {
  return request({ url: `${domainName}${api.cart}`, method: "get" });
};

// add item to cart
export const addToCart = (data) => {
  return request({
    url: `${domainName}${api.addToCart}`,
    method: "post",
    data,
  });
};

// add item to cart
export const updateQuantity = (data) => {
  return request({
    url: `${domainName}${api.updateQuantity}`,
    method: "put",
    data,
  });
};

// delete from cart
export const deleteFromCart = (productId) => {
  return request({
    url: `${domainName}${api.deleteFromCart}${productId}`,
    method: "delete",
  });
};

// get user addresses
export const getAddresses = () => {
  return request({ url: `${domainName}${api.getAddresses}`, method: "get" });
};

// add address
export const addAddress = (data) => {
  return request({
    url: `${domainName}${api.addAddress}`,
    method: "post",
    data,
  });
};

// delete address
export const deleteAddress = (addressId) => {
  return request({
    url: `${domainName}${api.deleteAddress}${addressId}`,
    method: "delete",
  });
};

// update address
export const updateAddress = (data) => {
  return request({
    url: `${domainName}${api.updateAddress}`,
    method: "put",
    data,
  });
};

// payment
export const doPayment = (data) => {
  return request({
    url: `${domainName}${api.payment}?amount=${data}`,
    method: "post",
  });
};
// payment
export const membershipPayment = (data) => {
  return request({
    url: `${domainName}${api.membershipPayment}?planId=${data}`,
    method: "post",
  });
};

// create order
export const createOrder = (data) => {
  return request({
    url: `${domainName}${api.createOrder}`,
    method: "post",
    data,
  });
};

// get all orders
export const getOrders = () => {
  return request({ url: `${domainName}${api.getOrders}` });
};

// cancel order
export const cancelOrder = (data) => {
  return request({
    url: `${domainName}${api.cancelOrder}`,
    method: "post",
    data,
  });
};

// return order
export const returnOrder = (data) => {
  return request({
    url: `${domainName}${api.returnOrder}`,
    method: "post",
    data,
  });
};

// get favorouites
export const getFavorites = () => {
  return request({ url: `${domainName}${api.getFavorites}` });
};

// add to favorites
export const addToFavorites = (productId) => {
  return request({
    url: `${domainName}${api.addFavorite}${productId}`,
    method: "post",
  });
};

// remove from favorites
export const removeFromFavorites = (productId) => {
  return request({
    url: `${domainName}${api.removeFavorite}${productId}`,
    method: "delete",
  });
};

// verify google token
export const googleLogin = (googleToken) => {
  return axios.post(`${domainName}${api.verifyGoogleToken}`, {
    access_token: googleToken,
  });
};

// get notification details
export const getNotificationDetails = () => {
  return request({ url: `${domainName}${api.notifications}` });
};

// change notification status
export const changeNotificationStatus = (data) => {
  return request({
    url: `${domainName}${api.notifications}`,
    method: "post",
    data,
  });
};

// get all reviews
export const getReviews = (productId) => {
  console.log(productId, "productId");
  return axios.get(`${domainName}${api.getReviews}${productId}`);
};

// add review
export const addReview = (data) => {
  return request({
    url: `${domainName}${api.addReview}`,
    method: "post",
    data,
  });
};

// edit review
export const editReview = (data) => {
  return request({
    url: `${domainName}${api.editReview}`,
    method: "put",
    data,
  });
};

// contact us
export const contact = (data) => {
  return axios.post(`${domainName}${api.contactUs}`, data);
};
export const consultancy = (data) => {
  return axios.post(`${domainName}${api.consultancy}`, data);
};

// **************************************************************************************
// ************************************************** seller ****************************
// **************************************************************************************

// **************************************************seller signup ****************************
export const sellerSignup = (data) => {
  console.log("CRETE< DATA", data)
  return request({ url: sellerApi.sellerSignup, method: "post", data: data });
};
// **************************************************seller pan detail ****************************
export const sellerPanDetail = (data, Pancard) => {
  return request({
    url: `${sellerApi.sellerPanDetail}?panCard=${Pancard}`,
    method: "patch",
    data: data,
  });
};
// **************************************************seller pan detail ****************************
export const sellerStore = (logo, data) => {
  return request({
    url: `${sellerApi.sellerStore}?storeName=${data.name}&storeUrl=${data.storeLink}&description=${data.description}&address=${data.address}`,
    method: "post",
    data: logo,
  });
};
// ************************************************** get seller detail ****************************
export const sellerDetail = (data) => {
  return request({ url: sellerApi.sellerDetail, method: "get" });
};
// ************************************************** get seller detail ****************************
export const checkStoreNameAvailable = (store_name) => {
  return request({
    url: `${sellerApi.checkStoreNameAvailable}?store_name=${store_name}`,
    method: "post",
  });
};
// ************************************************** add seller product ****************************
export const addSellerProduct = (data) => {
  return request({
    url: `${sellerApi.addProduct}`,
    method: "post",
    data: data,
  });
};
// ************************************************** add seller product ****************************
export const editSellerProduct = (data, id) => {
  return request({
    url: `${sellerApi.addProduct}/${id}`,
    method: "put",
    data: data,
  });
};

export async function uploadSellerProductImages(data, id) {
  return request({
    url: sellerApi.addProductImage + "/" + id,
    method: "post",
    data: data,
  });
}
// ************************************************** get seller products ****************************
export const getSellerProduct = (category, status) => {
  return request({
    url: `${sellerApi.addProduct}?category=${category}&status=${status}`,
    method: "get",
  });
};
// ************************************************** get seller product details ****************************
export const getSellerProductDetails = (id) => {
  return request({ url: `${sellerApi.productDetails}/${id}`, method: "get" });
};
// ************************************************** delete seller product details ****************************
export const deleteSellerProduct = (id) => {
  return request({ url: `${sellerApi.addProduct}/${id}`, method: "delete" });
};
// ************************************************** get all seller product ****************************
export const fetchAllSellerProducts = (storename) => {
  const session_access_token = sessionStorage.getItem("token");
  const local_access_token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${
      session_access_token ? session_access_token : local_access_token
    }`,
    "Session-Id": sessionStorage.getItem("Session-Id"),
  };
  return axios.get(`${domainName}${sellerApi.allProducts}/${storename}`, {
    headers,
  });
};
export const fetchHomepageProducts = () => {
  return axios.get(`${domainName}${sellerApi.homePageProducts}`);
};
// ************************************************** get seller product info ****************************
export const fetchSellerProductInfo = ({ queryKey }) => {
  const session_access_token = sessionStorage.getItem("token");
  const local_access_token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${
      session_access_token ? session_access_token : local_access_token
    }`,
    "Session-Id": sessionStorage.getItem("Session-Id"),
  };
  return axios.get(`${domainName}${sellerApi.productInfo}${queryKey[1]}`, {
    headers,
  });
};

// add seller item to cart
export const addSellerProductToCart = (data) => {
  return request({
    url: `${domainName}${sellerApi.addToCart}`,
    method: "post",
    data,
  });
};

// get cart items
export const getSellerCart = () => {
  return request({ url: `${domainName}${sellerApi.cart}`, method: "get" });
};
// get user addresses
export const getSellerAddresses = () => {
  return request({
    url: `${domainName}${sellerApi.getAddresses}`,
    method: "get",
  });
};
// delete address
export const deleteSellerAddress = (addressId) => {
  return request({
    url: `${domainName}${sellerApi.deleteAddress}${addressId}`,
    method: "delete",
  });
};
// update address
export const updateSellerAddress = (data) => {
  return request({
    url: `${domainName}${sellerApi.updateAddress}`,
    method: "put",
    data,
  });
};
// delete from cart
export const deleteSellerFromCart = (productId) => {
  return request({
    url: `${domainName}${sellerApi.deleteFromCart}${productId}`,
    method: "delete",
  });
};
// add item to cart
export const updateSellerQuantity = (data) => {
  return request({
    url: `${domainName}${sellerApi.updateQuantity}`,
    method: "put",
    data,
  });
};
// add address
export const addSellerAddress = (data) => {
  return request({
    url: `${domainName}${sellerApi.addAddress}`,
    method: "post",
    data,
  });
};
// create order
export const createSellerOrder = (data) => {
  return request({
    url: `${domainName}${sellerApi.createOrder}`,
    method: "post",
    data,
  });
};
// cancel order
export const cancelSellerOrder = (data) => {
  return request({
    url: `${domainName}${sellerApi.cancelOrder}`,
    method: "post",
    data,
  });
};
// get all orders
export const getSellerOrders = (status) => {
  return request({
    url: `${domainName}${sellerApi.getOrders}?status=${"All"}`,
  });
};
// get all seller user orders
export const getSelleruserOrders = (status) => {
  return request({
    url: `${domainName}${sellerApi.getSellerOrders}?status=${"All"}`,
  });
};
// return order
export const returnSellerOrder = (data) => {
  return request({
    url: `${domainName}${sellerApi.returnOrder}`,
    method: "post",
    data,
  });
};
// get favorouites
export const getSellerFavorites = () => {
  return request({ url: `${domainName}${sellerApi.getFavorites}` });
};

// add to favorites
export const addToSellerFavorites = (productId) => {
  return request({
    url: `${domainName}${sellerApi.addFavorite}${productId}`,
    method: "post",
  });
};

// remove from favorites
export const removeFromSellerFavorites = (productId) => {
  return request({
    url: `${domainName}${sellerApi.removeFavorite}${productId}`,
    method: "delete",
  });
};

// ************************************************************** dashboard ******************************************************
export const getDashboard = () => {
  return request({ url: `${sellerApi.getDashboard}`, method: "get" });
};
// ************************************************************** get Return Request ******************************************************
export const getReturnRequest = (page) => {
  return request({
    url: `${sellerApi.getReturnRequest}?page=${page}`,
    method: "get",
  });
};
// ************************************************************** get Recent Order ******************************************************
export const getRecentOrder = () => {
  return request({
    url: `${sellerApi.getRecentOrder}`,
    method: "get",
  });
};

// **************************************************************  Return Request ******************************************************
export const returnOrderRequest = (data) => {
  return request({
    url: `${domainName}${sellerApi.returnOrderRequest}`,
    method: "post",
    data,
  });
};
// **************************************************************  order Status ******************************************************
export const orderStatus = (data) => {
  return request({
    url: `${domainName}${sellerApi.orderStatus}`,
    method: "post",
    data,
  });
};
// **************************************************************  updateSellerInfo ******************************************************
export const updateSellerInfo = (data) => {
  return request({
    url: `${domainName}${sellerApi.updateSellerInfo}`,
    method: "patch",
    data,
  });
};
// **************************************************************  updateSellerStoreInfo ******************************************************
export const updateSellerStoreInfo = (data) => {
  return request({
    url: `${domainName}${sellerApi.updateSellerStoreInfo}`,
    method: "patch",
    data,
  });
};

// **************************************************************  get Seller Profile ******************************************************
export const getSellerProfile = () => {
  return request({
    url: `${sellerApi.getSellerProfile}`,
    method: "get",
  });
};

export const updateSellerStoreLogo = (data) => {
  return request({
    url: `${domainName}${sellerApi.updateSellerStoreLogo}`,
    method: "patch",
    data,
  });
};
export const updateNotificationSetting = (data) => {
  return request({
    url: `${domainName}${sellerApi.notificationSetting}`,
    method: "patch",
    data,
  });
};
export const createSupportTicket = (data) => {
  return request({
    url: `${domainName}${sellerApi.supportTicket}`,
    method: "post",
    data,
  });
};
export const getSupportTicket = () => {
  return request({
    url: `${domainName}${sellerApi.supportTicket}`,
    method: "get",
  });
};
export const getSellerFaq = () => {
  return request({
    url: `${domainName}${sellerApi.getSellerFaq}`,
    method: "get",
  });
};
export const getCustomerMemberShipPlan = () => {
  return request({
    url: `${domainName}${api.getCustomerMemberShipPlan}`,
    method: "get",
  });
};

export const getSellerMemberShipPlan = () => {
  return request({
    url: `${domainName}${sellerApi.getSellerMemberShipPlan}`,
    method: "get",
  });
};
export const activeMembership = (data) => {
  return request({
    url: `${domainName}${api.activeMembership}`,
    method: "post",
    data: data,
  });
};
export const CalculateDiscount = (pincode = 0) => {
  return request({
    url: `${domainName}${api.calculateDiscount}?delideliveryPincode=${pincode}`,
    method: "get",
  });
};
// get categories
export const getSellerCategories = () => {
  return request({
    url: `${domainName}${sellerApi.sellerCategories}`,
    method: "get",
  });
};
// add categories
export const addSellerCategories = (data) => {
  return request({
    url: `${domainName}${sellerApi.sellerCategories}`,
    method: "post",
    data: data,
  });
};
// update categories
export const upadteSellerCategories = (id, data) => {
  return request({
    url: `${domainName}${sellerApi.sellerCategories}/${id}`,
    method: "patch",
    data: data,
  });
};
// remove categories
export const removeSellerCategories = (id) => {
  return request({
    url: `${domainName}${sellerApi.sellerCategories}/${id}`,
    method: "delete",
  });
};

// ***************************************************  get client details ****************************************************************
export async function SendOtp(data) {
  return request({
    url: `${domainName}${api.otp}`,
    method: "post",
    data: data,
  });
}

export const VerifyOtp = (token, otp) => {
  console.log(token, otp);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Session-Id": sessionStorage.getItem("Session-Id"),
  };
  return axios.get(`${domainName}${api.verify_otp}/${otp}`, {
    headers,
  });
};

// ***************************************************  send otp  ****************************************************************
export async function sendPhoneOtp(data) {
  return request({ url: `${domainName}${api.send_otp}`, method: "post", data: data });
}
// ***************************************************  verify otp  ****************************************************************
export async function verifyPhoneOtp(data) {
  return request({ url: `${domainName}${api.verify_otp}`, method: "post", data: data });
}

// ***************************************************  forgot password  ****************************************************************
export async function ResetPassword(data) {
  return request({
    url: `${domainName}${api.reset_password}`,
    method: "post",
    data: data,
  });
}
// ***************************************************  get terms of use ****************************************************************
export async function GetPrivacyPolicy() {
  return request({ url: api.privacy, method: "get" });
}
// ***************************************************  get terms of use ****************************************************************
export async function GetTermsOfCondition() {
  return request({ url: api.terms, method: "get" });
}
