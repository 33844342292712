import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import {
  addSellerCategories,
  categoriesList,
  getSellerCategories,
} from "../../../../../../../../../apiCall";
import { useStateValue } from "../../../../../../../../../StateProvider";
import toast from "react-hot-toast";

const Details = ({ setActiveStep, onCancel, setMyData, myData }) => {
  const [, dispatch] = useStateValue();

  const [addNewCategory, setAddNewCategory] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    console.log(data.dimensions.toLowerCase().split("x"));
    if (data.dimensions.toLowerCase().split("x").length !== 3) {
      console.log("INSIDE", errors);
      setError("dimensions", {
        type: "custom",
        message: 'Dimensions must be in formate "40 x 50 x 70"',
      });
      return;
    }
    console.log(data);
    if (addNewCategory) {
      dispatch({ type: "SET_LOADING", status: true });
      try {
        const response = await addSellerCategories({ title: data.title });
        if (response.data.status[0].Error === "False") {
        //   toast.success("Category Added successfully");
          setMyData((pre) => {
            return {
              ...pre,
              price: data.price,
              current_stock: data.stock,
              category_id: response.data.value.id,
              weight: data.weight,
              dimensions: data.dimensions,
              description: data.description,
            };
          });
        } else {
          toast(response.data.status[0].ResponseMessage, { icon: "⚠️" });
        }
      } catch (err) {
        toast.error(err.message);
      }
      dispatch({ type: "SET_LOADING", status: false });
    } else {
      setMyData((pre) => {
        return {
          ...pre,
          price: data.price,
          current_stock: data.stock,
          category_id: data.category,
          weight: data.weight,
          dimensions: data.dimensions,
          description: data.description,
        };
      });
    }
    setActiveStep(2);
  };

  useEffect(() => {
    reset({
      price: myData.price,
      stock: myData.current_stock,
      category: myData.category,
      weight: myData.weight,
      dimensions: myData.dimensions,
      description: myData.description,
    });
  }, [myData]);
  const { data: categories } = useQuery(
    ["seller-categories"],
    getSellerCategories,
    {
      onSuccess: (data) => {},
      onError: (e) => {
        // console.log(e)
      },
    }
  );
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="input-container">
        <div className="row">
          <div className="input-wrapper">
            {/* <label>Title of Artwork/Service 10/10</label> */}
            <div className="input-inner-wrapper">
              <input
                type="number"
                placeholder="Add Price."
                {...register("price", {
                  required: "Price is required.",
                  min: {
                    value: 1,
                    message: "Price should be greater then 1$.",
                  },
                })}
              />
              <span>$</span>
            </div>
            {errors.price && (
              <div className="error">{errors.price.message}</div>
            )}
          </div>
          <div className="input-wrapper">
            {/* <label>Title of Artwork/Service 10/10</label> */}
            <input
              type="text"
              placeholder="Add Stock."
              {...register("stock", {
                required: "Stock is required.",
              })}
            />
            {errors.stock && (
              <div className="error">{errors.stock.message}</div>
            )}
          </div>
        </div>
        <div className="row">
          {addNewCategory ? (
            <div className="input-wrapper">
              {/* <label>Title of Artwork/Service 10/10</label> */}
              <input
                type="text"
                placeholder="Add Category."
                {...register("title", {
                  required: "Category is required.",
                })}
              />
              {errors.title && (
                <div className="error">{errors.title.message}</div>
              )}
            </div>
          ) : (
            <div className="input-wrapper">
              <select
                name=""
                id=""
                {...register("category", {
                  required: "Category is required.",
                  onChange: (data) => {
                    if (data.target.value === "other") {
                      setAddNewCategory(true);
                    }
                  },
                })}
              >
                <option disabled>Category</option>
                {categories?.data?.value.map((item) => (
                  <option key={item["title"] + item["id"]} value={item["id"]}>
                    {item["title"]}
                  </option>
                ))}
                <option key="other" value="other">
                  Other
                </option>
              </select>
              {errors.category && (
                <div className="error">{errors.category.message}</div>
              )}
            </div>
          )}
          <div className="input-wrapper">
            <div className="input-inner-wrapper">
              <input
                type="number"
                placeholder="Weight example: 10"
                {...register("weight", {
                  required: "weight is required.",
                })}
              />
              <span>KG</span>
            </div>
            {errors.weight && (
              <div className="error">{errors.weight.message}</div>
            )}
          </div>
          <div className="input-wrapper">
            {/* <label>Title of Artwork/Service 10/10</label> */}
            <div className="input-inner-wrapper">
              <input
                type="text"
                placeholder="Dimensions example: 40 × 50 × 70"
                {...register("dimensions", {
                  required: "dimensions is required.",
                })}
              />
              <span>CM</span>
            </div>
            {errors.dimensions && (
              <div className="error">{errors.dimensions.message}</div>
            )}
          </div>
        </div>

        <div className="input-wrapper">
          <label>Additional Information</label>
          <textarea
            rows={2}
            type="text"
            placeholder="A calming painting capturing the beauty of a sunset over the ocean."
            {...register("description", {
              required: "Additional Info is required.",
              maxLength: {
                value: 500,
                message: "Additional Info should be less then 500 characters.",
              },
            })}
          />
          {errors.description && (
            <div className="error">{errors.description.message}</div>
          )}
        </div>
        <div className="btn-wrapper">
          <div className="cancel-btn btn-secondary" onClick={() => onCancel()}>
            Cancel
          </div>
          <button className="btn-primary" type="submit">
            Save and Next
          </button>
        </div>
      </div>
    </form>
  );
};

export default Details;
