import React, { useEffect } from "react";
import "./MembershipSuccessModal.scss";
import { Modal } from "react-bootstrap";
import OrderSuccessImg from "../../../assets/images/green-bg-success.png";
import { useStateValue } from "../../../StateProvider";
import { useQueryClient } from "@tanstack/react-query";

const MembershipSuccessModal = (props) => {
  const[{userDetail},dispatch] =useStateValue();
  const queryClient = useQueryClient();

  useEffect(()=>{
    queryClient.invalidateQueries('user-data');
  },[])
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="checkout-modal">
        <img src={OrderSuccessImg} alt="" />
        <h4>Membership Plan Activated Successfully.</h4>
        <p>Confirmation will be sent to your email.</p>
      </Modal.Body>
    </Modal>
  );
};

export default MembershipSuccessModal;
