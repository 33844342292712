import { LuLayoutDashboard, LuSettings } from "react-icons/lu";
import { BsHandbag, BsSuitHeart } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import { RiLogoutBoxLine } from "react-icons/ri";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useStateValue } from "../../../../../StateProvider";
import { ReactComponent as ManageArt } from "../../../../../assets/svgs/box-1 1 (1).svg";


export const ProfileOptions = () => {
  const [, dispatch] = useStateValue();
  const navigate = useNavigate();

  // Do user logout
  const doLogout = () => {
    sessionStorage.clear();
    localStorage.clear()
    dispatch({ type: "SET_LOGIN_STATUS", status: false });
    navigate("/");
  };
  return (
    <div className="profile-options-bg">
      <div className="profile-options">
        {/* <Link className="underline-none" to={`/dashboard/${"dashboard"}`}> */}
          {/* <div className="option">
            <LuLayoutDashboard className="icon" />
            Dashboard
          </div> */}
        {/* </Link> */}
        <Link className="underline-none" to={"/seller/dashboard/orders"}>
          <div className="option">
            <BsHandbag className="icon" />
            My Orders
          </div>
        </Link>
        <Link className="underline-none" to={"/seller/dashboard/manage-artworks"}>
          <div className="option">
            <ManageArt className="icon" />
            Manage Art
          </div>
        </Link>
        <Link className="underline-none" to={"/seller/dashboard/profile-settings"}>
          <div className="option">
            <FaRegEdit className="icon" />
            Edit Profile
          </div>
        </Link>
        {/* <Link className="underline-none" to={storename ? `/seller/${storename}` : `/${"settings"}`}>
          <div className="option">
            <LuSettings className="icon" />
            Settings
          </div>
        </Link> */}
        <div className="option" onClick={doLogout}>
          <RiLogoutBoxLine className="icon" />
          Log Out
        </div>
      </div>
    </div>
  );
};
