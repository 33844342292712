import { Modal } from "react-bootstrap";
import { domainName } from "../../../Constants";
import { IoCloseSharp } from "react-icons/io5";
import "./OrderDetailModal.scss";
import { returnOrderRequest } from "../../../apiCall";
import toast from "react-hot-toast";
function formatDate(inputDate) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const dateObj = new Date(inputDate);

  const formattedDate = dateObj.toLocaleDateString("en-US", options);

  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const period = hours >= 12 ? "PM" : "AM";
  const formattedTime = `${hours % 12}:${minutes
    .toString()
    .padStart(2, "0")} ${period}`;

  return `${formattedDate}, ${formattedTime}`;
}

export const ReturnOrderDetailModal = ({
  order,
  orderCancellationDeatil,
  handelRequestAction,
  ...props
}) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="invoice-header-offcanvas">
        <span className="h5">order summary</span>
        <IoCloseSharp className="icon" onClick={() => props.onHide()} />
      </div>
      {/* <div className="underline-invoice-header"></div> */}
      <Modal.Body className="invoice-content">
        <span className="fw-500 order-id">Order ID : #{order?.order_id}</span>
        <span className="title">
          Order Placed : {formatDate(order?.order_details?.create_at)}
        </span>
        <span className="title">Shipping address</span>
        <div className="address">
          <span>{order?.order_details?.name}</span>
          <span style={{ textTransform: "uppercase" }}>
            {order?.order_details?.address}
          </span>
          <span>{order?.order_details?.state}</span>
        </div>
        <div className="deliver-date">
          Order delivered: {formatDate(order?.order_details?.update_at)}
        </div>
        {order?.product ? (
          <>
            {order.product.map((item) => (
              <div className="product-details">
                <img
                  src={`${domainName}/uploads/${item.productDetail.main_image}`}
                  alt="product"
                />
                <div>
                  <span className="name">
                    {item.productDetail.title} <br />{" "}
                    <span>{item.productDetail.category__title}</span>
                  </span>
                  <span className="quantity">Qyt:{item.quantity}</span>
                  <span className="price">
                    ₹{item.productDetail.price * item.quantity}
                  </span>
                </div>
              </div>
            ))}
          </>
        ) : (
          ""
        )}
        <div className="order-summary">
          {/* <span className="h5">Order Summary</span> */}
          <div className="underline-invoice-header"></div>
          <div className="flex-between sub-total">
            <span>Subtotal</span>
            <span>₹{order?.order_details?.total_amount}</span>
          </div>
          <div className="flex-between">
            <span>Shipping</span>
            <span>-----</span>
          </div>
          <div className="underline-invoice-header"></div>
          <div className="flex-between total">
            <div className="h5">
              Total <span>Monthly payment</span>{" "}
            </div>
            <div className="h5 amount">
              ₹{order?.order_details?.total_amount}{" "}
              <span>₹6045/month with EMI</span>
            </div>
          </div>
          <div className="order-cancellation-detail">
            <div className="title">
              Order Cancellation: Request #{order.return_id}
            </div>
            <div className="reason">Reason: {order.reason}</div>
            <div className="action-btn">
              <button
                className="btn-secondary"
                onClick={() =>
                  handelRequestAction({
                    returnRequestId: order.return_id,
                    status: "Rejected",
                  })
                }
              >
                Reject
              </button>
              <button
                className="btn-primary"
                onClick={() =>
                  handelRequestAction({
                    returnRequestId: order.return_id,
                    status: "Initiated",
                  })
                }
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export const OrderDetailModal = ({
  order,
  orderCancellationDeatil,
  ...props
}) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="invoice-header-offcanvas">
        <span className="h5">order summary</span>
        <IoCloseSharp className="icon" onClick={() => props.onHide()} />
      </div>
      {/* <div className="underline-invoice-header"></div> */}
      <Modal.Body className="invoice-content">
        <span className="fw-500 order-id">Order ID : #{order?.id}</span>
        <span className="title">
          Order Placed : {formatDate(order?.create_at)}
        </span>
        <span className="title">Shipping address</span>
        <div className="address">
          <span>{order?.name}</span>
          <span style={{ textTransform: "uppercase" }}>
            {order?.address}
          </span>
          <span>{order?.state}</span>
        </div>
        <div className="deliver-date">
          Order delivered: {formatDate(order?.update_at)}
        </div>
        {order?.product ? (
          <>
            {order.product.map((item) => (
              <div className="product-details">
                <img
                  src={`${domainName}/uploads/${item.productDetail.main_image}`}
                  alt="product"
                />
                <div>
                  <span className="name">
                    {item.productDetail.title} <br />{" "}
                    <span>{item.productDetail.category__title}</span>
                  </span>
                  <span className="quantity">Qyt:{item.quantity}</span>
                  <span className="price">
                    ₹{item.productDetail.price * item.quantity}
                  </span>
                </div>
              </div>
            ))}
          </>
        ) : (
          ""
        )}
        <div className="order-summary">
          {/* <span className="h5">Order Summary</span> */}
          <div className="underline-invoice-header"></div>
          <div className="flex-between sub-total">
            <span>Subtotal</span>
            <span>₹{order?.total_amount}</span>
          </div>
          <div className="flex-between">
            <span>Shipping</span>
            <span>-----</span>
          </div>
          <div className="underline-invoice-header"></div>
          <div className="flex-between total">
            <div className="h5">
              Total <span>Monthly payment</span>{" "}
            </div>
            <div className="h5 amount">
              ₹{order?.total_amount}{" "}
              <span>₹6045/month with EMI</span>
            </div>
          </div>
          
        </div>
      </Modal.Body>
    </Modal>
  );
};
