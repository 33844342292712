import React, { useEffect } from "react";
import "./CategoryModal.scss";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { useStateValue } from "../../../StateProvider";
import { contact, upadteSellerCategories } from "../../../apiCall";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
const UpdateCategoryModal = (props) => {
  const [, dispatch] = useStateValue();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
    },
  });
  const onSubmit = async (values) => {
    if (values.title === props.editDeteleCategory.title) {
      reset();
      props.onHide();
    } else {
      dispatch({ type: "SET_LOADING", status: true });
      try {
        const response = await upadteSellerCategories(
          props.editDeteleCategory.id,
          values
        );
        if (response.data.status[0].Error === "False") {
          toast.success("Category Updated successfully");
          reset();
          props.refetch();
          props.onHide();
        } else {
          toast(response.data.status[0].ResponseMessage, { icon: "⚠️" });
        }
      } catch (err) {
        toast.error(err.message);
      }
      dispatch({ type: "SET_LOADING", status: false });
    }
  };
  useEffect(() => {
    console.log(props);
    setValue("title", props.editDeteleCategory.title);
  }, [props]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="category-modals"
    >
      <Modal.Body className="category-modal">
        <div className="close">
          <IoClose size={20} onClick={props.onHide} />
        </div>
        <h3>Edit Category</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-box">
            <label htmlFor="">Category Name</label>
            <input
              type="text"
              placeholder="Enter Category name"
              {...register("title", {
                required: "This field is required",
              })}
            />
            <small className="error">{errors.title?.message}</small>
          </div>

          <div className="buttons">
            <button
              onClick={() => {
                props.onHide();
              }}
              className="btn-secondary"
            >
              Cancel
            </button>
            <button className="btn-primary">Save Changes</button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateCategoryModal;
