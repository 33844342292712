import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  sellerSignup,
  sendPhoneOtp,
  updateProfileImg,
  updateSellerInfo,
  verifyPhoneOtp,
} from "../../../../../../../apiCall";
import { useStateValue } from "../../../../../../../StateProvider";
import {
  GetCountries,
  GetState,
  GetCity,
  GetLanguages, //async functions
} from "react-country-state-city";
import { domainName } from "../../../../../../../Constants";
import { QueryClient } from "@tanstack/react-query";

const AccountInfo = ({ profileDetail, refetch, setProfile }) => {
  const [, dispatch] = useStateValue();

  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();

  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const imgRef = useRef();
  const [profilePic, setProfilePic] = useState();
  const [isOtpSent, setIsOtpSent] = useState(false)
  const [token, setToken] = useState()

  useEffect(() => {
    GetCountries().then((result) => {
      setCountriesList(result);
    });
  }, []);
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: profileDetail?.name,
      gender: profileDetail?.gender,
      phoneNumber: profileDetail?.phone_number,
      email: profileDetail?.email,
      country: profileDetail?.country,
      city: profileDetail?.city,
      state: profileDetail?.state,
      bio: profileDetail?.bio,
      sellingType: profileDetail?.sellingType,
    },
  });
  const onSubmit = async (data) => {
    console.log(token)
    if(getValues("phoneNumber") !== profileDetail?.phone_number && !token){
      setError("phoneNumber", {type: "custom", message: "Please verify phone number first."})
      return;
    }
    const Re = await updateSellerInfo(data);
    if (Re?.status === 200) {
      toast.success("infomation updated successfully");
      sessionStorage.setItem("token", Re.data.access_token);
      setToken("")
    } else {
      toast.error(Re?.response.data.status[0].ResponseMessage);
    }
    refetch();
    // }
  };
  const [gender, setGender] = useState("male");
  const [sellingType, setSellingType] = useState("art");
  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };
  useEffect(() => {
    const setData = () => {
      setValue("name", profileDetail?.name);
      setValue("gender", profileDetail?.gender);
      setValue("phoneNumber", profileDetail?.phone_number);
      setValue("email", profileDetail?.email);
      setValue("country", profileDetail?.country);
      setValue("city", profileDetail?.city);
      setValue("state", profileDetail?.state);
      setValue("bio", profileDetail?.bio);
      setValue("sellingType", profileDetail?.sellingType);

      GetCountries().then((result) => {
        setCountriesList(result);
        setCountryDetail();
      });
      const setCountryDetail = () => {
        console.log(countriesList);
        const country = countriesList.find(
          (item) =>
            item.name.toLowerCase() === profileDetail?.country.toLowerCase()
        );
        setCountry(country);
        setValue("country", country?.name);
        GetState(country?.id).then((result) => {
          setStateList(result);
          setStateDetail(result);
        });
      };
      const setStateDetail = (result) => {
        const state = result.find(
          (item) =>
            item.name.toLowerCase() === profileDetail?.state.toLowerCase()
        ); //here you will get full state object.

        setState(state);
        setValue("state", state?.name);
        console.log(country?.id, state?.id);
        GetCity(country?.id, state?.id).then((result) => {
          console.log(result);
          setCityList(pre => result);
          setcityDetail(pre => result);
        });
      };
      const setcityDetail = (result) => {
        const city = result.find(
          (item) =>
            item.name.toLowerCase() === profileDetail?.city.toLowerCase()
        ); //here you will get full state object.

        setCity(city);
        setValue("city", city?.name);
      };
    };
    setData();
  }, [profileDetail]);

  const onImageSelect = async (e) => {
    const fData = new FormData();
    fData.append("profile_img", e.target.files[0]);
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (
        file.type === "image/x-png" ||
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      ) {
        try {
          const response = await updateProfileImg(fData);
          console.log(response)
          if (response.data?.status[0].Error === "False") {
            setProfilePic({ url: URL.createObjectURL(file) });
            setProfile(domainName + response?.data?.user?.profile_image)
            // QueryClient.invalidateQueries("user-data");
            toast.success(response.data?.status[0].Message);
          } else {
            toast(response.data?.status[0].ResponseMessage, { icon: "⚠️" });
          }
        } catch (err) {
          toast.error(err.message);
        }
      } else {
        return toast("Select an image file", {
          icon: "❌",
          position: "top-center",
          style: {
            borderRadius: "10px",
          },
        });
      }
    }
  };

  const sendVerificationOtp = async (e) => {
    e.preventDefault()
    console.log(getValues("phoneNumber"), "HELLO")
    if (!getValues("phoneNumber")) {
        setError("phoneNumber", {type: "custom", message: "Phone Number is required"})
        return;
      }else if(!String(getValues("phoneNumber"))?.match(/^[6-9]\d{9}$/)){
        setError("phoneNumber", {type: "custom", message: "Invalid Mobile Number"})
        return;
      }else{
        setError("phoneNumber", null)
      }
    dispatch({ type: "SET_IS_LOADING", status: true });

    const Re = await sendPhoneOtp({ phone_number: getValues("phoneNumber")});
    dispatch({ type: "SET_IS_LOADING", status: false });
    console.log(Re);

    if (Re?.data?.status[0].Error === "False") {
        toast.success("OTP Sent successfully.");
        setIsOtpSent(true)
        sessionStorage.setItem("reset_pass_token", Re.data.token)
    } else if (Re?.data?.status) {
        toast.error(Re.data.status[0].ResponseMessage);
    } else {
        toast.error("Something went wrong, Please try again.");
    }
  };
  const verifyOtp = async (e) => {
    e.preventDefault();
    if(!isOtpSent){
      setError("phoneNumber", {type: "custom", message: "Please send OTP first."})
      return;
    }else{
      setError("phoneNumber", null)
    }
    if (!getValues("otp")) {
      setError("otp", {type: "custom", message: "OTP is required."})
      return;
    } else {
      setError("otp", null)
    }

    dispatch({ type: "SET_IS_LOADING", status: true });
    const Re = await verifyPhoneOtp({phone_number: getValues("phoneNumber"), phone_number_otp: getValues("otp")});
    dispatch({ type: "SET_IS_LOADING", status: false });
    console.log(Re);

    if (Re?.data?.status[0].Error === "False") {
        setToken(Re.data.token);
        toast.success("Phone number verified.");
    } else if (Re?.response?.data?.status) {
        toast.error(Re?.response.data.status[0].ResponseMessage);
    } else {
        toast.error("Something went wrong, Please try again.");
    }
  };
  return (
    <div className="seller-profile">
      <div className="seller-form-wrapper">
        <div className="title">Account information</div>

        <div className="upload-logo">
          <div className="image-div">
            <h5>Profile Image</h5>
            {profilePic?.url ? (
              <img
                src={
                  profilePic?.url || profileDetail.profile_image
                    ? `${profilePic?.url}`
                    : "https://img.freepik.com/free-icon/user_318-159711.jpg"
                }
                alt="profile"
                className="profile-img"
                onClick={() => imgRef.current.click()}
              />
            ) : (
              <img
                src={
                  profilePic?.url || profileDetail.profile_image
                    ? `${domainName}${profileDetail?.profile_image}`
                    : "https://img.freepik.com/free-icon/user_318-159711.jpg"
                }
                alt="profile"
                className="profile-img"
                onClick={() => imgRef.current.click()}
              />
            )}
            <input
              type="file"
              ref={imgRef}
              accept="image/*"
              onChange={(e) => onImageSelect(e)}
              hidden
            />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-container">
            <div className="input-wrapper">
              <label>Name</label>
              <input
                type="text"
                placeholder="Enter Your Name."
                {...register("name", {
                  required: "Name is required.",
                  maxLength: {
                    value: 20,
                    message: "Name should be less then 20 characters.",
                  },
                })}
              />
              {errors.name && (
                <div className="error">{errors.name.message}</div>
              )}
            </div>
            <div className="input-wrapper">
              <label>Bio</label>
              <textarea
                rows={3}
                type="text"
                placeholder="Enter Your Name."
                {...register("bio", {
                  required: "Bio is required.",
                })}
              ></textarea>
              {errors.bio && <div className="error">{errors.bio.message}</div>}
            </div>
            <div className="gender">
              <div className="gender-input-wrapper">
                <div className="input-wrapper">
                  <input
                    type="checkbox"
                    value="male"
                    checked={gender === "male"}
                    onChange={handleGenderChange}
                  />
                  <label>Male</label>
                </div>
                <div className="input-wrapper">
                  <input
                    type="checkbox"
                    value="female"
                    checked={gender === "female"}
                    onChange={handleGenderChange}
                  />
                  <label>Female</label>
                </div>
              </div>
              {errors.gender && (
                <div className="error">{errors.gender.message}</div>
              )}
            </div>
            <div className="address">
              <div className="input-wrapper">
                <label>Your country</label>
                <select
                  type="text"
                  value={country?.name}
                  {...register("country", {
                    required: "Country is required.",
                    onChange: (e) => {
                      const country = countriesList.find(
                        (item) => item.name === e.target.value
                      );

                      setCountry(country);
                      setValue("country", country?.name);
                      GetState(country?.id).then((result) => {
                        setStateList(result);
                      });
                    },
                  })}
                >
                  <option key={0} value="">
                    Select Country
                  </option>
                  {countriesList.map((item, index) => (
                    <option key={index + 1} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {errors.country && (
                  <div className="error">{errors.country.message}</div>
                )}
              </div>
              <div className="input-wrapper">
                <label>Your State</label>
                <select
                  type="text"
                  placeholder="state,"
                  value={state?.name}
                  {...register("state", {
                    required: "State is required.",
                    onChange: (e) => {
                      const state = stateList.find(
                        (item) => item.name === e.target.value
                      ); //here you will get full state object.

                      setState(state);
                      setValue("state", state?.name);

                      GetCity(country?.id, state.id).then((result) => {
                        setCityList(pre => result);
                      });
                    },
                  })}
                >
                  <option key={0} value="">
                    Select State
                  </option>
                  {stateList.map((item, index) => (
                    <option key={index + 1} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {errors.state && (
                  <div className="error">{errors.state.message}</div>
                )}
              </div>
              <div className="input-wrapper">
                <label>Your city</label>
                <select
                  type="text"
                  placeholder="city,"
                  value={city?.name}
                  {...register("city", {
                    required: "City is required.",
                    onChange: (e) => {
                      const city = cityList.find(
                        (item) => item.name === e.target.value
                      ); //here you will get full city object.
                      setCity(city?.name);
                      setValue("city", city?.name);
                    },
                  })}
                >
                  <option key={0} value="">
                    Select City
                  </option>
                  {cityList.map((item, index) => (
                    <option key={index + 1} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {errors.city && (
                  <div className="error">{errors.city.message}</div>
                )}
              </div>
            </div>
            <div className="input-wrapper">
              <label>Mobile Number</label>
              <div className="input-btn-container">
                <input
                  type="number"
                  placeholder="Enter your phone number"
                  {...register("phoneNumber", {
                    required: "Mobile Number is required.",
                    pattern:{
                      value:/^[6-9]\d{9}$/,
                      message:"Invalid Mobile Number."
                    }
                  })}
                />
                <button className="btn-primary" onClick={(e) => sendVerificationOtp(e)}>Send OTP</button>
              </div>
              {errors.phoneNumber && (
                <div className="error">{errors.phoneNumber.message}</div>
              )}
            </div>
            <div className="input-wrapper">
              <label>Mobile OTP</label>
              <div className="input-btn-container">
                <input
                  type="number"
                  placeholder="XXXXXX"
                  {...register("otp")}
                />
                <button className="btn-primary" onClick={(e) => verifyOtp(e)}>Verify OTP</button>
              </div>
              {errors.otp && (
                <div className="error">{errors.otp.message}</div>
              )}
            </div>
            <div className="input-wrapper">
              <label>Email</label>
              <input
                type="email"
                placeholder="john.doe@email.com"
                {...register("email", {
                  required: "Email Number is required.",
                })}
              />
              {errors.email && (
                <div className="error">{errors.email.message}</div>
              )}
            </div>
            <div className="product-type-wrapper">
              <label>What are you selling?</label>
              <div className="product-type">
                <div
                  className={`${
                    sellingType === "art" ? " btn-primary" : "btn-secondary"
                  }`}
                  onClick={() => {
                    setSellingType("art");
                    setValue("sellingType", "art");
                  }}
                >
                  Art
                </div>
                <div
                  className={`${
                    sellingType === "drawings"
                      ? " btn-primary"
                      : "btn-secondary"
                  }`}
                  onClick={() => {
                    setSellingType("drawings");
                    setValue("sellingType", "drawings");
                  }}
                >
                  Drawings
                </div>
              </div>
            </div>
            <button type="submit" className="btn-primary">
              save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AccountInfo;
