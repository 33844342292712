import React from "react";
import "./SellerHome.scss";
import BannerImg from "../../../assets/images/become-a-seller-bg.png";
import ArtImg from "../../../assets/images/about-us-banner.png";
import Person1 from "../../../assets/images/Rectangle 4.png";
import Person2 from "../../../assets/images/Rectangle 4-1.png";
import Person3 from "../../../assets/images/Rectangle 4-2.png";
import Person4 from "../../../assets/images/Rectangle 4-3.png";
import Person5 from "../../../assets/images/Rectangle 4-4.png";
import create from "../../../assets/images/ion_create-outline.png";
import delivery from "../../../assets/images/carbon_delivery.png";
import getPaid from "../../../assets/images/solar_hand-money-outline.png";
import { AccordionFAQ } from "../../Home/components/AccordionFAQ/AccordionFAQ";
import { Link } from "react-router-dom";

const SellerHome = () => {
  const artists = [Person1, Person2, Person3, Person4, Person5];
  const faqs = [
    {
      title: "What can I sell?",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, ",
    },
    {
      title: "How much money can I make?",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, ",
    },
    {
      title: "How much time will I need to invest?",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, ",
    },
    {
      title: "How do I price my service?",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, ",
    },
  ];
  return (
    <div className="become-seller-main">
      <div
        className="header"
        style={{ backgroundImage: `url(${BannerImg})` }}
        data-aos="fade-down"
      >
        <div className="content">
          <div className="left">
            <h1>Tart ArtBecome a Seller</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
              luctus neque quis nisi pharetra, eu vestibulum felis lacinia. Cras
              sollicitudin faucibus est nec porttitor.Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Etiam luctus neque quis nisi
              pharetra, eu vestibulum
            </p>
            <Link to="/seller/signup">
              <button className="btn-primary">Become a Seller</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="how-it-work" data-aos="fade-up">
        <h1>How it works</h1>
        <div className="steps">
          <div className="step-wrapper">
            <img src={create} alt="" />
            <h4>1. Create a Gig</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
              luctus neque quis nisi pharetra,{" "}
            </p>
          </div>
          <div className="step-wrapper">
            <img src={delivery} alt="" />
            <h4>2. Deliver great work</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
              luctus neque quis nisi pharetra,{" "}
            </p>
          </div>
          <div className="step-wrapper">
            <img src={getPaid} alt="" />
            <h4>3. Get paid</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
              luctus neque quis nisi pharetra,{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="seller-community">
        <h1 data-aos="fade-right">Our Seller community </h1>
        <div className="seller-members-wrapper" data-aos="fade-left">
          <div className="seller-members">
            {artists.map((artist) => (
              <div className="artist">
                <img src={artist} alt="artist" />
                <span>Gregg Rosen</span>
                <span>Artist</span>
              </div>
            ))}
            {artists.map((artist) => (
              <div className="artist">
                <img src={artist} alt="artist" />
                <span>Gregg Rosen</span>
                <span>Artist</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="faq">
        <div className="row">
          <div className="left-column">
            <div className="title">Frequently asked questions</div>
            <div className="contatnt">
              Check out the FAQ sections if you have a specific query regarding
              a particular form of art.
            </div>
          </div>
          <div className="right-column">
            <div className="faq-wrapper">
              {faqs.map((faq) => (
                <AccordionFAQ title={faq.title} content={faq.content} />
              ))}
            </div>
          </div>
        </div>
        {/* <div className="side-img">
          <img src={homeImg3} />
        </div> */}
      </div>
    </div>
  );
};

export default SellerHome;
