import React, { useEffect, useState } from "react";
import "./SupportTicket.scss";
import AddCategoryModal from "../../../../../components/modals/AddCategoryModal/AddCategoryModal";
import UpdateCategoryModal from "../../../../../components/modals/AddCategoryModal/UpdateCategoryModal";
import DeleteCategoryModal from "../../../../../components/modals/AddCategoryModal/DeleteCategoryModal";
import SupportTicketModal from "../../../../../components/modals/SupportTicketModal/SupportTicketModal";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useStateValue } from "../../../../../StateProvider";
import { getSupportTicket } from "../../../../../apiCall";
import { FormatDate } from "../../../../../utils/FormateDate";

const SupportTicket = () => {
  const [, dispatch] = useStateValue();
  const [createTicketModalShow, setCreateTicketModalShow] = useState(false);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["support-ticket"],
    queryFn: () => getSupportTicket(),
    onSuccess: (data) => {
      console.log(data);
      if (data.status === 200) {
        setTickert(data.data?.value);
      } else {
        toast.error(data.response.data.status[0].ResponseMessage);
      }
    },
    onError: (e) => {
      if (e.message) {
        return toast.error(e.message);
      }
      toast.error("Something went wrong");
    },
  });
  const [ticket, setTickert] = useState(
    data?.status === 200 ? data.data?.value : []
  );
  useEffect(() => {
    if (isLoading) {
      dispatch({ type: "SET_LOADING", status: true });
    } else {
      dispatch({ type: "SET_LOADING", status: false });
    }
  }, [isLoading]);

  return (
    <div className="card-container support-ticket">
      <div className="header">
        <div className="title">Support Ticket</div>
        <button
          className="btn-secondary"
          onClick={() => setCreateTicketModalShow(true)}
        >
          {" "}
          Create New Ticket{" "}
        </button>
      </div>
      <div className="ticket-wrapper">
        {ticket.length > 0 ? (
          <>
            {ticket.map((item, index) => (
              <div className="ticket-card" id={`ticket-${item.id}`}>
                <h3>Ticket #{item.id}</h3>
                <p>
                  Status:{" "}
                  <span
                    className={`${
                      item.status === "Closed" ? "text-red" : "text-green"
                    }`}
                  >
                    {item.status}
                  </span>{" "}
                </p>
                <p>Last Update: {FormatDate(item.update_at)}</p>
                <p>Problem: {item.message}</p>
              </div>
            ))}
          </>
        ) : (
          <span>You don't have any ticket.</span>
        )}
      </div>
      <SupportTicketModal
        show={createTicketModalShow}
        refetch={refetch}
        onHide={() => setCreateTicketModalShow(false)}
      />
    </div>
  );
};

export default SupportTicket;
