import React, { useEffect, useState } from "react";

import "./termsOfUse.scss";
import { GetTermsOfCondition } from "../../apiCall";

const TermsOfUse = () => {
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const [content, setContent] = useState();

    useEffect(() => {
        const getData = async () => {
            const re = await GetTermsOfCondition()

            if(re?.data?.status[0]?.Error === "False"){
                setContent(re?.data?.value)
            }
        }

        getData()
        window.scrollTo({ top: 0, behavior: "instant" });
    }, []);
    return (
        <div className='terms-of-use-main'>
            <h3>Terms of Use</h3>
            <hr />
            {content && <div dangerouslySetInnerHTML={{ __html: content }}></div>}
        </div>
    );
};

export default TermsOfUse;