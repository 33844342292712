import React, { useEffect, useRef, useState } from "react";
import "./ArtWorkList.scss";
import { HiMiniMagnifyingGlass } from "react-icons/hi2";
import productImg from "../../../../../../../assets/arts/art (2).png";
import {
  getSellerProduct,
  categoriesList,
  deleteSellerProduct,
} from "../../../../../../../apiCall";
import { useQuery } from "@tanstack/react-query";

import toast from "react-hot-toast";
import { domainName } from "../../../../../../../Constants";
import { useStateValue } from "../../../../../../../StateProvider";

const ArtWorkList = ({ setActiveTab, setPage, setEditProduct }) => {
  const [{ userDetail }] = useStateValue();

  const filterOption = [
    "All",
    "Under Review",
    "Active",
    "Sold Out",
    "Violation",
  ];
  const [categoryFilter, setCategoryFilter] = useState("All");
  const [searchParamsFilter, setSearchParamsFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const searchInputRef = useRef();
  const { data: categories } = useQuery(
    ["art-list-categories"],
    categoriesList,
    {
      onSuccess: (data) => {},
      onError: (e) => {
        // console.log(e)
      },
    }
  );
  const { data: prodcut,refetch } = useQuery({
    queryKey: ["atr-work-list", categoryFilter, statusFilter],
    queryFn: async () => {
      const data = await getSellerProduct(categoryFilter, statusFilter);
      return data;
    },
    onSuccess: (Re) => {
      if (Re?.data?.status[0].Error === "False") {
        setProducts(Re?.data?.value);
        setFilteredItems(Re?.data?.value);
      } else if (Re?.data?.status[0]?.ResponseMessage) {
        toast.error(Re?.data?.status[0].ResponseMessage);
      } else {
        toast.error("Something went wrong.");
      }
    },
    onError: (e) => {
      // console.log(e)
    },
  });
  const [products, setProducts] = useState(
    prodcut?.status === 200 ? prodcut?.data?.value : []
  );
  // const [searchQuery, setSearchQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState(products);

  const handleSearch = (input) => {
    const newfilteredItems = products.filter((item) =>
      item.title.toLowerCase().includes(input.toLowerCase())
    );
    setFilteredItems(newfilteredItems);
  };

  // useEffect(() => {
  //   // const getData = async () => {
  //   //   const Re = await getSellerProduct();
  //   //   console.log(Re?.data);
  //   //   if (Re?.data?.status[0].Error === "False") {
  //   //     setProducts(Re?.data?.value);
  //   //   } else if (Re?.data?.status[0]?.ResponseMessage) {
  //   //     toast.error(Re?.data?.status[0].ResponseMessage);
  //   //   } else {
  //   //     toast.error("Something went wrong.");
  //   //   }
  //   // };
  //   // getData();
  // }, []);

  const handleDelete = async (id) => {
    const Re = await deleteSellerProduct(id);
    console.log(Re?.data);

    if (Re?.data?.status[0].Error === "False") {
      toast.success("Product deleted");
      refetch()
      // setProducts((pre) => pre.filter((prod) => prod.id !== id));
    } else if (Re?.data?.status[0]?.ResponseMessage) {
      toast.error(Re?.data?.status[0].ResponseMessage);
    } else {
      toast.error("Something went wrong.");
    }
  };
  const handleAddArtWork = () => {
    console.log(userDetail?.membership_Detail, products.length)
    if (userDetail?.is_membership_purchased) {
      if (
        userDetail?.membership_Detail?.max_art_work_listing > products.length
      ) {
        setActiveTab("addArtWork");
        setPage("addNewArt");
      } else {
        toast.error("You have reached the maximum artwork listing limit.");
      }
    } else {
      toast.error("Please purchase a membership to access this feature.");
    }
  };

  return (
    <div className="art-work-list">
      {console.log(products)}
      <div className="search-bar-wrapper">
        <div className="category">
          <select
            name=""
            id=""
            onChange={(e) => setCategoryFilter(e.target.value)}
          >
            <option value="All">All</option>
            {categories?.data?.value.map((item) => (
              <option key={item["title"] + item["id"]} value={item["id"]}>
                {item["title"]}
              </option>
            ))}
          </select>
        </div>
        <div className="search-bar">
          <div className="search-bar-inner-wrapper">
            <input
              type="text"
              name=""
              placeholder="Search"
              ref={searchInputRef}
            />
            <HiMiniMagnifyingGlass size={20} />
          </div>
          <div className="btn-wrapper">
            <button
              className="btn-primary"
              onClick={() => handleSearch(searchInputRef.current.value)}
            >
              Search
            </button>
          </div>
        </div>
      </div>

      <div className="filter">
        {filterOption.map((option, index) => (
          <>
            {statusFilter === option ? (
              <button
                className="btn-primary"
                onClick={() => setStatusFilter(option)}
              >
                {option}
              </button>
            ) : (
              <button
                className="btn-secondary"
                onClick={() => setStatusFilter(option)}
              >
                {option}
              </button>
            )}
          </>
        ))}
      </div>
      <div className="list">
        <div className="list-header">
          <div className="title">
            {filteredItems.length > 0 ? filteredItems.length : 0} Products
          </div>
          <button className="btn-secondary" onClick={() => handleAddArtWork()}>
            Add new artworks
          </button>
        </div>
        <div className="table-container">
          {filteredItems.length > 0 ? (
            <table>
              <thead>
                <th>Artwork Title </th>
                <th>Price</th>
                <th>Stock</th>
                <th>Earrings</th>
                <th>sales</th>
                <th>Status</th>
                <th>Action</th>
              </thead>
              <tbody>
                {filteredItems?.map((prod) => (
                  <tr key={"product-" + prod.id}>
                    <td>
                      <div className="product-detail-wrapper">
                        {/* <div
                      className="product-img"
                      style={{ backgroundImage: `url(${productImg})` }}
                    ></div> */}
                        <img
                          src={domainName + "/uploads/" + prod?.image}
                          alt=""
                        />
                        <div className="product-detail">
                          <div className="name">{prod?.title}</div>
                          <div className="category">{prod?.category}</div>
                        </div>
                      </div>
                    </td>
                    <td>₹{prod?.price}</td>
                    <td>{prod?.current_stock}</td>
                    <td>₹0</td>
                    <td>0</td>
                    <td>
                      {prod?.status === "Violation" && (
                        <span className="red-text">Violation </span>
                      )}
                      {prod?.status === "Under Review" && (
                        <span className="red-text">Under Review </span>
                      )}
                      {prod?.status === "Sold Out" && (
                        <span className="red-text">Sold Out </span>
                      )}
                      {prod?.status === "Active" && (
                        <span className="green-text">Active </span>
                      )}
                    </td>
                    <td>
                      <div className="action-wrapper">
                        <span
                          onClick={() => {
                            setEditProduct(prod?.id);
                            setActiveTab("updateArtWork");
                          }}
                        >
                          Edit
                        </span>
                        <span onClick={() => handleDelete(prod?.id)}>
                          Delete
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h4>You don't have any artworks.</h4>
          )}
        </div>
      </div>
    </div>
  );
};

export default ArtWorkList;
