import { useState } from "react";
import { Collapse } from "react-bootstrap";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import "./SellerAccordionFAQ.scss";

export const SellerAccordionFAQ = ({ title, content }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="accordion-faq-box">
      <div
        className="question"
        aria-controls="accordion-description"
        aria-expanded={open}
        onClick={() => setOpen(!open)}
      >
        <span>{title}</span>
        {open ? (
          <AiFillCaretUp className="icon" />
        ) : (
          <AiFillCaretDown className="icon" />
        )}
      </div>
      <Collapse in={open}>
        <div id="accordion-description">
          <ul>
            {content.length > 0 ? (
              <>
                {content.map((item) => (
                  <li>{item.point}</li>
                ))}
              </>
            ) : (
              <></>
            )}
          </ul>
        </div>
      </Collapse>
    </div>
  );
};
