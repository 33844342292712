export const FormatDate =( inputDate)=> {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const dateObj = new Date(inputDate);
  
    const formattedDate = dateObj.toLocaleDateString("en-US", options);
  
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";
    const formattedTime = `${hours % 12}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;
  
    return `${formattedDate}, ${formattedTime}`;
  }