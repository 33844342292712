import React, { useEffect } from "react";
import "./SupportTicketModal.scss";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { useStateValue } from "../../../StateProvider";
import { contact, createSupportTicket } from "../../../apiCall";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { ValidateNumberLength } from "../../../utils/ValidateNumberLength";
const SupportTicketModal = (props) => {
  const [, dispatch] = useStateValue();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone_number: "",
      message: "",
    },
  });
  const onSubmit = async (values) => {
    dispatch({ type: "SET_LOADING", status: true });
    try {
      const re = await createSupportTicket(values);
      console.log(re);
      if (re?.status === 201) {
        toast.success(re?.data.status[0].ResponseMessage);
        props.refetch();
        props.onHide();
        reset();
      } else {
        toast.error(re?.response.data.status[0].ResponseMessage);
      }
    } catch (err) {
      toast.error(err.message);
    }
    dispatch({ type: "SET_LOADING", status: false });
  };
  useEffect(() => {
    reset();
  }, []);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="category-modals"
    >
      <Modal.Body className="category-modal">
        <div className="close">
          <IoClose size={20} onClick={props.onHide} />
        </div>
        <h3>Create New Ticket </h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-box">
            <label htmlFor="">Name</label>
            <input
              type="text"
              placeholder="Enter your name"
              {...register("name", {
                required: "This field is required",
              })}
            />
            <small className="error">{errors.name?.message}</small>
          </div>
          <div className="input-box">
            <label>Email</label>
            <input
              type="email"
              placeholder="john.doe@email.com"
              {...register("email", {
                required: "Email Number is required.",
              })}
            />

            <small className="error">{errors.email?.message}</small>
          </div>
          <div className="input-box">
            <label>Mobile Number</label>
            <input
              type="number"
              placeholder="9173848036"
              {...register("phone_number", {
                required: "Mobile Number is required.",
                 pattern:{
                    value:/^[6-9]\d{9}$/,
                    message:"Invalid Mobile Number."
                  }
              })}
              onChange={(e)=>ValidateNumberLength("phone_number",e,setValue)}

            />
            <small className="error">{errors.phone_number?.message}</small>
          </div>
          <div className="input-box">
            <label>Description</label>
            <textarea
              rows={4}
              type="text"
              placeholder="Write a problem"
              {...register("message", {
                required: "Description is required.",
                maxLength: {
                  value: 500,
                  message: "Description should be less then 500 characters.",
                },
              })}
            />
            <small className="error">{errors.message?.message}</small>
          </div>

          <div className="buttons">
            <button className="btn-primary">Submit Ticket</button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default SupportTicketModal;
