import "./Home.scss";
import art2 from "../../assets/arts/art (2).png";
import art3 from "../../assets/arts/art (3).png";
import art4 from "../../assets/arts/art (4).png";
import secondaryLogo from "../../assets/images/secondary-logo.png";
import vector from "../../assets/images/Vector.png";
import vectorFill from "../../assets/images/Vector-fill.png";
import homeImg1 from "../../assets/images/home-img-1.png";
import homeImg2 from "../../assets/images/home-img-2.png";
import homeImg3 from "../../assets/images/home-img-3.png";
import Slider from "react-slick";
import { useEffect, useRef, useState } from "react";
import { FaChevronDown } from "react-icons/fa6";
import AOS from "aos";
import "aos/dist/aos.css";
import CircleSlider from "./components/CircleSlider/CircleSlider";
import { HomeProductCard } from "./components/HomeProductCard/HomeProductCard";
import { AccordionFAQ } from "./components/AccordionFAQ/AccordionFAQ";
import { Footer } from "../../components/Footer/Footer";
import { fetchHomepageProducts } from "../../apiCall";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

const qaContent = [
  "What can I sell?",
  "How much money can I make?",
  "How much time will I need to invest?",
  "How do I price my service?",
];
const faqs = [
  {
    title: "What can I sell?",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, ",
  },
  {
    title: "How much money can I make?",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, ",
  },
  {
    title: "How much time will I need to invest?",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, ",
  },
  {
    title: "How do I price my service?",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam luctus neque quis nisi pharetra, ",
  },
];
const products = [
  {
    id: 1,
    main_image: art2,
    title: "Structural Landscape",
    category__title: "Gregg Rosen/Paintings",
    owner: "",
    price: "₹1,85,323.00",
    available_for_rent: true,
    // emi: 299,
  },
  {
    id: 1,
    main_image: art3,
    title: "Structural Landscape",
    category__title: "Gregg Rosen/Paintings",
    owner: "",
    price: "₹1,85,323.00",
    available_for_rent: true,
  },
  {
    id: 1,
    main_image: art4,
    title: "Structural Landscape",
    category__title: "Gregg Rosen/Paintings",
    owner: "",
    price: "₹1,85,323.00",
    available_for_rent: true,
  },
  {
    id: 1,
    main_image: art2,
    title: "Structural Landscape",
    category__title: "Gregg Rosen/Paintings",
    owner: "",
    price: "₹1,85,323.00",
    available_for_rent: true,
  },
  {
    id: 1,
    main_image: art3,
    title: "Structural Landscape",
    category__title: "Gregg Rosen/Paintings",
    owner: "",
    price: "₹1,85,323.00",
    available_for_rent: true,
  },
  {
    id: 1,
    main_image: art4,
    title: "Structural Landscape",
    category__title: "Gregg Rosen/Paintings",
    owner: "",
    price: "₹1,85,323.00",
    available_for_rent: true,
  },
  {
    id: 1,
    main_image: art2,
    title: "Structural Landscape",
    category__title: "Gregg Rosen/Paintings",
    owner: "",
    price: "₹1,85,323.00",
    available_for_rent: true,
  },
  {
    id: 1,
    main_image: art3,
    title: "Structural Landscape",
    category__title: "Gregg Rosen/Paintings",
    owner: "",
    price: "₹1,85,323.00",
    available_for_rent: true,
  },
];

export const Home = () => {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const scrollContainerRef = useRef(null);
  const [products, setProducts] = useState([])
  const sectionRef = useRef(null);
  const navigateTo = useNavigate()
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const { isLoading: homepaageProductsIsLoading } = useQuery(
    ["all-products"],
    () => fetchHomepageProducts(),
    {
      onSuccess: (data) => {
        console.log(data)
        setProducts(data.data?.value);
      },
      onError: (e) => {
        if (e.message) {
          return toast.error(e.message);
        }
        toast.error("Something went wrong");
      },
    }
  );


  useEffect(() => {
    AOS.init();

    const scrollContainer = scrollContainerRef.current;
    const handleScroll = () => {
      // var circle = document.getElementById('circle');

      if (!scrollContainer) return;

      const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
      const currentScrollPercentage =
        (scrollTop / (scrollHeight - clientHeight)) * 100;

      setScrollPercentage(currentScrollPercentage);
    };

    scrollContainer.addEventListener("scroll", handleScroll);

    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const[faqOpenContent,setFaqOpenContent] =useState("")

  return (
    <div className="home-container" ref={scrollContainerRef}>
      {console.log(products)}
      <section className="one">
        <div className="left-column" data-aos="fade-right">
          <div className="vector-fill">
            <img src={vectorFill} alt="" />
          </div>
          <div className="content">For art</div>
          <div className="vector">
            <img src={vector} alt="" />
          </div>
        </div>
        <div className="middle-column">
          <div className="logo" data-aos="fade-down">
            <img src={secondaryLogo} alt="" />
          </div>
          <div
            className="slider-wrapper"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="300"
            data-aos-offset="0"
          >
            <Slider {...settings}>
              <div>
                <img src={homeImg1} />
              </div>
              <div>
                <img src={homeImg2} />
              </div>
              <div>
                <img src={homeImg3} />
              </div>
            </Slider>
          </div>
          {/* <div className="scroll-down-wrapper" > */}
          <div className="scroll-down">
            <div
              className="scroll-down-inner-wrapper"
              onClick={scrollToSection}
            >
              <span>Scroll down to explore</span>
            </div>
            <div className="down-arrow" onClick={scrollToSection}>
              <FaChevronDown />
            </div>
          </div>
          {/* </div> */}
        </div>
        <div className="right-column" data-aos="fade-left">
          <div className="vector">
            <img src={vector} alt="" />
          </div>
          <div className="content">from art</div>
          <div className="vector-fill">
            <img src={vectorFill} alt="" />
          </div>
        </div>
      </section>
      <section className="two" ref={sectionRef}>
        <Slider {...settings}>
          <div>
            <img src={homeImg1} />
          </div>
          <div>
            <img src={homeImg2} />
          </div>
          <div>
            <img src={homeImg3} />
          </div>
        </Slider>
        <CircleSlider />
      </section>
      <section className="tree">
        <div className="row">
          <div className="left-column">
            <div className="title">Popular artwork</div>
            <div className="button-wrapper">
              <button className="btn-primary" onClick={() => navigateTo('/shop')}>Explore the Gallery</button>
            </div>
          </div>
          <div className="right-column">
            <div className="slider-wrapper">
              {products.map((product) => (
                <HomeProductCard item={product} />
              ))}
            </div>
          </div>
        </div>
        <div className="side-img">
          <img src={homeImg3} />
        </div>
      </section>
      <section className="four">
        <div className="row">
          <div className="left-column">
            <div className="title">Frequently asked questions</div>
            <div className="contatnt">
              Check out the FAQ sections if you have a specific query regarding
              a particular form of art.
            </div>
          </div>
          <div className="right-column">
            <div className="faq-wrapper">
              {faqs.map((faq) => (
                <AccordionFAQ title={faq.title} content={faq.content} setFaqOpenContent={setFaqOpenContent} faqOpenContent={faqOpenContent} />
              ))}
            </div>
          </div>
        </div>
        <div className="side-img">
          <img src={homeImg3} />
        </div>
      </section>
      {/* <section className="footer-section">
        <Footer />
      </section> */}
    </div>
  );
};
