import "./ForgotPassword.scss";
import { ReactComponent as GoogleIcon } from "../../assets/svgs/google-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { LoginBackgroundImage } from "../../components/LoginBackgroundImage/LoginBackgroundImage";
import { MdOutlineMail } from "react-icons/md";
import { HiOutlinePhone } from "react-icons/hi";
import { useForm } from "react-hook-form";
import { useState } from "react";
import toast from "react-hot-toast";
import { SendOtp, VerifyOtp, ResetPassword } from "../../apiCall";
import { useStateValue } from "../../StateProvider";

export const ForgotPassword = () => {
    const navigate = useNavigate();
    const [mode, setMode] = useState("email");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");
    const [passwordMismatchError, setPasswordMismatchError] = useState("");

    const [token, setToken] = useState("");
    const [, dispatch] = useStateValue();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const handleForgotPassword = (status) => {
        dispatch({
            type: "SET_FORGOT_PASSWORD_MODAL_SHOW",
            status: status,
        });
    };
    const sendOtp = async (e) => {
        e.preventDefault();
        if (!email) {
            setEmailError("Email is required");
            return;
        } else {
            setEmailError("");
        }
        const Re = await SendOtp({ email: email, reason: "Forgot Password" });
        console.log(Re);

        if (Re?.data?.status[0].Error === "False") {
            setMode("otp");
            setToken(Re?.data?.token);
        } else if (Re?.data?.status) {
            toast.error(Re.data.status[0].ResponseMessage);
        } else {
            toast.error("Something went wrong, Please try again.");
        }
    };
    const verifyOtp = async (e) => {
        e.preventDefault();

        if (!otp) {
            setOtpError("Otp is required");
            return;
        } else {
            setOtpError("");
        }

        const Re = await VerifyOtp(token, otp);
        console.log(Re);

        if (Re?.data?.status[0].Error === "False") {
            setToken(Re.data.value);
            setMode("change_password");
        } else if (Re?.data?.status) {
            toast.error(Re.data.status[0].ResponseMessage);
        } else {
            toast.error("Something went wrong, Please try again.");
        }
    };
    const changePassword = async (data) => {
        if (data.newPassword !== data.confirm_password) {
            setPasswordMismatchError(
                "Password and Confirm password must be same."
            );
            return;
        } else {
            setPasswordMismatchError("");
        }

        const Re = await ResetPassword({
            email: email,
            newPassword: data.newPassword,
            token: token,
        });
        console.log(Re);

        if (Re?.data?.status[0].Error === "False") {
            toast.success("Password Changed!!");
            handleForgotPassword(false);
        } else if (Re?.data?.status) {
            toast.error(Re.data.status[0].ResponseMessage);
        } else {
            toast.error("Something went wrong, Please try again.");
        }
    };
    return (
        <div className='forgot-pw-container'>
            <div className='main-section'>
                <div className='form-container'>
                    <h1>Forgot Password?</h1>
                    <p>
                        We sent you a code. select which contact details we use
                        to reset your password
                    </p>
                    {mode === "email" && (
                        <form>
                            <div className='input-box'>
                                <MdOutlineMail className='icon' />
                                <input
                                    type='email'
                                    name='email'
                                    placeholder='Please enter your email...'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <p className='form-error'>{emailError}</p>
                            <button className='button' onClick={sendOtp}>
                                Continue
                            </button>
                        </form>
                    )}
                    {mode === "otp" && (
                        <form>
                            <div className='input-box'>
                                <MdOutlineMail className='icon' />
                                <input
                                    type='text'
                                    name='otp'
                                    placeholder='Please enter your OTP...'
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                />
                            </div>
                            <p className='form-error'>{otpError}</p>
                            <button className='button' onClick={verifyOtp}>
                                Continue
                            </button>
                        </form>
                    )}
                    {mode === "change_password" && (
                        <form>
                            <div className='input-box'>
                                <MdOutlineMail className='icon' />
                                <input
                                    type='password'
                                    placeholder='Enter your password'
                                    {...register("newPassword", {
                                        required: true,
                                        pattern:
                                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{6,20}$/i,
                                    })}
                                />
                                {errors.newPassword?.type === "required" && (
                                    <p className='form-error'>
                                        Password is required.
                                    </p>
                                )}
                                {errors.newPassword?.type === "pattern" && (
                                    <p className='form-error'>
                                        Must Be Contains Uppercase, Lowercase
                                        Letters, Numbers, Special Characters And
                                        Length Is Greater Than 8 Character And
                                        Less Then 16 Character.
                                    </p>
                                )}
                            </div>
                            <div className='input-box'>
                                <MdOutlineMail className='icon' />
                                <input
                                    type='password'
                                    placeholder='Confirm your password'
                                    {...register("confirm_password", {
                                        required: true,
                                        pattern:
                                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{6,20}$/i,
                                    })}
                                />
                                {errors.confirm_password?.type ===
                                    "required" && (
                                    <p className='form-error'>
                                        Confirm password is required.
                                    </p>
                                )}
                                {errors.confirm_password?.type ===
                                    "pattern" && (
                                    <p className='form-error'>
                                        Must Be Contains Uppercase, Lowercase
                                        Letters, Numbers, Special Characters And
                                        Length Is Greater Than 8 Character And
                                        Less Then 16 Character.
                                    </p>
                                )}
                                <p className='form-error'>
                                    {passwordMismatchError}
                                </p>
                            </div>
                            <button
                                className='button'
                                onClick={handleSubmit(changePassword)}>
                                Change Password
                            </button>
                        </form>
                    )}
                </div>
            </div>
            <LoginBackgroundImage />
        </div>
    );
};
