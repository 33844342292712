import React, { useState } from "react";
import "./Notification.scss";
import { IoMdNotificationsOutline } from "react-icons/io";

const Notification = () => {
  return (
    <div className="card-container notification-ticket">
      <div className="notification-wrapper">
        <div className="notification-card">
          <IoMdNotificationsOutline size={24} />
          <div className="content">
            <h3>You have a new message from@ash</h3>
            <span>27 Oct 2023.12:46 PM</span>
          </div>
        </div>
        <div className="notification-card">
          <IoMdNotificationsOutline size={24} />
          <div className="content">
            <h3>You have a new message from@ash</h3>
            <span>27 Oct 2023.12:46 PM</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
