import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { updateNotificationSetting } from "../../../../../../../apiCall";
import toast from "react-hot-toast";

const NotificationSetting = ({profileDetail,refetch}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      emailNotification: profileDetail?.email_notification,
      orderUpdateNotification: profileDetail?.orderUpdate_notification,
      chatNotification: profileDetail?.chat_notification,
      reviewNotification: profileDetail?.review_notification,
      returnNotification: profileDetail?.return_notification,
      listingNotification: profileDetail?.listing_notification,
    },
  });

  const onSubmit = async (data) => {
    console.log(data);
    try {
      const response = await updateNotificationSetting(data);
      console.log(response);

      if (response?.status === 200) {
        toast.success("Notification setting updated successfully.");
        setIsChanged(false);
      } else {
        toast.error(response?.response.data.status[0].ResponseMessage);
      }
    } catch (error) {
      console.error("Error uploading PAN card:", error);
    }
  };
  const [isChanged, setIsChanged] = useState(false);

  const notificationSetting = [
    {
      title: "Email notifications",
      name: "emailNotification",
      isActive: true,
    },
    {
      title: "Order updates",
      name: "orderUpdateNotification",
      isActive: true,
    },
    {
      title: "Chats",
      name: "chatNotification",
      isActive: true,
    },
    {
      title: "Reviews",
      name: "reviewNotification",
      isActive: true,
    },
    {
      title: "Return & Refund",
      name: "returnNotification  ",
      isActive: true,
    },
    {
      title: "Listing",
      name: "listingNotification",
      isActive: true,
    },
  ];
  return (
    <div className="card-container notification-setting">
      <div className="title">Notification</div>
      <div className="list">
        <form onSubmit={handleSubmit(onSubmit)}>
          {notificationSetting.map((item, index) => (
            <div className="item" id={index}>
              <span>{item.title}</span>
              <label className="switch">
                <input
                  type="checkbox"
                  // defaultChecked={item.isActive}
                  {...register(`${item.name}`, {
                    onChange: () => setIsChanged(true),
                  })}
                />
                <span className="slider round"></span>
              </label>
            </div>
          ))}
          {isChanged ? (
            <div className="btn-wrapper">
              <button className="btn-primary">Save</button>
            </div>
          ) : (
            ""
          )}
        </form>
      </div>
    </div>
  );
};

export default NotificationSetting;
